import React, { PropsWithChildren } from 'react';

import useGetMe from '../../queries/useGetMe';
import LogoutPopover from '../../components/Logout/LogoutPopover';
import Layout from '../../components/Layout/Layout';
import Header from '../../components/Header/Header';

export default function ErrorPage({ children }: PropsWithChildren) {
  const user = useGetMe();

  const firstRow = user.isSuccess ? <LogoutPopover logoutText="LogoutButton" /> : null;

  return <Layout header={<Header firstRow={firstRow} />} body={children} />;
}
