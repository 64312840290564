import React from 'react';
import { AppShell, Aside, Box, Container, Header, ScrollArea } from '@mantine/core';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';

import useStyles from './Layout.style';
import useScrollbarWidth from './Layout.helpers';

interface Props {
  header: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  aside?: React.ReactNode;
}

export default function Layout({ header, body, footer, aside }: Props) {
  const breakpoint = 'md';
  const padding = 16;
  const bigHeaderHeight = 115;
  const smallHeaderHeight = 100;
  const mainContentWidth = 832;
  const asideWidth = 495;
  const asideScrollHideDelay = 500;
  const asideScrollbarSize = 4;

  const viewportSize = useViewportSize();
  const scrollbarWidth = useScrollbarWidth();

  const mx = Math.max((viewportSize.width - (mainContentWidth + asideWidth + scrollbarWidth)) / 2, 0);
  const { classes, theme, cx } = useStyles({ breakpoint, asideScrollbarSize, mx, haveAside: !!aside, asideWidth });

  /* eslint-disable react-perf/jsx-no-new-object-as-prop */
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints[breakpoint]}px)`);
  const headerHeight = middleScreen ? smallHeaderHeight : bigHeaderHeight;

  const asideWidthProps = { [breakpoint]: asideWidth };
  const appShellClasses = { main: classes.main, body: classes.body };
  const asideScrollAreaClasses = { viewport: classes.viewport };
  /* eslint-enable react-perf/jsx-no-new-object-as-prop */

  return (
    <AppShell
      header={
        <Header height={headerHeight} className={aside && middleScreen ? cx(classes.header, 'sticky') : classes.header}>
          <Container h="100%" fluid mx={mx} px={padding}>
            {header}
          </Container>
        </Header>
      }
      aside={
        aside ? (
          <Aside
            width={asideWidthProps}
            right={mx}
            pr={padding}
            pl={0}
            display={!aside && middleScreen ? 'none' : undefined}
          >
            <ScrollArea.Autosize
              maxHeight="100%"
              scrollHideDelay={asideScrollHideDelay}
              scrollbarSize={asideScrollbarSize}
              classNames={asideScrollAreaClasses}
            >
              {aside}
            </ScrollArea.Autosize>
          </Aside>
        ) : undefined
      }
      padding={0}
      classNames={appShellClasses}
    >
      <ScrollArea className={classes.scrollArea} scrollbarSize={asideScrollbarSize} offsetScrollbars>
        <Container fluid ml={aside ? mx : 0}>
          {body}
        </Container>
      </ScrollArea>
      {footer && <Box ml={mx}>{footer}</Box>}
    </AppShell>
  );
}
