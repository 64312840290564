import { useQuery } from 'react-query';

import axios from '../axios';
import { IApiResponse, IPollDto } from '../types';

const useGetSettingsActivePoll = () =>
  useQuery<IPollDto | undefined, Error>(
    'settings/active-poll',
    async () => {
      const response = await axios.get<unknown, IApiResponse<IPollDto[]>>(`/gateway/tilly/settings/polls?limit=${1}`);
      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value.filter((p) => p.state !== 'Finished')[0];
    },
    {
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetSettingsActivePoll;
