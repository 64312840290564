import React, { useCallback, useMemo } from 'react';
import {
  ExpandedState,
  TableState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Flex, Stack } from '@mantine/core';

import { ISettingsDepartment, ISettingsLocation, ISettingsRole, ISettingsSlice } from '../../types';
import groupByColumns from '../../helpers/groupByColumns';

import { COLUMNS, COLUMN_IDS } from './SliceSettingsDataTable.constants';
import { AdditionalSlice } from './SlicesSettings.types';

interface PropsBase<TSlice extends ISettingsSlice> {
  data: TSlice[];
  slice: AdditionalSlice;
  onDeleteHandler: (defaults: TSlice) => void;
  onMoveHandler: (defaults: TSlice) => void;
  onRenameHandler: (defaults: TSlice) => void;
  onAddHandler: (defaults: Omit<TSlice, 'id'>) => void;
}

interface DepartmentsProps extends PropsBase<ISettingsDepartment> {
  slice: AdditionalSlice.Departments;
}

interface RolesProps extends PropsBase<ISettingsRole> {
  slice: AdditionalSlice.Roles;
}

interface LocationsProps extends PropsBase<ISettingsLocation> {
  slice: AdditionalSlice.Locations;
}

type Props = DepartmentsProps | RolesProps | LocationsProps;

export default function SliceSettingsDataTable({
  data,
  slice,
  onDeleteHandler,
  onMoveHandler,
  onRenameHandler,
  onAddHandler,
}: Props) {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const state = useMemo<Partial<TableState>>(
    () => ({
      columnVisibility: {
        [COLUMN_IDS.HIERARCHY]:
          (slice === AdditionalSlice.Departments || slice === AdditionalSlice.Roles) && data.some((x) => x.parentId),
        [COLUMN_IDS.ID]: false,
      },
      expanded,
      sorting: [
        {
          id: COLUMN_IDS.ID,
          desc: false,
        },
      ],
    }),
    [slice, data, expanded],
  );

  const initialData = useMemo(
    () =>
      slice === AdditionalSlice.Departments || slice === AdditionalSlice.Roles ? data.filter((x) => !x.parentId) : data,
    [slice, data],
  );

  const getSubRows = useCallback(
    () => (row: ISettingsSlice) => {
      if (slice !== AdditionalSlice.Departments && slice !== AdditionalSlice.Roles) return undefined;
      return data.filter((x) => x.parentId === row.id);
    },
    [data, slice],
  );

  const table = useReactTable({
    data: initialData,
    state,
    columns: COLUMNS,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (x) => x.id.toString(),
    getSubRows: getSubRows(),
    meta: {
      dataType: slice,
      getDataChangeHandler: (item, action) => {
        switch (action) {
          case 'delete':
            return () => onDeleteHandler(item);

          case 'move':
            return () => onMoveHandler(item);

          case 'rename':
            return () => onRenameHandler(item);

          case 'add_child':
            return () => onAddHandler({ name: '', parentId: item.id });

          default:
            return () => {
              console.warn(`Handler for action of type ${action} not found.`);
            };
        }
      },
    },
  });

  return (
    <Stack spacing={0}>
      {table.getRowModel().rows.map((row) => (
        <Flex key={row.id} justify="space-between" h={30}>
          {groupByColumns(row.getVisibleCells()).map((group) => (
            <Flex key={group.id} w={group.column.getSize()} display="inline-flex" justify="space-between">
              {group.cells.map((cell) => (
                <Flex key={cell.id} w={cell.column.getSize()} display="inline-flex" align="center">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
      ))}
    </Stack>
  );
}
