import { Tenure } from '../statistics/types/enums';

const record = {
  [Tenure.LessThanSixMonths]: 0,
  [Tenure.FromSixMonthsToTwoYears]: 1,
  [Tenure.FromTwoYearsToFiveYears]: 2,
  [Tenure.MoreThanFiveYears]: 3,
};

export default function compareTenures(a: Tenure, b: Tenure) {
  if (record[a] === record[b]) {
    return 0;
  }

  return record[a] - record[b];
}
