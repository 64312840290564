import Cookies from 'js-cookie';

import isDemo from './isDemo';

function getCookieKey() {
  return isDemo() ? 'demo_automatic_start_onboarding' : 'start_automatic_onboarding';
}

export function isOnboardingShouldStartAutomatically() {
  const cookie = Cookies.get(getCookieKey());
  return !cookie || cookie === 'true';
}

export function disableOnboardingStartAutomatic() {
  const route = isDemo() ? '/demo' : '/';
  Cookies.set(getCookieKey(), 'false', {
    path: route,
    expires: 3650,
  });
}
