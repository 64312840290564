import React, { ChangeEvent, useEffect, useRef } from 'react';
import { Box, Checkbox, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import useGetPredictorHistory from '../../statistics/queries/useGetPredictorHistory';
import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import { Predictor as PredictorType } from '../../statistics/types/enums';
import useGetPollSlices from '../../statistics/queries/useGetPollSlices';
import { setFilter, useGetGroupName } from '../Indicator/Indicator.helpers';
import { useFilter } from '../../globalStates/Filter';
import { useGroupBy } from '../../globalStates/GroupBy';
import { PredictorContextValue, usePredictor } from '../../globalStates/Predictor';
import State from '../../globalStates/State';

import { clearLines, clearSvg, createLines, getData, initSvg } from './Predictor.helpers';
import useStyles from './Predictor.style';

interface IPredictorProps {
  polls: IPollInfo[];
  poll: IPollInfo;
  selected: string | null | undefined;
  id: string;
}

export default function Predictor(props: IPredictorProps) {
  const { poll, polls, selected, id } = props;

  const [{ predictor, showNegative, showPositive }, setPredictor] = usePredictor() as State<
    PredictorContextValue & { predictor: PredictorType }
  >;
  const [groupBy] = useGroupBy();

  const slices = useGetPollSlices(poll.id);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useMantineTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const [filter] = useFilter();
  const isFilters = Object.values(filter).some((f: number[]) => f.length > 0);
  const groupNameByFilter = useGetGroupName(slices, groupBy, selected);

  // overall
  const { data: overallData, isLoading: isLoadingDefaultData } = useGetPredictorHistory(poll, predictor, {
    departments: [],
    locations: [],
    roles: [],
    tenures: [],
  });
  const predictorOverallData = getData(polls, overallData, predictor, 'Overall');

  // selected
  const { data: selectedData, isLoading: isLoadingByFilterData } = useGetPredictorHistory(
    poll,
    predictor,
    setFilter(filter, groupBy, selected),
  );
  const predictorSelectedData = getData(polls, selectedData, predictor, groupNameByFilter!);

  // filtered
  const { data: filteredData } = useGetPredictorHistory(poll, predictor, filter);
  const predictorFilteredData = getData(polls, filteredData, predictor, 'Filtered');
  predictorOverallData.push(...predictorFilteredData);

  const setPositiveActionHandler = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (showNegative) {
        setPredictor({ predictor, showNegative, showPositive: e.currentTarget.checked });
      }
    },
    [predictor, showNegative, setPredictor],
  );

  const setNegativeActionHandler = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (showPositive) {
        setPredictor({ predictor, showPositive, showNegative: e.currentTarget.checked });
      }
    },
    [predictor, showPositive, setPredictor],
  );

  const singleCheckChoosen = showNegative !== showPositive;
  const showOverall = !isFilters && singleCheckChoosen;
  const showFiltered = isFilters && singleCheckChoosen;

  useEffect(() => {
    initSvg(containerRef, predictor, id, predictorOverallData[0]);
    return () => clearSvg(containerRef);
  }, [predictorOverallData, predictor, id]);

  useEffect(() => {
    clearLines(id);
    createLines({
      activeType: predictor,
      activeDepartment: selected,
      datasetDefault: predictorOverallData,
      datasetByFilterData: predictorSelectedData[0],
      elementRef: containerRef,
      activeNegative: showNegative,
      activePositive: showPositive,
      positiveColor: theme.colors.green[2],
      negativeColor: theme.colors.red[4],
      id,
      showOverall,
      showFiltered,
    });
  }, [
    selected,
    predictorOverallData,
    predictorSelectedData,
    predictorFilteredData,
    predictor,
    showNegative,
    showPositive,
    showFiltered,
    showOverall,
    isFilters,
    theme,
    id,
  ]);

  if (isLoadingDefaultData || isLoadingByFilterData) {
    return <CustomSkeleton className={classes.skeleton} />;
  }

  return (
    <Group spacing={48} className={classes.wrapper}>
      <Box ref={containerRef} />
      <Stack>
        <Text>{t('PredictorInput.Title')}</Text>
        <Group spacing={4} align="flex-start">
          <Checkbox color="red" checked={showNegative} onChange={setNegativeActionHandler} />
          <Text className={`${classes.inputLabel} ${classes.negativeColor}`}>
            {t(`PredictorChart.NegativeTitle.${predictor}`)}
          </Text>
        </Group>
        <Group spacing={4} align="flex-start">
          <Checkbox color="teal" checked={showPositive} onChange={setPositiveActionHandler} />
          <Text className={`${classes.inputLabel} ${classes.positiveColor}`}>
            {t(`PredictorChart.PositiveTitle.${predictor}`)}
          </Text>
        </Group>
      </Stack>
    </Group>
  );
}
