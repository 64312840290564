import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  backToLanding: {
    marginLeft: '16px',
  },
  installToSlack: {
    marginLeft: '16px',
  },
  share: {
    paddingLeft: '12px !important',
    paddingRight: '6px !important',
  },
  settings: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  logout: {
    paddingLeft: '6px',
    paddingRight: '0px',
  },
}));

export default useStyles;
