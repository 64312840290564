import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Group, MultiSelect, Stack } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { IconChevronDown } from '@tabler/icons-react';
import { z } from 'zod';

import useUpdateSettingsMutation, { IUpdateSettingsRequest } from '../../queries/useUpdateSettingsMutation';
import { IBotSettingsDto, IChannelDto, IUserDto } from '../../types';
import SettingsCard from '../SettingsCard/SettingsCard';

import ChannelsSelector from './ChannelsSelector/ChannelsSelector';

export interface Props {
  users: IUserDto[];
  channels: IChannelDto[];
  settings: IBotSettingsDto;
}

export default function SettingsForm({ users, channels, settings }: Props) {
  const { t } = useTranslation();
  const updateSettingsMutation = useUpdateSettingsMutation();

  const settingsSchema = z.object({
    admins: z.array(z.string()).nonempty({ message: t<string>('SettingsForm.AdminsSelectRequiredError') }),
    channel: z.string().nonempty({ message: t<string>('SettingsForm.ChannelSelectRequiredError') }),
  });

  const settingsForm = useForm<IUpdateSettingsRequest>({
    initialValues: {
      admins: settings.admins.map((u) => u.id),
      channel: settings.channel?.id ?? '',
    },

    validate: zodResolver(settingsSchema),
  });

  return (
    <form onSubmit={settingsForm.onSubmit((values) => updateSettingsMutation.mutate(values))}>
      <Stack spacing="lg">
        <SettingsCard label={t<string>('SettingsForm.CardLabel')}>
          <ChannelsSelector
            id="channelsSelector"
            label={t<string>('SettingsForm.ChannelsSelectInputLabel')}
            description={t<string>('SettingsForm.ChannelsSelectDescription')}
            items={channels.map((ch) => ({ value: ch.id, label: ch.name }))}
            placeholder={t('SettingsForm.ChannelsSelectPlaceholder')}
            limit={20}
            {...settingsForm.getInputProps('channel')}
          />
          <MultiSelect
            id="adminsSelector"
            label={t<string>('SettingsForm.AdminsSelectInputLabel')}
            description={t('SettingsForm.AdminsSelectDescription')}
            data={users.map((u) => ({ value: u.id, label: u.name }))}
            placeholder={t<string>('SettingsForm.AdminsSelectPlaceholder')}
            rightSection={<IconChevronDown size={14} />}
            limit={20}
            withinPortal
            searchable
            withAsterisk
            // eslint-disable-next-line
            labelProps={{ mb: 'xs' }}
            // eslint-disable-next-line
            styles={{ rightSection: { pointerEvents: 'none' } }}
            {...settingsForm.getInputProps('admins')}
          />
        </SettingsCard>

        {updateSettingsMutation.isError && (
          <Alert color="red">
            {t('SettingsForm.ServerErrorAlert', { errorMessage: updateSettingsMutation.error.message })}
          </Alert>
        )}

        <Group position="right">
          <Button color="green" type="submit" disabled={updateSettingsMutation.isLoading}>
            {t('SettingsForm.Submit')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
