import { useCallback, useState } from 'react';
import { UseMutationResult } from 'react-query';

// eslint-disable-next-line import/prefer-default-export
export function useMutationModalDispatcher<TVariables>(
  mutation: UseMutationResult<unknown, unknown, TVariables, unknown>,
) {
  const [variables, setVariables] = useState<TVariables>();

  const open = useCallback(
    (item: TVariables) => {
      mutation.reset();
      setVariables(item);
    },
    [mutation, setVariables],
  );

  const close = useCallback(() => {
    mutation.reset();
    setVariables(undefined);
  }, [mutation, setVariables]);

  const submit = useCallback(
    (variablesOverride?: Partial<TVariables>) => {
      if (!variables) {
        return;
      }

      mutation.mutate(
        {
          ...variables,
          ...variablesOverride,
        },
        {
          onSuccess: close,
        },
      );
    },
    [variables, mutation, close],
  );

  return { variables, open, close, submit };
}
