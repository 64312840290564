import React, { useMemo } from 'react';
import { Group, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { IOverallOverview, IOverviewStatistics, IStats } from '../../statistics/types/overview';
import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import { MainPredictorType } from '../../statistics/types/enums';
import Carousel from '../Carousel/Carousel';
import MainPredictorCard from '../MainPredictorCard/MainPredictorCard';
import { ELEMENT_ID } from '../../constants';

import useStyles from './MainPredictorCards.style';

interface IMainPredictorCardsProps {
  overallOverview: IOverallOverview | undefined;
  isOverallOverviewLoading: boolean;
  polls: IPollInfo[];
}

export default function MainPredictorCards(props: IMainPredictorCardsProps) {
  const { isOverallOverviewLoading, overallOverview, polls } = props;
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const data = overallOverview?.statistics;

  const types = useMemo(() => ['enps', 'turnover'], []);

  const mainPredictorCards = types.map((type) => (
    <MainPredictorCard
      key={type}
      data={data?.[type as keyof IOverviewStatistics] as IStats}
      isLoading={isOverallOverviewLoading}
      type={type as MainPredictorType}
      polls={polls}
    />
  ));

  const container = middleScreen ? (
    <Carousel rootClassName={classes.carouselRoot}>{mainPredictorCards}</Carousel>
  ) : (
    <Group className={classes.container}>{mainPredictorCards}</Group>
  );

  return <div id={ELEMENT_ID.MAIN_PREDICTORS}>{container}</div>;
}
