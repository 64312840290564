import React from 'react';
import { Select, SelectItem } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { IChannelDto } from '../../types';

import useStyles from './styles';

interface Props {
  channelId: string | null;
  setChannelId: (x: string | null) => void;
  error: string | null;
  channels: IChannelDto[];
}

export default function ChannelSelect({ channelId, setChannelId, error, channels }: Props) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const ref = React.useRef<HTMLInputElement>(null);

  const data = React.useMemo<SelectItem[]>(() => channels.map((x) => ({ value: x.id, label: x.name })), [channels]);

  const classNames = React.useMemo(
    () => ({
      item: classes.selectItem,
    }),
    [classes],
  );

  const onKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  return (
    <Select
      data={data}
      value={channelId}
      onChange={setChannelId}
      label={t<string>('ScheduleSettingsForm.ChannelSelect.Label')}
      placeholder={t<string>('ScheduleSettingsForm.ChannelSelect.Placeholder')}
      error={error}
      classNames={classNames}
      ref={ref}
      onKeyDown={onKeyDown}
      limit={10}
      maxDropdownHeight={250}
      dropdownPosition="bottom"
      searchable
      hoverOnSearchChange
      withinPortal
      withAsterisk
      // eslint-disable-next-line
      labelProps={{ mb: 'xs' }}
    />
  );
}
