import { useQuery } from 'react-query';

import { ISettingsRole } from '../types';
import axios from '../axios';

const useGetSettingsRoles = (enabled = true) =>
  useQuery<ISettingsRole[], Error>(
    'settings/roles',
    () => axios.get<unknown, ISettingsRole[]>('/gateway/tilly/settings/roles'),
    {
      staleTime: Infinity,
      retry: true,
      enabled,
    },
  );

export default useGetSettingsRoles;
