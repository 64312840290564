import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    marginTop: theme.spacing.lg,
    gap: '30px',
  },
  selectedPredictorButton: {
    padding: '0px 12px',
    border: `1px solid ${theme.black}`,
    boxShadow: `0px 2px 0px ${theme.colors.black}, 0px 1px 0px ${theme.colors.black}`,
    borderRadius: '12px',
    backgroundColor: theme.colors.white[0],
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      fontWeight: 400,
      fontSize: 16,
    },
  },
  notSelectedPredictorButton: {
    padding: '0px 12px',
    backgroundColor: theme.colors.white[0],
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      borderBottom: `1px dotted ${theme.black}`,
      fontWeight: 400,
      fontSize: 16,
      height: '22px',
    },
  },
}));

export default useStyles;
