import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    rowGap: theme.spacing.xs,
    flexWrap: 'nowrap',
  },
}));

export default useStyles;
