import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  backToLanding: {},
  share: {
    padding: '0px !important',
  },
  installToSlack: {},
  logout: {
    padding: '0px !important',
  },
  settings: {
    padding: '0px !important',
  },
}));

export default useStyles;
