import React, { useMemo } from 'react';
import { Box, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { CellContext, HeaderContext } from '@tanstack/react-table';
import floatEqual from 'float-equal';

import { GroupBy, Plan } from '../../statistics/types/enums';
import useGetPollSlices from '../../statistics/queries/useGetPollSlices';
import useGetPollsRecent from '../../statistics/queries/useGetPollsRecent';
import goodMinus from '../../helpers/goodMinus';
import useGetWorkspaceInfo from '../../statistics/queries/useGetWorkspaceInfo';
import LongTextTruncate from '../LongTextTruncate/LongTextTruncate';
import HierarchicalCell from '../HierarchicalCell/HierarchicalCell';
import { useSelected } from '../../globalStates/Selected';
import { useComparison } from '../../globalStates/Comparison';
import { useGroupBy } from '../../globalStates/GroupBy';

import useStyles from './OverviewTable.cells.style';
import { ITableDataRow } from './Overview.types';
import { getDifferenceValue, getGroupByItems, getNumberValues } from './OverviewTable.helpers';
import { ROW_ID } from './OverviewTable.constants';
import GroupBySelect from './GroupBySelect/GroupBySelect';
import SortByButton from './SortByButton/SortByButton';
import CompareByButton from './CompareByButton/CompareByButton';

export function NameHeader() {
  const polls = useGetPollsRecent();
  const slices = useGetPollSlices(polls.data?.polls[0]?.id ?? 0, !!polls.data);
  const workspaceInfo = useGetWorkspaceInfo();

  if (polls.isLoading || slices.isLoading || workspaceInfo.isLoading) {
    return null;
  }

  const isFree = workspaceInfo.data?.plan === Plan.Free;
  const groupByItems = getGroupByItems(slices.data!, isFree);
  return <GroupBySelect groupByItems={groupByItems} isFree={isFree} />;
}

export function NameCell({ getValue, row }: CellContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [groupBy] = useGroupBy();

  const bodyRowClass = useMemo(() => {
    if (row.getCanExpand()) {
      return classes.bold;
    }

    return '';
  }, [row, classes]);

  const value = getValue<string>();
  const localizedValue = groupBy === GroupBy.Tenures ? t(`OverviewTable.${value}`) : value;

  const footRow = row.id === ROW_ID.OVERALL || row.id === ROW_ID.FILTERED;

  if (footRow) {
    return <Text>{localizedValue}</Text>;
  }

  return <LongTextTruncate text={localizedValue} maxWidth={125} className={bodyRowClass} align="start" />;
}

export function HierarchyCell({ table, row }: CellContext<ITableDataRow, unknown>) {
  return <HierarchicalCell row={row} table={table} />;
}

export function ComparisonCell({ row }: CellContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const [selected] = useSelected();
  const [comparison] = useComparison();

  const compared = comparison.comparison.map((x) => x.toString());

  function getShowAddButton() {
    if (row.id === ROW_ID.OVERALL) {
      return selected && !comparison.showOverall;
    }

    if (row.id === ROW_ID.FILTERED) {
      return selected && !comparison.showFiltered;
    }

    return selected && !compared.includes(row.id) && row.id !== selected;
  }

  function getShowRemoveButton() {
    if (row.id === ROW_ID.OVERALL) {
      return selected && comparison.showOverall;
    }

    if (row.id === ROW_ID.FILTERED) {
      return selected && comparison.showFiltered;
    }

    return selected && compared.includes(row.id) && row.id !== selected;
  }

  const showAddButton = getShowAddButton();
  const showRemoveButton = getShowRemoveButton();

  return (
    <div>
      {showAddButton && (
        <CompareByButton className="hidden" add row={row.id}>
          {t('OverviewTable.AddComparison')}
        </CompareByButton>
      )}
      {showRemoveButton && (
        <CompareByButton className="hidden" add={false} row={row.id}>
          {t('OverviewTable.RemoveComparison')}
        </CompareByButton>
      )}
    </div>
  );
}

export function PplHeader({ column }: HeaderContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <SortByButton
      className={classes.peopleCount}
      isSorted={column.getIsSorted()}
      toggleSortingHandler={column.toggleSorting}
    >
      {t('OverviewTable.PeopleCount')}
    </SortByButton>
  );
}

export function PplCell({ getValue }: CellContext<ITableDataRow, unknown>) {
  const { classes } = useStyles();

  return <Text className={classes.peopleCount}>{getValue<number>()}</Text>;
}

export function EnpsHeader({ column }: HeaderContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <SortByButton
      className={classes.positive}
      isSorted={column.getIsSorted()}
      toggleSortingHandler={column.toggleSorting}
    >
      {t('OverviewTable.eNPS')}
    </SortByButton>
  );
}

export function TurnoverHeader({ column }: HeaderContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <SortByButton
      className={classes.negative}
      isSorted={column.getIsSorted()}
      toggleSortingHandler={column.toggleSorting}
    >
      {t('OverviewTable.Turnover')}
    </SortByButton>
  );
}

export function PredictorPositiveHeader({ column }: HeaderContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <SortByButton
      className={classes.positive}
      isSorted={column.getIsSorted()}
      toggleSortingHandler={column.toggleSorting}
    >
      {t('OverviewTable.PredictorPositive')}
    </SortByButton>
  );
}

export function PredictorNegativeHeader({ column }: HeaderContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <SortByButton
      className={classes.negative}
      isSorted={column.getIsSorted()}
      toggleSortingHandler={column.toggleSorting}
    >
      {t('OverviewTable.PredictorNegative')}
    </SortByButton>
  );
}

export function PositiveCell({ getValue, table, column, row }: CellContext<ITableDataRow, unknown>) {
  const { classes, cx } = useStyles();

  const value = getValue<number | undefined>();

  if (value === undefined) {
    return <Text className={cx(classes.positive, classes.unknown)}>?</Text>;
  }

  const footRow = row.id === ROW_ID.OVERALL || row.id === ROW_ID.FILTERED;
  const { rows } = table.getRowModel();

  const values = getNumberValues(column, rows);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  const className =
    (floatEqual(minValue, value) || floatEqual(maxValue, value)) && !footRow
      ? cx(classes.positive, classes.bold)
      : classes.positive;

  return <Text className={className}>{goodMinus(value)}</Text>;
}

export function PositiveDiffHeader({ column }: HeaderContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  return (
    <SortByButton
      className={cx(classes.positive, classes.diff, classes.diffHeader)}
      isSorted={column.getIsSorted()}
      toggleSortingHandler={column.toggleSorting}
    >
      {t('OverviewTable.Diff')}
    </SortByButton>
  );
}

export function PositiveDiffCell({ getValue, table, column, row }: CellContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const value = getValue<number | undefined | null>();

  if (value === undefined || value === null) {
    return <Text />;
  }

  const { rows } = table.getRowModel();

  const values = getNumberValues(column, rows);
  const minDiff = Math.min(...values);
  const maxDiff = Math.max(...values);
  const footRow = row.id === ROW_ID.OVERALL || row.id === ROW_ID.FILTERED;

  const hidden = !column.getIsSorted() && !floatEqual(maxDiff, value) && !floatEqual(minDiff, value) && !footRow;
  const showArrowsUp = floatEqual(maxDiff, value) && !floatEqual(minDiff, value);
  const showArrowsDown = floatEqual(minDiff, value) && !floatEqual(maxDiff, value);

  const className = hidden ? cx('hidden', classes.positive, classes.diff) : cx(classes.positive, classes.diff);

  return (
    <Text className={className}>
      {getDifferenceValue(value)}
      {showArrowsUp && (
        <Box title={t('OverviewTable.MaximumDifferenceTitle')} className={classes.arrowsContainer}>
          <Text className={classes.arrows}>{t('OverviewTable.UpwardsPairedArrows')}</Text>
        </Box>
      )}
      {showArrowsDown && (
        <Box title={t('OverviewTable.MinimumDifferenceTitle')} className={classes.arrowsContainer}>
          <Text className={classes.arrows}>{t('OverviewTable.DownwardsPairedArrows')}</Text>
        </Box>
      )}
    </Text>
  );
}

export function NegativeCell({ getValue, table, column, row }: CellContext<ITableDataRow, unknown>) {
  const { classes, cx } = useStyles();

  const value = getValue<number | undefined>();

  if (value === undefined) {
    return <Text className={cx(classes.negative, classes.unknown)}>?</Text>;
  }

  const footRow = row.id === ROW_ID.OVERALL || row.id === ROW_ID.FILTERED;
  const { rows } = table.getRowModel();

  const values = getNumberValues(column, rows);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  const className =
    (floatEqual(minValue, value) || floatEqual(maxValue, value)) && !footRow
      ? cx(classes.negative, classes.bold)
      : classes.negative;

  return <Text className={className}>{goodMinus(value)}</Text>;
}

export function NegativeDiffHeader({ column }: HeaderContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  return (
    <SortByButton
      className={cx(classes.negative, classes.diff, classes.diffHeader)}
      isSorted={column.getIsSorted()}
      toggleSortingHandler={column.toggleSorting}
    >
      {t('OverviewTable.Diff')}
    </SortByButton>
  );
}

export function NegativeDiffCell({ getValue, table, column, row }: CellContext<ITableDataRow, unknown>) {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const value = getValue<number | undefined | null>();

  if (value === undefined || value === null) {
    return <Text />;
  }

  const { rows } = table.getRowModel();

  const values = getNumberValues(column, rows);
  const minDiff = Math.min(...values);
  const maxDiff = Math.max(...values);
  const footRow = row.id === ROW_ID.OVERALL || row.id === ROW_ID.FILTERED;

  const hidden = !column.getIsSorted() && !floatEqual(maxDiff, value) && !floatEqual(minDiff, value) && !footRow;
  const showArrowsUp = floatEqual(maxDiff, value) && !floatEqual(minDiff, value) && !footRow;
  const showArrowsDown = floatEqual(minDiff, value) && !floatEqual(maxDiff, value) && !footRow;

  const className = hidden ? cx('hidden', classes.negative, classes.diff) : cx(classes.negative, classes.diff);

  return (
    <Text className={className}>
      {getDifferenceValue(value)}
      {showArrowsUp && (
        <Box title={t('OverviewTable.MaximumDifferenceTitle')} className={classes.arrowsContainer}>
          <Text className={classes.arrows}>{t('OverviewTable.UpwardsPairedArrows')}</Text>
        </Box>
      )}
      {showArrowsDown && (
        <Box title={t('OverviewTable.MinimumDifferenceTitle')} className={classes.arrowsContainer}>
          <Text className={classes.arrows}>{t('OverviewTable.DownwardsPairedArrows')}</Text>
        </Box>
      )}
    </Text>
  );
}
