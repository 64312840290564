import React from 'react';
import { Slider, Switch, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface Props {
  scheduleWeeks: number | null;
  setScheduleWeeks: (x: number | null) => void;
}

export default function ScheduleIntervalInput({ scheduleWeeks, setScheduleWeeks }: Props) {
  const { t } = useTranslation();

  const scheduleEnabled = React.useMemo(() => scheduleWeeks !== null, [scheduleWeeks]);

  const onChangeScheduleEnabledSwitch: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (e) => {
      const enabled = e.currentTarget.checked;

      setScheduleWeeks(!enabled ? null : 12);
    },
    [setScheduleWeeks],
  );

  return (
    <>
      <Switch
        label={t('ScheduleSettingsForm.ScheduleIntervalInput.SwitchLabel')}
        color="violet"
        checked={scheduleEnabled}
        onChange={onChangeScheduleEnabledSwitch}
      />
      {scheduleEnabled && (
        <>
          <Text size={14} weight={500}>
            {t('ScheduleSettingsForm.ScheduleIntervalInput.SliderLabel')}
          </Text>
          <Slider
            color="violet"
            min={2}
            max={25}
            // eslint-disable-next-line
            marks={[
              { value: 2, label: '2' },
              { value: 25, label: '25' },
            ]}
            value={scheduleWeeks ?? undefined}
            onChange={setScheduleWeeks}
          />
          <Text color="dimmed" size={12} mt="sm">
            {t('ScheduleSettingsForm.ScheduleIntervalInput.Hint')}
          </Text>
        </>
      )}
    </>
  );
}
