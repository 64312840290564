import { AssessmentZone } from '../../types';

export const ENPS_DESCRIPTIONS: Record<string, string> = {
  bad: 'MainPredictorCard.eNPSBadDescription',
  normal: 'MainPredictorCard.eNPSNormalDescription',
  good: 'MainPredictorCard.eNPSGoodDescription',
  excellent: 'MainPredictorCard.eNPSExcellentDescription',
};

export const ENPS_ZONES: AssessmentZone[] = [
  {
    value: 0,
    level: 'bad',
  },
  {
    value: 0.3,
    level: 'normal',
  },
  {
    value: 0.7,
    level: 'good',
  },
  {
    value: 1,
    level: 'excellent',
  },
];
