import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  loaderContainer: {
    height: '24px',
    width: '130px',
  },
  title: {
    lineHeight: '24px',
  },
}));

export default useStyles;
