import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import axios from '../axios';
import { IApiResponse, IPollProgress } from '../types';

interface NotFoundResult {
  code: 'entity_not_found';
}

interface SuccessResult {
  code: 'success';
  value: IPollProgress;
}

const useGetPollProgress = () =>
  useQuery<SuccessResult | NotFoundResult, Error>(
    'settings/progress/poll',
    async () => {
      try {
        const response = await axios.get<unknown, IApiResponse<IPollProgress>>('/gateway/tilly/settings/progress/poll');
        return {
          code: 'success',
          value: response.value!,
        };
      } catch (er) {
        const error = er as AxiosError<IApiResponse<IPollProgress>>;

        if (error.response && error.response.data?.code === 'entity_not_found') {
          return {
            code: 'entity_not_found',
          };
        }

        throw new Error('Unreachable');
      }
    },
    {
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetPollProgress;
