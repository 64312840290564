import guardAgainstNull from '../../helpers/guardAgainstNull';
import group from '../../helpers/linq';
import { GroupBy } from '../../statistics/types/enums';
import {
  IGroupOverview,
  IGroupOverviewResponse,
  IOverallOverview,
  IPredictorStats,
  IStats,
} from '../../statistics/types/overview';
import { IDepartment, ILocation, IPollSlicesResponse, IRole } from '../../statistics/types/slicesAndInfo';

import { ITableDataRow } from './Overview.types';

const normalizeValue = (value: number) => Math.round((value + Number.EPSILON) * 100);

const normalizeDiff = (value: number) => Math.round((value + Number.EPSILON) * 100);

const normalizeStats = (stats: IStats): IStats => ({
  value: normalizeValue(stats.value),
  difference: guardAgainstNull(stats.difference, normalizeDiff),
});

const normalizePredictorStats = (predictorStats: IPredictorStats): IPredictorStats => ({
  positive: normalizeStats(predictorStats.positive),
  negative: normalizeStats(predictorStats.negative),
});

export const normalizeOverallOverview = (overview: IOverallOverview): IOverallOverview => ({
  ppl: overview.ppl,
  statistics: guardAgainstNull(overview.statistics, (statistics) => ({
    enps: normalizeStats(statistics.enps),
    turnover: guardAgainstNull(statistics.turnover, normalizeStats),
    community: guardAgainstNull(statistics.community, normalizePredictorStats),
    control: guardAgainstNull(statistics.control, normalizePredictorStats),
    workload: guardAgainstNull(statistics.workload, normalizePredictorStats),
    values: guardAgainstNull(statistics.values, normalizePredictorStats),
    reward: guardAgainstNull(statistics.reward, normalizePredictorStats),
    fairness: guardAgainstNull(statistics.fairness, normalizePredictorStats),
  })),
});

const normalizeGroupOverview = (overview: IGroupOverview): IGroupOverview => {
  const normalizedOverview = normalizeOverallOverview(overview);

  return {
    ...normalizedOverview,
    groupName: overview.groupName,
  };
};

const getDepartmentsTableData = (
  departments: IDepartment[],
  groupOverview: IGroupOverviewResponse,
): ITableDataRow[] => {
  const hierarchyDepartments = group(
    departments.filter((department) => department.parentId !== null),
    (item) => item.parentId,
  );

  function getSubDepartments(parentId: string): ITableDataRow[] {
    const subDepartments = hierarchyDepartments[parentId.toString()];
    if (!subDepartments) {
      return [];
    }

    return groupOverview.data
      .filter((department) => subDepartments.some((subDepartment) => subDepartment.name === department.groupName))
      .map((department) => normalizeGroupOverview(department))
      .map((department) => ({
        ...department,
        id: departments.find((d) => d.name === department.groupName)!.id.toString(),
      }));
  }

  return groupOverview.data
    .filter((department) => {
      const { parentId } = departments.find((d) => d.name === department.groupName)!;

      if (!parentId) {
        return true;
      }

      const parent = departments.find((d) => d.id === parentId)!;
      return !groupOverview.data.find((d) => d.groupName === parent.name);
    })
    .map((department) => normalizeGroupOverview(department))
    .map((department) => {
      const id = departments.find((x) => x.name === department.groupName)!.id.toString();

      return {
        ...department,
        subRows: getSubDepartments(id),
        id,
      };
    });
};

const getRolesTableData = (roles: IRole[], groupOverview: IGroupOverviewResponse): ITableDataRow[] => {
  const hierarchyRoles = group(
    roles.filter((role) => role.parentId !== null),
    (role) => role.parentId,
  );

  function getSubRoles(parentId: string): ITableDataRow[] {
    const subRoles = hierarchyRoles[parentId.toString()];
    if (!subRoles) {
      return [];
    }

    return groupOverview.data
      .filter((role) => subRoles.some((subDepartment) => subDepartment.name === role.groupName))
      .map((role) => normalizeGroupOverview(role))
      .map((role) => ({
        ...role,
        id: roles.find((x) => x.name === role.groupName)!.id.toString(),
      }));
  }

  return groupOverview.data
    .filter((role) => {
      const { parentId } = roles.find((r) => r.name === role.groupName)!;

      if (!parentId) {
        return true;
      }

      const parent = roles.find((r) => r.id === parentId)!;
      return !groupOverview.data.find((r) => r.groupName === parent.name);
    })
    .map((role) => normalizeGroupOverview(role))
    .map((role) => {
      const id = roles.find((x) => x.name === role.groupName)!.id.toString();

      return {
        ...role,
        subRows: getSubRoles(id),
        id,
      };
    });
};

function getLocationsTableData(locations: ILocation[], groupOverview: IGroupOverviewResponse): ITableDataRow[] {
  return groupOverview.data
    .map((location) => normalizeGroupOverview(location))
    .map((location) => ({
      ...location,
      id: locations.find((x) => x.name === location.groupName)!.id.toString(),
    }));
}

function getTenuresTableData(groupOverview: IGroupOverviewResponse): ITableDataRow[] {
  return groupOverview.data
    .map((tenure) => normalizeGroupOverview(tenure))
    .map((tenure) => ({
      ...tenure,
      id: tenure.groupName,
    }));
}

export const getTableData = (
  slices: IPollSlicesResponse,
  groupOverview: IGroupOverviewResponse,
  groupBy: GroupBy,
): ITableDataRow[] => {
  switch (groupBy) {
    case GroupBy.Departments:
      return getDepartmentsTableData(slices.departments, groupOverview);
    case GroupBy.Roles:
      return getRolesTableData(slices.roles, groupOverview);
    case GroupBy.Locations:
      return getLocationsTableData(slices.locations, groupOverview);
    case GroupBy.Tenures:
      return getTenuresTableData(groupOverview);
    default:
      throw new Error('Unreachable');
  }
};
