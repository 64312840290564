import React from 'react';
import { Center, RingProgress, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { IPredictorStats } from '../../../statistics/types/overview';
import isValueUnknown from '../PredictorCard.helpers';
import toPercent from '../../../helpers/toPercent';
import usePredictorCardStyles from '../PredictorCard.style';

import useRingChartsStyles from './RingCharts.style';

interface RingChartsProps {
  className: string;
  data: IPredictorStats | undefined;
  isFirstPoll: boolean;
}

export default function RingCharts(props: RingChartsProps) {
  const { className, data, isFirstPoll } = props;
  const unknownSymbol = '?';
  const { classes: predictorCardClasses, cx } = usePredictorCardStyles({ describe: false });
  const { classes: ringChartsClasses } = useRingChartsStyles();
  const theme = useMantineTheme();
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  let differenceSymbol = '';
  let difference = '';

  if (!isValueUnknown(data, 'positive')) {
    if (data?.positive.difference) {
      differenceSymbol = data?.positive.difference < 0 ? '↓' : '↑';
      difference = `${toPercent(Math.abs(data?.positive.difference), 1)}`;
    } else {
      differenceSymbol = '≈';
    }
  }

  return (
    <div className={cx(ringChartsClasses.container, className)}>
      {!isValueUnknown(data, 'positive') && (
        <RingProgress
          size={middleScreen ? 180 : 140}
          thickness={10}
          rootColor="transparent"
          // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
          sections={[{ value: toPercent(data?.positive.value, 1), color: 'green.2' }]}
          label={
            <Stack className={ringChartsClasses.ringLabel}>
              <Text className={cx(ringChartsClasses.value, predictorCardClasses.positive)}>
                {toPercent(data?.positive.value, 1)}
              </Text>
              {(differenceSymbol || difference) && !isFirstPoll && (
                <Text
                  className={cx(ringChartsClasses.difference, predictorCardClasses.positive)}
                >{`${differenceSymbol}${difference}`}</Text>
              )}
              {isFirstPoll && (
                <Text className={cx(ringChartsClasses.value, predictorCardClasses.negative)}>
                  {toPercent(data?.negative.value, 1)}
                </Text>
              )}
            </Stack>
          }
        />
      )}
      {!isValueUnknown(data, 'negative') && (
        <RingProgress
          className={ringChartsClasses.negativeRing}
          size={middleScreen ? 170 : 130}
          thickness={10}
          rootColor="transparent"
          // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
          sections={[{ value: toPercent(data?.negative.value, 1), color: 'red.4' }]}
        />
      )}
      {isValueUnknown(data, 'positive') && (
        <Center className={ringChartsClasses.questionContainer}>
          <Text className={cx(ringChartsClasses.question, predictorCardClasses.unknown)}>{unknownSymbol}</Text>
        </Center>
      )}
    </div>
  );
}
