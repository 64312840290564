import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  wrapper: {
    width: '340px',
    height: '160px',
    position: 'relative',
    // border: '1px dashed #0101011f',
  }
}));

export default useStyles;