import { MainPredictorType } from '../../statistics/types/enums';

export const settings = {
  width: 340,
  xAxisWidth: 332,
  yAxisHeight: 150,
  height: 160,
  padding: 10,
};

export type ObjectDataValues = {
  dateTitle: string;
  date: string;
  value: number;
  nullValue?: boolean;
};

export type ObjectData = {
  dataType: string;
  type: MainPredictorType;
  values: ObjectDataValues[];
};

export const names = {
  [MainPredictorType.Turnover]: 'Overall',
  [MainPredictorType.Enps]: 'Overall',
};
