import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  button: {
    fontSize: 12,
    color: theme.colors.blue[2],
  },
}));

export default useStyles;
