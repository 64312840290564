import React, { useCallback } from 'react';
import { CellContext } from '@tanstack/react-table';
import { ActionIcon, Menu, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { IconDotsVertical, IconList, IconMinus, IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { ISettingsSlice } from '../../types';
import LongTextTruncate from '../LongTextTruncate/LongTextTruncate';

import useStyles from './SliceSettingsDataTable.styles';
import { AdditionalSlice } from './SlicesSettings.types';

function HierarchicalButton({ onClick, isOpen }: { onClick: () => void; isOpen: boolean }) {
  const { classes } = useStyles();

  return (
    <UnstyledButton className={classes.hierarchyButton} onClick={onClick}>
      {isOpen ? (
        <IconMinus className={classes.hierarchyButtonIcon} />
      ) : (
        <IconPlus className={classes.hierarchyButtonIcon} />
      )}
    </UnstyledButton>
  );
}

export function HierarchyCell({ table, row }: CellContext<ISettingsSlice, unknown>) {
  const { classes, cx } = useStyles();
  const { rows } = table.getRowModel();

  const parent = row.parentId ? rows.find((r) => r.id === row.parentId) : undefined;
  const canExpand = row.getCanExpand();

  function getHierarchyClassName() {
    if (canExpand) {
      return cx(classes.hierarchy, classes.firstOfHierarchy);
    }

    if (parent?.subRows.at(-1)?.id === row.id) {
      return cx(classes.hierarchy, classes.lastOfHierarchy);
    }

    return classes.hierarchy;
  }

  function showHierarchy() {
    if (canExpand) {
      return row.getIsExpanded();
    }

    if (parent) {
      return parent.getIsExpanded();
    }

    return false;
  }

  const className = showHierarchy()
    ? cx(classes.hierarchyCellContainer, getHierarchyClassName())
    : classes.hierarchyCellContainer;

  return (
    <div className={className}>
      {canExpand && <HierarchicalButton onClick={row.getToggleExpandedHandler()} isOpen={row.getIsExpanded()} />}
    </div>
  );
}

export function NameCell({ getValue, row }: CellContext<ISettingsSlice, unknown>) {
  return <LongTextTruncate text={getValue<string>()} weight={row.getCanExpand() ? 'bold' : undefined} />;
}

export function OptionsCell({ table, row }: CellContext<ISettingsSlice, unknown>) {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const slice = table.options.meta?.dataType as AdditionalSlice | undefined;
  const isHierarchicalSlice = slice === AdditionalSlice.Departments || slice === AdditionalSlice.Roles;

  const getDataChangeHandler = useCallback(
    (action: string) =>
      table.options.meta?.getDataChangeHandler
        ? table.options.meta.getDataChangeHandler(row.original, action)
        : () => {
            console.warn('No value passed for getDataChangeHandler.');
          },
    [table, row],
  );

  return (
    <Menu shadow="md" width={200} withinPortal>
      <Menu.Target>
        <ActionIcon variant="subtle" color="light-gray.4">
          <IconDotsVertical size={20} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>
          <LongTextTruncate text={row.original.name} />
        </Menu.Label>
        <Menu.Divider />
        <Menu.Item icon={<IconPencil size={16} />} onClick={getDataChangeHandler('rename')}>
          {t('SlicesSettingsDataTable.Rename')}
        </Menu.Item>
        {isHierarchicalSlice && !row.parentId && (
          <Menu.Item icon={<IconPlus size={16} />} onClick={getDataChangeHandler('add_child')}>
            {t('SlicesSettingsDataTable.AddSubSlice')}
          </Menu.Item>
        )}
        {isHierarchicalSlice && !row.getCanExpand() && (
          <Menu.Item icon={<IconList size={16} />} onClick={getDataChangeHandler('move')}>
            {t('SlicesSettingsDataTable.MoveToParentSlice')}
          </Menu.Item>
        )}
        {(!isHierarchicalSlice || !row.getCanExpand()) && (
          <Menu.Item
            icon={<IconTrash color={theme.colors.red[0]} size={16} />}
            onClick={getDataChangeHandler('delete')}
          >
            <Text color="red">{t('SlicesSettingsDataTable.DeleteSlice')}</Text>
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}
