export default function waitForElement<E extends Element = Element>(selector: string, parent?: ParentNode): Promise<E> {
  const parentNode = parent ?? document.body;

  return new Promise((resolve) => {
    const existedElement = parentNode.querySelector<E>(selector);
    if (existedElement) {
      resolve(existedElement);
      return;
    }

    const observer = new MutationObserver(() => {
      const observedElement = parentNode.querySelector<E>(selector);
      if (observedElement) {
        observer.disconnect();
        resolve(observedElement);
      }
    });

    observer.observe(parentNode, {
      childList: true,
      subtree: true,
    });
  });
}
