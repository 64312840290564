import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  dropdown: {
    pointerEvents: 'none',
    backgroundColor: 'white',
    wordWrap: 'break-word',
    maxWidth: '300px',
    padding: '5px',
    borderRadius: '0px',
  },
}));

export default useStyles;
