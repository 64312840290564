import { IDepartment, ILocation, IRole, ITenure } from '../../statistics/types/slicesAndInfo';
import { GroupBy } from '../../statistics/types/enums';

import { ITableDataRow } from './FilterTable/FilterTable.types';

const getTableData = (data: IDepartment[] | IRole[] | ILocation[] | ITenure[], type: GroupBy): ITableDataRow[] => {
  function getSubRows(slice: IDepartment | IRole | ILocation | ITenure): ITableDataRow[] {
    if ('parentId' in slice) {
      return (
        data
          // eslint-disable-next-line
          // @ts-ignore
          .filter((s: IDepartment | IRole | ILocation | ITenure) => s.parentId === slice.id)
          .map((s: IDepartment | IRole | ILocation | ITenure) => ({
            id: 'id' in s ? s.id.toString() : s.name.toString(),
            ppl: s.ppl,
            name: s.name,
            type,
          }))
      );
    }

    return [];
  }

  return (
    data
      // eslint-disable-next-line
      // @ts-ignore
      .filter((s: IDepartment | IRole | ILocation | ITenure) => ('parentId' in s ? !s.parentId : true))
      .map((s: IDepartment | IRole | ILocation | ITenure) => ({
        id: 'id' in s ? s.id.toString() : s.name.toString(),
        ppl: s.ppl,
        name: s.name,
        type,
        subRows: getSubRows(s),
      }))
  );
};

export default getTableData;
