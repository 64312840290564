import { t } from 'i18next';
import Shepherd from 'shepherd.js';

import { ELEMENT_ID } from '../../../constants';

export default function addSortingFeaturesStep(tour: Shepherd.Tour) {
  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.SortingFeatures.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.SortingFeatures.Text'),
    attachTo: {
      element: `#${ELEMENT_ID.SORTING_FEATURES}`,
      on: 'bottom',
    },
  });
}
