import { t } from 'i18next';
import Shepherd from 'shepherd.js';

import { ELEMENT_ID, ELEMENT_CLASS } from '../../../constants';
import { OpenedFiltersContext } from '../../../globalStates/OpenedFilters';
import createOverlay from '../../../helpers/createOverlay';
import { GroupBy } from '../../../statistics/types/enums';
import { isVisible, onceHideHandlers } from '../helpers';
import { OVERLAY_INTERCEPTOR_CLASS } from '../constants';

export default function addFiltersSteps(tour: Shepherd.Tour) {
  const defaultNextFilter = GroupBy.Departments;
  let nextFilter = defaultNextFilter;

  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.Filters.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.Filters.Text'),
    attachTo: {
      element: `#${ELEMENT_ID.FILTERS}`,
      on: 'bottom',
    },
    beforeShowPromise() {
      const target = document.getElementById(ELEMENT_ID.FILTERS);

      if (!target) {
        return Promise.resolve();
      }

      const filters = target.querySelectorAll<HTMLElement>(`.${ELEMENT_CLASS.FILTER}`);

      filters.forEach((filter) => {
        const removeOverlay = createOverlay([filter], (x) => {
          Object.assign(x, {
            className: OVERLAY_INTERCEPTOR_CLASS,
          });
          x.addEventListener('click', () => {
            nextFilter = (filter.getAttribute('data-filter') as GroupBy | null) ?? nextFilter;
            tour.next();
          });
        });
        onceHideHandlers.subscribe(() => removeOverlay());
      });

      return Promise.resolve();
    },
  });

  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.FilterDropdown.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.FilterDropdown.Text'),
    attachTo: {
      element: `#${ELEMENT_ID.FILTER_DROPDOWN}`,
      on: 'left',
    },
    showOn() {
      return isVisible(`#${ELEMENT_ID.FILTERS}`);
    },
    modalOverlayOpeningPadding: 0,
    modalOverlayOpeningRadius: 8,
    beforeShowPromise() {
      return new Promise((resolve) => {
        OpenedFiltersContext.setOpened(nextFilter);
        OpenedFiltersContext.setFixed(true);

        onceHideHandlers.subscribe(() => {
          OpenedFiltersContext.setOpened(null);
          OpenedFiltersContext.setFixed(false);
          nextFilter = defaultNextFilter;
        });

        resolve(undefined);
      });
    },
  });
}
