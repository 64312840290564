import React from 'react';
import { Stack, Text, Title, UnstyledButton } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { IPredictorStats } from '../../statistics/types/overview';
import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import toPercent from '../../helpers/toPercent';
import { Predictor } from '../../statistics/types/enums';

import isValueUnknown from './PredictorCard.helpers';
import RingCharts from './RingCharts/RingCharts';
import useStyles from './PredictorCard.style';

interface IPredictorCardProps {
  data: IPredictorStats | undefined;
  isLoading: boolean;
  isFree: boolean;
  onClick: (value: Predictor) => () => void;
  type: Predictor;
  isFirstPoll: boolean;
  describe?: boolean;
}

export default function PredictorCard(props: IPredictorCardProps) {
  const { data, isLoading, isFree, onClick, type, isFirstPoll, describe } = props;
  const unknownSymbol = '?';
  const { cx, classes } = useStyles({ describe });
  const { t } = useTranslation();

  if (isLoading) {
    return <CustomSkeleton className={classes.container} />;
  }

  const description = (
    <>
      <Text className={cx(classes.caption, isFree && classes.blurred)}>
        {isValueUnknown(data, 'positive') ? (
          <Text className={classes.unknown} span>
            {unknownSymbol}
          </Text>
        ) : (
          <Text className={cx(classes.captionValue, classes.positive)} span>
            {`${toPercent(data?.positive.value, 1)}%`}
          </Text>
        )}
        {` ${t(`PredictorChart.PositiveTitle.${type}`)}`}
      </Text>
      <Text className={cx(classes.caption, isFree && classes.blurred)}>
        {isValueUnknown(data, 'negative') ? (
          <Text className={classes.unknown} span>
            {unknownSymbol}
          </Text>
        ) : (
          <Text className={cx(classes.captionValue, classes.negative)} span>
            {`${toPercent(data?.negative.value, 1)}%`}
          </Text>
        )}
        {` ${t(`PredictorChart.NegativeTitle.${type}`)}`}
      </Text>
    </>
  );

  return (
    <UnstyledButton className={classes.unstyledButton} onClick={onClick(type)}>
      <Stack className={classes.container}>
        <Title order={4} className={classes.title}>
          {t(`Predictor.${type}`)}
        </Title>
        <RingCharts className={isFree ? classes.blurred : ''} data={data} isFirstPoll={isFirstPoll} />
        {describe && description}
      </Stack>
    </UnstyledButton>
  );
}
