import { Cell, Column } from '@tanstack/react-table';

type CellsGroup<TData> = {
  id: string;
  column: Column<TData, unknown>;
  cells: Cell<TData, unknown>[];
};

export default function groupByColumns<TData>(cells: Cell<TData, unknown>[]) {
  return cells.reduce<CellsGroup<TData>[]>((map, e) => {
    if (!e.column.parent) {
      return [...map, { id: e.id, column: e.column, cells: [e] }];
    }

    const parentId = e.column.parent.id;
    const group = map.find((x) => x.id === parentId);
    if (group) {
      group.cells.push(e);
      return [...map];
    }

    return [...map, { id: parentId, column: e.column.parent, cells: [e] }];
  }, []);
}
