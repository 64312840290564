import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  wrapper: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.white,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1200px',
    minHeight: '100vh',
    padding: '8px 60px 20px',
  },
  main: {
    width: '100%',
    minHeight: 'calc(100vh - 60px - 28px)',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    width: '176px',
    height: '60px',
  },
  link: {
    color: theme.black,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '28px',
  },
  logoLink: {
    display: 'flex',
  },
  messageContainer: {
    maxWidth: '830px',
    marginTop: '20px',
    marginBottom: '32px',
  },
  message: {
    fontSize: theme.fontSizes.xl,
    fontWight: 400,
    lineHeight: '36px',
  },
}));

export default useStyles;
