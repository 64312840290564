import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { GroupBy } from '../statistics/types/enums';

import State from './State';

const Context = React.createContext<State<GroupBy>>([GroupBy.Departments, () => {}]);

export const useGroupBy = () => React.useContext(Context);

export default function GroupByProvider({ children }: React.PropsWithChildren) {
  const [groupBy, setGroupBy] = useQueryParam('groupBy', StringParam);

  const state = React.useMemo<State<GroupBy>>(() => {
    const value = groupBy ? (groupBy as GroupBy) : GroupBy.Departments;

    const update = (newValue: GroupBy) => {
      setGroupBy(newValue);
    };

    return [value, update];
  }, [groupBy, setGroupBy]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
