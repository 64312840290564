import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  positive: {
    color: `${theme.colors.green[2]} !important`,
    textAlign: 'end',
    position: 'relative',
    fontSize: '16px',
    marginLeft: '10px',
    marginRight: '5px',
  },
  negative: {
    color: `${theme.colors.red[4]} !important`,
    textAlign: 'end',
    position: 'relative',
    fontSize: '16px',
    marginLeft: '10px',
    marginRight: '5px',
  },
  diff: {
    fontSize: '13px',
    marginLeft: 0,
    marginRight: '10px',
  },
  diffHeader: {
    marginLeft: '10px',
    marginRight: '15px',
  },
  bold: {
    fontWeight: 'bold',
  },
  arrowsContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '-1.3em',
    width: '18px',
  },
  arrows: {
    letterSpacing: '-0.55em',
    position: 'relative',
    right: 7,
  },
  peopleCount: {
    color: `${theme.colors['light-gray'][6]} !important`,
    textAlign: 'end',
    position: 'relative',
    fontSize: '13px',
    marginLeft: '5px',
    marginRight: '20px',
  },
  unknown: {
    color: `${theme.colors['light-gray'][6]} !important`,
  },
}));

export default useStyles;
