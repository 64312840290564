import { useQuery } from 'react-query';

import axios from '../axios';
import { IUser } from '../types';

const useGetMe = () =>
  useQuery<IUser, Error>('user', () => axios.get('/user/me').then((data) => data.data), {
    staleTime: Infinity,
    retry: false,
  });

export default useGetMe;
