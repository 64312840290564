import { useMutation } from 'react-query';

import axios from '../axios';
import { BotSettingsSuccessResult, IApiResponse, IBotSettingsDto } from '../types';

import queryClient from './queryClient';

export interface IUpdateSettingsRequest {
  admins: string[];
  channel: string;
}

const useUpdateSettingsMutation = () =>
  useMutation<BotSettingsSuccessResult, Error, IUpdateSettingsRequest, unknown>(
    async (request: IUpdateSettingsRequest) => {
      const response = await axios.post<unknown, IApiResponse<IBotSettingsDto>>('/gateway/tilly/settings', request);

      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return {
        code: 'success',
        value: response.value,
      };
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData('settings', data);
      },
    },
  );

export default useUpdateSettingsMutation;
