import React from 'react';
import { Box } from '@mantine/core';

import { useComparison } from '../../../globalStates/Comparison';
import { useSelected } from '../../../globalStates/Selected';
import { useGroupBy } from '../../../globalStates/GroupBy';
import { GroupBy, Tenure } from '../../../statistics/types/enums';
import { ELEMENT_CLASS } from '../../../constants';

import useStyles from './BodyRow.style';

interface Props extends React.PropsWithChildren {
  groupId: string;
}

export default function BodyRow({ children, groupId }: Props) {
  const { classes, cx } = useStyles();
  const [comparison, setComparison] = useComparison();
  const [selected, setSelected] = useSelected();
  const [groupBy] = useGroupBy();

  const group = (groupBy === GroupBy.Tenures ? (groupId as Tenure) : Number(groupId)) as never;

  function getOptionalClassName() {
    if (comparison.comparison.includes(group)) {
      return 'compared';
    }

    return selected === groupId ? 'active' : undefined;
  }

  const onClickBodyRow = React.useCallback(() => {
    function getNewComparison() {
      const index = comparison.comparison.indexOf(group);

      if (index !== -1) {
        comparison.comparison.splice(index, 1);
      }

      return comparison.comparison;
    }

    if (selected === groupId) {
      setComparison({ ...comparison, comparison: [] });
      setSelected(null);
    } else {
      setComparison({ ...comparison, comparison: getNewComparison() });
      setSelected(group);
    }
  }, [selected, groupId, comparison, group, setComparison, setSelected]);

  return (
    <Box
      onClick={onClickBodyRow}
      className={cx(classes.row, getOptionalClassName(), ELEMENT_CLASS.OVERVIEW_TABLE_ROW)}
      data-group-id={groupId}
    >
      <div className="dot" />
      <div className="border" />
      <div className="background" />
      {children}
    </Box>
  );
}
