import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  row: {
    position: 'relative',
  },
  bold: {
    fontWeight: 'bold',
  },
  active: {
    backgroundColor: theme.white[7],
  },
  rightSection: {
    pointerEvents: 'none',
  },
  checkboxBody: {
    paddingRight: '8px',
    alignItems: 'center',
  },
  checkboxInput: {
    backgroundColor: theme.colors.white[0],
    border: `1px ${theme.colors.white[6]} solid`,
    borderRadius: theme.radius.xs,
    '&:checked, &:active': {
      backgroundColor: theme.white,
      border: `1px ${theme.colors.white[6]} solid`,
    },
    height: '17px',
    width: '17px',
  },
  checkboxInner: {
    height: '17px',
    width: '17px',
  },
  peopleCount: {
    textAlign: 'end',
    color: `${theme.colors['light-gray'][6]} !important`,
  },
}));

export default useStyles;
