import React from 'react';
import { Stack } from '@mantine/core';

import { Tenure } from '../../../statistics/types/enums';
import ComparisonBadge from '../ComparisonBadge/ComparisonBadge';
import Predictor from '../../Predictor/Predictor';
import { IPollInfo } from '../../../statistics/types/slicesAndInfo';

type Props = {
  id: number | Tenure;
  name: string | null | undefined;
  polls: IPollInfo[];
  poll: IPollInfo;
};

export default function ComparisonPredictor({ id, name, polls, poll }: Props) {
  return (
    <Stack spacing="sm" mt="lg" align="center">
      <ComparisonBadge comparisonId={id} name={name} />
      <Predictor id={id.toString()} selected={id.toString()} polls={polls} poll={poll} />
    </Stack>
  );
}
