import { createStyles, keyframes } from '@mantine/core';

const rotate = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(1turn)' },
});

const useStyles = createStyles(() => ({
  loader: {
    width: '100%',
    height: '100%',
  },
  ring: {
    animationName: `${rotate}`,
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(0.2, 0.2, 0.58, 0.8)',
  },
}));

export default useStyles;
