import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  selectItem: {
    '&[data-hovered], :hover': {
      color: theme.colors.gray[9],
      backgroundColor: theme.colors.gray[2],
    },
    '&[data-selected]': {
      color: theme.colors.gray[9],
      backgroundColor: theme.colors.gray[4],
    },
  },
}));

export default useStyles;
