import { useMutation } from 'react-query';
import dayjs from 'dayjs';

import axios from '../axios';
import { IApiResponse, IScheduleSettingsDto, IScheduleSettingsWebDto } from '../types';

import queryClient from './queryClient';

interface BotInstalledResult {
  code: 'bot_uninstalled';
}

interface ActivePollResult {
  code: 'active_poll';
}

interface NotConfiguredResult {
  code: 'not_configured';
  errors?: string[];
}

interface SuccessResult {
  code: 'success';
  value: IScheduleSettingsDto;
}

const useUpdateScheduleSettingsMutation = () =>
  useMutation<
    SuccessResult | BotInstalledResult | ActivePollResult | NotConfiguredResult,
    Error,
    IScheduleSettingsDto,
    unknown
  >(
    async (request: IScheduleSettingsDto) => {
      const webDto: IScheduleSettingsWebDto = {
        start: request.start?.toISOString() ?? null,
        interval: request.interval?.toISOString() ?? null,
        channelId: request.channelId,
        notificationText: request.notificationText,
      };
      const response = await axios.post<unknown, IApiResponse<IScheduleSettingsWebDto>>(
        '/gateway/tilly/settings/schedule',
        webDto,
      );

      if (!response || response.code !== 'success' || !response.value) {
        if (response.code === 'bot_uninstalled') {
          return {
            code: 'bot_uninstalled',
          };
        }

        if (response.code === 'active_poll') {
          return {
            code: 'active_poll',
          };
        }

        if (response.code === 'not_configured') {
          return {
            code: 'not_configured',
            errors: response.errors,
          };
        }

        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      const responseWebDto = response.value;

      return {
        code: 'success',
        value: {
          start: responseWebDto.start !== null ? new Date(responseWebDto.start) : null,
          interval: responseWebDto.interval !== null ? dayjs.duration(responseWebDto.interval) : null,
          channelId: responseWebDto.channelId,
          notificationText: responseWebDto.notificationText,
        },
      };
    },
    {
      onSuccess: (data) => {
        if (data.code === 'success' || data.code === 'bot_uninstalled') {
          queryClient.setQueryData('settings/schedule', data);
        }
      },
    },
  );

export default useUpdateScheduleSettingsMutation;
