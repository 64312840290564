import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    borderTop: 'none',
    backgroundColor: theme.colors.white[0],
    padding: `42px 16px 16px`,
    boxSizing: 'content-box',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      borderTop: `1px ${theme.colors.white[1]} solid`,
      backgroundColor: theme.white,
      padding: '25px 16px',
      boxSizing: 'border-box',
    },
  },
  floor: {
    gap: theme.spacing.md,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
    },
  },
  caption: {
    color: theme.colors.white[4],
    fontSize: theme.fontSizes.xs,
  },
  link: {
    color: theme.colors.white[4],
    fontSize: theme.fontSizes.xs,
    textWrap: 'nowrap',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      color: theme.black,
    },
  },
  linksGroup: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      rowGap: '2px',
      width: 'min-content',
    },
  },
}));

export default useStyles;
