import { useQuery } from 'react-query';

import { IApiResponse, IUserSettingsDto } from '../types';
import axios from '../axios';

const useGetUserSettings = () =>
  useQuery<IUserSettingsDto, Error>(
    'user-settings',
    async () => {
      const response = await axios.get<unknown, IApiResponse<IUserSettingsDto>>('/gateway/tilly/user-settings');
      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetUserSettings;
