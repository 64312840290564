import { DefaultMantineColor, createStyles } from '@mantine/core';

interface Props {
  color: DefaultMantineColor;
}

const useStyles = createStyles((theme, { color }: Props) => ({
  active: {
    backgroundColor: theme.colors[color][6],
    '&:hover': {
      backgroundColor: theme.colors[color][6],
    },
  },
  day: {
    '&[data-selected]': {
      backgroundColor: theme.colors[color][6],
    },
  },
  weekend: {
    color: theme.colors.red[4],
  },
}));

export default useStyles;
