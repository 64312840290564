import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMantineTheme } from '@mantine/core';

import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import useGetPollSlices from '../../statistics/queries/useGetPollSlices';
import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import useGetMainMetricsHistory from '../../statistics/queries/useGetMainMetricsHistory';
import useGetMainMetricsHistories from '../../statistics/queries/useGetMainMetricsHistories';
import { GroupBy, MainPredictorType } from '../../statistics/types/enums';
import { useFilter } from '../../globalStates/Filter';
import { useSelected } from '../../globalStates/Selected';
import { useComparison } from '../../globalStates/Comparison';
import { useGroupBy } from '../../globalStates/GroupBy';

import { clearSvg, initSvg, clearLines, createLines, getData, getGroupName, setFilter } from './Indicator.helpers';
import useStyles from './Indicator.style';

interface IIndicatorProps {
  type: MainPredictorType;
  polls: IPollInfo[];
}

export default function Indicator(props: IIndicatorProps) {
  const { type, polls } = props;
  const [activeType] = useState(type);
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const slices = useGetPollSlices(polls[0].id);
  const [filter] = useFilter();

  const [selected] = useSelected();
  const [comparison] = useComparison();
  const [groupBy] = useGroupBy();

  const activeDataName = getGroupName(slices, groupBy, selected);
  const activeDataNameTitle =
    !activeDataName || groupBy !== GroupBy.Tenures ? activeDataName : t(`Tenures.${activeDataName}`);

  // overall
  const { data: overallData, isLoading: isLoadingDefaultData } = useGetMainMetricsHistory(polls[0], {
    departments: [],
    locations: [],
    roles: [],
    tenures: [],
  });

  // selected
  const { data: selectedData, isLoading: isLoadingActiveData } = useGetMainMetricsHistory(
    polls[0],
    setFilter(filter, groupBy, selected),
  );
  const indicatorSelectedData = getData(polls, selectedData, type, activeDataNameTitle);

  // filtered
  const { data: filteredData } = useGetMainMetricsHistory(polls[0], filter);
  const indicatorFilteredData = getData(polls, filteredData, type, 'Filtered');

  const indicatorOverallData = comparison.showOverall
    ? getData(polls, overallData, type, 'Overall')
    : indicatorFilteredData;

  if (comparison.showFiltered && comparison.showOverall) {
    indicatorOverallData.push(...indicatorFilteredData);
  }

  // comparison
  const comparisonDatas = useGetMainMetricsHistories(
    polls[0],
    comparison.comparison.map((c) => setFilter(filter, groupBy, c as string)),
  );
  comparisonDatas.forEach(({ data }, i) => {
    const activeComparisonDataName = getGroupName(slices, groupBy, comparison.comparison[i].toString());
    const activeComparisonDataNameTitle =
      !activeComparisonDataName || groupBy !== GroupBy.Tenures
        ? activeComparisonDataName
        : t(`Tenures.${activeComparisonDataName}`);
    const comparisonData = getData(polls, data, type, activeComparisonDataNameTitle);
    indicatorOverallData.push(...comparisonData);
  });

  useEffect(() => {
    initSvg(
      containerRef,
      type,
      indicatorOverallData.find((d) => d.type === activeType),
    );
    return () => clearSvg(containerRef);
  }, [activeType, indicatorOverallData, indicatorSelectedData, type]);

  useEffect(() => {
    clearLines(type);
    if (selected) {
      createLines({
        activeType,
        dataset: indicatorOverallData,
        activeDataset: indicatorSelectedData[0],
        elementRef: containerRef,
        type,
        colorActiveLine: type === MainPredictorType.Enps ? theme.colors.green[2] : theme.colors.red[4],
        colorOtherLine: type === MainPredictorType.Enps ? theme.colors.green[2] : theme.colors.red[4],
        showOverall: comparison.showOverall,
        showFiltered: comparison.showFiltered,
      });
    } else {
      createLines({
        activeType,
        dataset: indicatorOverallData,
        activeDataset: undefined,
        elementRef: containerRef,
        type,
        colorActiveLine: type === MainPredictorType.Enps ? theme.colors.green[2] : theme.colors.red[4],
        colorOtherLine: type === MainPredictorType.Enps ? theme.colors.green[2] : theme.colors.red[4],
        showOverall: comparison.showOverall,
        showFiltered: comparison.showFiltered,
      });
    }
  }, [activeType, indicatorOverallData, indicatorSelectedData, type, selected, theme, comparison]);

  if (isLoadingDefaultData || (selected && isLoadingActiveData)) {
    return <CustomSkeleton className={classes.wrapper} />;
  }

  return <Box ref={containerRef} className={classes.wrapper} />;
}
