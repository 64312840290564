import React, { useEffect } from 'react';

import axios from '../../../axios';
import LoadingPage from '../../loading';
import { ISessionUrl } from '../../../types';
import { ROUTES } from '../../../constants';

export default function UpgradePageRedirect(): JSX.Element {
  useEffect(() => {
    const successURL = `${window.location.origin}${ROUTES.UPGRADE_SUCCEEDED}`;
    const cancelURL = `${window.location.origin}${ROUTES.UPGRADE_CANCELLED}`;

    axios
      .post<unknown, ISessionUrl>(
        `gateway/tilly/stripe/create-checkout-session?SuccessUrl=${successURL}&CancelUrl=${cancelURL}`,
      )
      .then((res) => {
        window.location.replace(res.sessionUrl);
      });
  });

  return <LoadingPage />;
}
