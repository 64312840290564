import React from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

import LoadingPage from '../../pages/loading';
import useGetMe from '../../queries/useGetMe';
import { ROUTES } from '../../constants';
import { useEncryptedParam } from '../../globalStates/EncryptedParam';

interface ProtectedRouteProps {
  children: JSX.Element;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { data: user, isLoading } = useGetMe();
  const [searchParams, setSearchParams] = useSearchParams();
  const [encryptedParam] = useEncryptedParam();

  React.useEffect(() => {
    const storedParams = localStorage.getItem('originalUrlParams');
    if (user && storedParams !== null) {
      const currentParams = new URLSearchParams(window.location.search);
      if (currentParams.get('selectAll') === 'true') {
        currentParams.delete('item');
      }
      if (currentParams.toString() !== storedParams) {
        setSearchParams(storedParams);
      } else {
        localStorage.removeItem('originalUrlParams');
      }
    }
  }, [searchParams, setSearchParams, user]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!user && !encryptedParam) {
    if (!localStorage.getItem('originalUrlParams')) {
      const storingParams = new URLSearchParams(window.location.search);
      if (storingParams.get('selectAll') === 'true') {
        storingParams.delete('item');
      }
      localStorage.originalUrlParams = storingParams.toString();
    }

    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    const state = {
      fromUrl: window.location.pathname.toLowerCase() === ROUTES.LOGIN ? window.location.origin : window.location.href,
    };

    return <Navigate to={ROUTES.LOGIN} replace state={state} />;
  }

  return children || <Outlet />;
}
