import React from 'react';
import { UseMutationResult, UseQueryResult } from 'react-query';
import { Box, Group, Stack } from '@mantine/core';

import {
  IDeleteSliceResponse,
  ISettingsDepartment,
  ISettingsLocation,
  ISettingsRole,
  ISettingsSlice,
  ISlicesSettingsDto,
} from '../../types';
import CustomLoader from '../CustomLoader/CustomLoader';

import SliceSettingsSwitch from './SliceSettingsSwitch';
import SliceSettingsDataTable from './SliceSettingsDataTable';
import { AdditionalSlice } from './SlicesSettings.types';
import SliceSettingsDeleteModal from './SliceSettingsDeleteModal';
import SliceSettingsMoveModal from './SliceSettingsMoveModal';
import SliceSettingsRenameModal from './SliceSettingsRenameModal';
import SliceSettingsAddModal from './SliceSettingsAddModal';
import SliceSettingsAddNewButton from './SliceSettingsAddNewButton';
import { useMutationModalDispatcher } from './SliceSettings.helpers';

interface PropsBase<TSlice extends ISettingsSlice> {
  slice: AdditionalSlice;
  slicesEnabledSettings: UseQueryResult<ISlicesSettingsDto, Error>;
  settingsSlices: UseQueryResult<TSlice[], Error>;
  deleteSettingsSlice: UseMutationResult<IDeleteSliceResponse, Error, TSlice, unknown>;
  updateSettingsSlice: UseMutationResult<TSlice, Error, TSlice, unknown>;
  createSettingsSlice: UseMutationResult<TSlice, Error, Omit<TSlice, 'id'>, unknown>;
}

interface DepartmentsProps extends PropsBase<ISettingsDepartment> {
  slice: AdditionalSlice.Departments;
}

interface RolesProps extends PropsBase<ISettingsRole> {
  slice: AdditionalSlice.Roles;
}

interface LocationsProps extends PropsBase<ISettingsLocation> {
  slice: AdditionalSlice.Locations;
}

type Props = DepartmentsProps | RolesProps | LocationsProps;

export default function SliceSettings({
  slice,
  slicesEnabledSettings,
  settingsSlices,
  deleteSettingsSlice,
  updateSettingsSlice,
  createSettingsSlice,
}: Props) {
  const {
    variables: deletingSlice,
    open: openDeleteSliceModal,
    close: closeDeleteSliceModal,
    submit: deleteSlice,
  } = useMutationModalDispatcher(deleteSettingsSlice);

  const {
    variables: movingSlice,
    open: openMoveSliceModal,
    close: closeMoveSliceModal,
    submit: moveSlice,
  } = useMutationModalDispatcher(updateSettingsSlice);

  const {
    variables: renamingSlice,
    open: openRenameSliceModal,
    close: closeRenameSliceModal,
    submit: renameSlice,
  } = useMutationModalDispatcher(updateSettingsSlice);

  const {
    variables: addingSlice,
    open: openAddSliceModal,
    close: closeAddSliceModal,
    submit: addSlice,
  } = useMutationModalDispatcher(createSettingsSlice);

  if (!slicesEnabledSettings.data || !settingsSlices.data) {
    return <CustomLoader />;
  }

  return (
    <Stack>
      <SliceSettingsSwitch
        settingsKey={`${slice}Enabled`}
        slicesEnabledSettings={slicesEnabledSettings.data}
        slicesList={settingsSlices.data}
      />
      <Box>
        {settingsSlices.data.length > 0 && (
          <SliceSettingsDataTable
            data={settingsSlices.data}
            slice={slice}
            onDeleteHandler={openDeleteSliceModal}
            onMoveHandler={openMoveSliceModal}
            onRenameHandler={openRenameSliceModal}
            onAddHandler={openAddSliceModal}
          />
        )}
        {!!slicesEnabledSettings.data[`${slice}Enabled`] && (
          <Group>
            <SliceSettingsAddNewButton handler={openAddSliceModal} />
          </Group>
        )}
      </Box>
      {!!deletingSlice && (
        <SliceSettingsDeleteModal
          deletingSlice={deletingSlice}
          close={closeDeleteSliceModal}
          submit={deleteSlice}
          isLoading={deleteSettingsSlice.isLoading}
          error={deleteSettingsSlice.error?.message}
        />
      )}
      {(slice === AdditionalSlice.Departments || slice === AdditionalSlice.Roles) && !!movingSlice && (
        <SliceSettingsMoveModal
          slice={slice}
          movingSlice={movingSlice}
          slices={settingsSlices.data}
          close={closeMoveSliceModal}
          submit={moveSlice}
          isLoading={updateSettingsSlice.isLoading}
          error={updateSettingsSlice.error?.message}
        />
      )}
      {!!renamingSlice && (
        <SliceSettingsRenameModal
          renamingSlice={renamingSlice}
          slices={settingsSlices.data}
          close={closeRenameSliceModal}
          submit={renameSlice}
          isLoading={updateSettingsSlice.isLoading}
          error={updateSettingsSlice.error?.message}
        />
      )}
      {!!slicesEnabledSettings.data[`${slice}Enabled`] && !!addingSlice && (
        <SliceSettingsAddModal
          slice={slice}
          addingSlice={addingSlice}
          slices={settingsSlices.data}
          close={closeAddSliceModal}
          submit={addSlice}
          isLoading={createSettingsSlice.isLoading}
          error={createSettingsSlice.error?.message}
        />
      )}
    </Stack>
  );
}
