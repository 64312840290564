import React from 'react';

import { ReactComponent as IconCheck } from '../../images/icons/check.svg';

export default function CheckboxIcon(props: { className?: string }) {
  const { className } = props;

  return <IconCheck className={className} />;
}

CheckboxIcon.defaultProps = {
  className: '',
};
