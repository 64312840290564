import { useQueries } from 'react-query';

import { IPollInfo } from '../types/slicesAndInfo';
import { IFilter } from '../types/filter';
import axios from '../../axios';
import { IMainMetricsHistoryResponse } from '../types/history';
import { IApiResponse } from '../../types';

const useGetMainMetricsHistories = (poll: IPollInfo, filters: IFilter[], limit = 6) =>
  useQueries(
    filters.map((filter) => ({
      queryKey: [`/stats/polls/${poll.id}/history/main`, filter],
      queryFn: async () => {
        const response = await axios.get<unknown, IApiResponse<IMainMetricsHistoryResponse>>(
          `/gateway/tilly/stats/polls/${poll.id}/history/main`,
          {
            params: { filter, limit },
          },
        );

        if (!response || response.code !== 'success' || !response.value) {
          throw new Error(
            `Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`,
          );
        }

        return response.value;
      },
    })),
  );

export default useGetMainMetricsHistories;
