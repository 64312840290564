import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  label: {
    textAlign: 'left',
    marginBottom: theme.spacing.xs,
    fontSize: theme.fontSizes.md,
  },
}));

export default useStyles;
