export default function toPercent(num: number | null | undefined, decimalPlacesForSmallValue?: number) {
  if (!num) return 0;

  if (decimalPlacesForSmallValue) {
    if (num > -0.01 && num < 0.01) {
      return +(num * 100).toFixed(decimalPlacesForSmallValue);
    }
  }

  return Math.round(num * 100);
}
