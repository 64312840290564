import React, { useCallback } from 'react';
import { Input, Popover, ScrollArea, SelectChevronIcon } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { GroupBy, Tenure } from '../../statistics/types/enums';
import { IDepartment, ILocation, IRole, ITenure } from '../../statistics/types/slicesAndInfo';
import groupByToQuery from '../../helpers/groupByToQuery';
import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import { usePaymentInfoModal } from '../../globalStates/PaymentInfoModal';
import { useFilter } from '../../globalStates/Filter';
import { useOpenedFilters } from '../../globalStates/OpenedFilters';
import { ELEMENT_ID } from '../../constants';

import { ITableDataRow } from './FilterTable/FilterTable.types';
import getTableData from './Filter.helpers';
import useStyles from './Filter.style';
import FilterTable from './FilterTable/FilterTable';

interface IFilterProps {
  data: IDepartment[] | IRole[] | ILocation[] | ITenure[] | undefined;
  isLoading: boolean;
  type: GroupBy;
  isFree: boolean;
}

function InternalFilter({ rows, type, isFree }: { rows: ITableDataRow[]; type: GroupBy; isFree: boolean }) {
  const { t } = useTranslation();
  const { cx, classes } = useStyles();
  const [filter, setFilter] = useFilter();

  const { opened: openedFilter, setOpened: setOpenedFilter, fixed } = useOpenedFilters();

  const slices = React.useMemo(() => {
    const key = groupByToQuery(type);
    return filter[key].map((x) => x.toString());
  }, [type, filter]);

  const setSlices = React.useCallback(
    (newSlices: string[]) => {
      const key = groupByToQuery(type);
      if (type === GroupBy.Tenures) {
        setFilter({ ...filter, [key]: newSlices as Tenure[] });
      } else {
        setFilter({ ...filter, [key]: newSlices.map((x) => parseInt(x, 10)) });
      }
    },
    [type, filter, setFilter],
  );

  const opened = React.useMemo(() => openedFilter === type, [openedFilter, type]);
  const setOpened = React.useCallback(
    (value: boolean) => {
      if (fixed) {
        return;
      }

      setOpenedFilter(value ? type : null);
    },
    [setOpenedFilter, type, fixed],
  );
  const toggleOpened = React.useCallback(() => setOpened(!opened), [opened, setOpened]);

  const showHierarchy = rows.some((r) => r.subRows !== undefined && r.subRows.length > 0);
  const rowPlaceholder = t(`Filter.${type}Placeholder`);
  const oneFilterRowActive = slices.length === 1;
  const noFilterActive = slices.length === 0;
  const activeFilter = slices.length > 0;

  function getFilterPlaceholder() {
    if (noFilterActive) {
      return rowPlaceholder;
    }

    if (oneFilterRowActive) {
      const name =
        type === GroupBy.Tenures
          ? t(`OverviewTable.${slices[0]}`)
          : rows
              .map((row) => row.subRows)
              .flat()
              .concat(rows)
              .find((row) => row && row.id === slices[0].toString())!.name;

      return t(`Filter.One${type}Placeholder`, { name });
    }

    return t(`Filter.Multiple${type}Placeholder`, { count: slices.length });
  }

  const filterPlaceholder = getFilterPlaceholder();

  const inputBaseClasses = React.useMemo(
    () => ({
      input: activeFilter ? cx(classes.input, classes.activeFilter) : classes.input,
      rightSection: classes.rightSection,
      wrapper: classes.inputWrapper,
    }),
    [cx, classes, activeFilter],
  );

  const popoverClasses = React.useMemo(
    () => ({
      dropdown: cx(classes.dropdown, classes.dropdownSlices),
    }),
    [cx, classes],
  );

  const [, setOpenedPaymentInfoModal] = usePaymentInfoModal();
  const openPaymentInfoModal = useCallback(() => setOpenedPaymentInfoModal(true), [setOpenedPaymentInfoModal]);

  if (isFree) {
    return (
      <Input
        onClick={openPaymentInfoModal}
        component="button"
        classNames={inputBaseClasses}
        rightSection={<SelectChevronIcon size="sm" error={false} />}
      >
        {filterPlaceholder}
      </Input>
    );
  }

  return (
    <Popover
      classNames={popoverClasses}
      offset={-1}
      position="bottom-start"
      transitionDuration={0}
      withinPortal
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Input
          component="button"
          onClick={toggleOpened}
          classNames={inputBaseClasses}
          rightSection={<SelectChevronIcon size="sm" error={false} />}
        >
          {filterPlaceholder}
        </Input>
      </Popover.Target>
      <Popover.Dropdown id={ELEMENT_ID.FILTER_DROPDOWN}>
        <ScrollArea.Autosize maxHeight={500} scrollHideDelay={500} scrollbarSize={4} type="hover">
          <FilterTable rows={rows} showHierarchy={showHierarchy} slices={slices} setSlices={setSlices} />
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
}

export default function Filter(props: IFilterProps) {
  const { data, isLoading, type, isFree } = props;
  const { cx, classes } = useStyles();

  if (isLoading) {
    return <CustomSkeleton className={cx(classes.inputSlices, classes.loaderContainer)} />;
  }

  const rows = getTableData(data!, type);

  if (!rows.length && !isFree) {
    return null;
  }

  return <InternalFilter rows={rows} type={type} isFree={isFree} />;
}
