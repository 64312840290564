import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import axios from '../axios';

import queryClient from './queryClient';

const notDeletableQueries = ['workspaceId', 'workspaceName'];

const useLogoutMutation = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMutation(() => axios.post('/user/logout'), {
    onSuccess: () => {
      const keys = Array.from(searchParams.keys());
      keys.forEach((key) => {
        if (notDeletableQueries.includes(key)) {
          return;
        }

        searchParams.delete(key);
      });

      setSearchParams(searchParams);
      queryClient.resetQueries('user');
    },
  });
};

export default useLogoutMutation;
