import { ColumnDef } from '@tanstack/react-table';

import { GroupBy } from '../../../statistics/types/enums';
import compareTenures from '../../../helpers/compareTenures';

import { ITableDataRow } from './FilterTable.types';
import { COLUMN_ID } from './FilterTable.constants';
import { HierarchyCell, NameCell, PplCell } from './FilterTable.cells';

const COLUMN_DEF: ColumnDef<ITableDataRow>[] = [
  {
    id: COLUMN_ID.HIERARCHY,
    accessorFn: (row) => row.name,
    cell: HierarchyCell,
    size: 30,
  },
  {
    id: COLUMN_ID.NAME,
    accessorFn: (row) => row.name,
    cell: NameCell,
    sortingFn: (a, b) => {
      const left = a.original.name;
      const right = b.original.name;
      if (a.original.type === GroupBy.Tenures) {
        // eslint-disable-next-line
        // @ts-ignore
        return compareTenures(left, right);
      }

      return left.localeCompare(right);
    },
    size: 165,
  },
  {
    id: COLUMN_ID.PEOPLE_COUNT,
    accessorFn: (row) => row.ppl,
    cell: PplCell,
    size: 45,
  },
];

export default COLUMN_DEF;
