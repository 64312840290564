import React from 'react';
import { Button, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import isDemo from '../../../helpers/isDemo';
import BackToLanding from '../../../components/BackToLanding/BackToLanding';
import InstallToSlack from '../../../components/InstallToSlack/InstallToSlack';
import ShareLink from '../../../components/ShareLink/ShareLink';
import LogoutPopover from '../../../components/Logout/LogoutPopover';
import { useEncryptedParam } from '../../../globalStates/EncryptedParam';
import { ROUTES } from '../../../constants';

import useStyles from './FooterButtons.style';

export default function FooterButtons() {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  const [encryptedParam] = useEncryptedParam();
  const shared = !!encryptedParam;

  if (isDemo()) {
    return (
      <>
        <BackToLanding buttonClass={classes.backToLanding} />
        <InstallToSlack buttonClass={classes.installToSlack} />
      </>
    );
  }

  if (!shared) {
    return (
      <>
        <ShareLink buttonClass={classes.share} />
        <Button variant="white" className={classes.settings} component={Link} to={ROUTES.SETTINGS}>
          <Text weight="normal" color={theme.black} size={16} underline>
            {t('Settings')}
          </Text>
        </Button>
        <LogoutPopover logoutText="LogoutButton" buttonClass={classes.logout} />
      </>
    );
  }

  return null;
}
