import React from 'react';
import { Popover, Text, UnstyledButton } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconSelector } from '@tabler/icons-react';

import { ELEMENT_ID } from '../../../constants';
import { GroupBy } from '../../../statistics/types/enums';
import { usePaymentInfoModal } from '../../../globalStates/PaymentInfoModal';
import { useSelected } from '../../../globalStates/Selected';
import { useComparison } from '../../../globalStates/Comparison';
import { useGroupBy } from '../../../globalStates/GroupBy';
import { useOpenedGroupBy } from '../../../globalStates/OpenedGroupBy';

import useStyles from './GroupBySelect.style';

export default function GroupBySelect({ groupByItems, isFree }: { groupByItems: GroupBy[]; isFree: boolean }) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [groupBy, setGroupBy] = useGroupBy();
  const [, setPaymentInfoModalOpened] = usePaymentInfoModal();
  const [, setSelected] = useSelected();
  const [, setComparison] = useComparison();

  const { opened, setOpened: setOpenedWithoutCheck, fixed } = useOpenedGroupBy();

  const setOpened = React.useCallback(
    (value: boolean) => {
      if (fixed) {
        return;
      }

      setOpenedWithoutCheck(value);
    },
    [fixed, setOpenedWithoutCheck],
  );

  const toggleOpened = React.useCallback(() => setOpened(!opened), [opened, setOpened]);

  const onChange = React.useCallback(
    (value: GroupBy) => {
      if (value === groupBy) {
        return;
      }

      if (isFree) {
        setPaymentInfoModalOpened(true);
      } else {
        setGroupBy(value);

        setSelected(null);
        setComparison({ comparison: [], showOverall: true, showFiltered: false });
      }
    },
    [isFree, groupBy, setGroupBy, setPaymentInfoModalOpened, setSelected, setComparison],
  );

  const getChangeHandler = React.useCallback(
    (x: GroupBy) => () => {
      setOpened(false);
      onChange(x);
    },
    [onChange, setOpened],
  );

  const getLabel = React.useCallback((x: GroupBy) => t(`OverviewTable.${x}`), [t]);

  const popoverClasses = React.useMemo(() => ({ dropdown: classes.dropdown }), [classes]);

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      classNames={popoverClasses}
      width="target"
      position="bottom"
      withinPortal
      offset={0}
      transitionDuration={0}
    >
      <Popover.Target>
        <div>
          <UnstyledButton
            id={ELEMENT_ID.GROUP_BY_SELECT}
            onClick={toggleOpened}
            className={classes.root}
            aria-expanded={opened}
          >
            <Text>{getLabel(groupBy)}</Text>
            <IconSelector className={classes.selectorIcon} />
          </UnstyledButton>
        </div>
      </Popover.Target>
      <Popover.Dropdown id={ELEMENT_ID.GROUP_BY_DROPDOWN}>
        {groupByItems.map((x) => (
          <UnstyledButton className={classes.item} key={x} onClick={getChangeHandler(x)}>
            {getLabel(x)}
          </UnstyledButton>
        ))}
      </Popover.Dropdown>
    </Popover>
  );
}
