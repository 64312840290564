import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  selectItem: {
    '&[data-selected], :hover': {
      color: theme.colors.gray[9],
      backgroundColor: theme.colors.gray[3],
    },
    borderRadius: '4px',
  },
}));

export default useStyles;
