import React, { CSSProperties } from 'react';
import { DefaultProps, MantineTransition, Popover, Selectors, Text, TextProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import useStyles from './LongTextTruncate.style';
import useIsTruncated from './LongTextTruncate.helpers';

interface Props extends DefaultProps<Selectors<typeof useStyles> & TextProps['classNames']>, TextProps {
  text: string;
  maxWidth?: CSSProperties['maxWidth'];
}

const transition: MantineTransition = {
  in: { opacity: 0.9, transitionDelay: '500ms' },
  out: { opacity: 0, transitionDelay: '1000ms' },
  transitionProperty: 'opacity, transitionDelay',
};

export default function LongTextTruncate({
  text,
  maxWidth,
  classNames,
  styles,
  unstyled,
  className,
  ...others
}: Props) {
  const { classes } = useStyles(undefined, { name: 'LongTextTruncate', classNames, styles, unstyled });
  const [opened, { close, open }] = useDisclosure(false);

  const { ref, isTruncated } = useIsTruncated([text, maxWidth]);

  if (!isTruncated) {
    return (
      <Text truncate maw={maxWidth ?? '100%'} ref={ref} className={className} {...others}>
        {text}
      </Text>
    );
  }

  return (
    <Popover
      position="top-start"
      shadow="xs"
      offset={5}
      opened={opened}
      transition={transition}
      exitTransitionDuration={1000}
      withinPortal
    >
      <Popover.Target>
        <Text
          truncate
          maw={maxWidth}
          onMouseEnter={open}
          onMouseLeave={close}
          ref={ref}
          className={className}
          {...others}
        >
          {text}
        </Text>
      </Popover.Target>
      <Popover.Dropdown className={classes.dropdown}>
        <Text>{text}</Text>
      </Popover.Dropdown>
    </Popover>
  );
}
