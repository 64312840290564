import React from 'react';
import { UnstyledButton, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '@tanstack/react-table';
import { useMediaQuery } from '@mantine/hooks';

import useStyles from './SortByButton.styles';

interface Props extends React.PropsWithChildren {
  className?: string;
  isSorted: false | SortDirection;
  toggleSortingHandler: (desc?: boolean) => void;
}

export default function SortByButton({ children, className, isSorted, toggleSortingHandler }: Props) {
  const { t } = useTranslation();
  const { classes, theme, cx } = useStyles();
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const onClick = React.useCallback(() => {
    if (!isSorted) {
      toggleSortingHandler();
    } else {
      toggleSortingHandler(isSorted === 'asc');
    }
  }, [isSorted, toggleSortingHandler]);

  const buttonClassName = isSorted ? cx(classes.button, className, 'sorted') : cx(classes.button, className);

  return (
    <UnstyledButton onClick={onClick} component="div" className={buttonClassName}>
      {children}{' '}
      {!middleScreen &&
        (!isSorted || isSorted === 'desc' ? (
          <Text className="icon">{t('OverviewTable.DownwardsArrow')}</Text>
        ) : (
          <Text className="icon">{t('OverviewTable.UpwardsArrow')}</Text>
        ))}
      {middleScreen && isSorted === 'desc' && <Text className="icon">{t('OverviewTable.DownwardsArrow')}</Text>}
      {middleScreen && isSorted === 'asc' && <Text className="icon">{t('OverviewTable.UpwardsArrow')}</Text>}
      {middleScreen && !isSorted && (
        <>
          <Text className="icon">{t('OverviewTable.DownwardsArrow')}</Text>
          <Text className="iconRight">{t('OverviewTable.UpwardsArrow')}</Text>
        </>
      )}
    </UnstyledButton>
  );
}
