import { useQuery } from 'react-query';

import axios from '../axios';
import { IApiResponse, IUserDto } from '../types';

interface SuccessResult {
  code: 'success';
  value: IUserDto[];
}

interface BotInstalledResult {
  code: 'bot_uninstalled';
}

const useGetSettingsUsers = () =>
  useQuery<SuccessResult | BotInstalledResult, Error, SuccessResult | BotInstalledResult>(
    'settings/users',
    async () => {
      const response = await axios.get<unknown, IApiResponse<IUserDto[]>>('/gateway/tilly/settings/users');
      if (!response || response.code !== 'success' || !response.value) {
        if (response.code === 'bot_uninstalled') {
          return {
            code: 'bot_uninstalled',
          };
        }

        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return {
        code: 'success',
        value: response.value,
      };
    },
    {
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetSettingsUsers;
