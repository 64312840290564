import React, { useMemo } from 'react';
import { Stack, Center, Box } from '@mantine/core';
import { useMediaQuery, useTimeout } from '@mantine/hooks';

import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import MainPredictorCards from '../MainPredictorCards/MainPredictorCards';
import PredictorButtons from '../PredictorButtons/PredictorButtons';
import PredictorCards from '../PredictorCards/PredictorCards';
import Predictor from '../Predictor/Predictor';
import useGetOverallOverview from '../../statistics/queries/useGetOverallOverview';
import { Tenure } from '../../statistics/types/enums';
import SelectedGroupBadge from '../SelectedGroupBadge/SelectedGroupBadge';
import FilteredBadge from '../FilteredBadge/FilteredBadge';
import PredictorSelect from '../PredictorSelect/PredictorSelect';
import { useFilter } from '../../globalStates/Filter';
import groupByToQuery from '../../helpers/groupByToQuery';
import { useSelected } from '../../globalStates/Selected';
import { useGroupBy } from '../../globalStates/GroupBy';
import { usePredictor } from '../../globalStates/Predictor';
import { ELEMENT_ID } from '../../constants';
import useGetOverallOverviews from '../../statistics/queries/useGetOverallOverviews';
import { useComparison } from '../../globalStates/Comparison';
import { getGroupName, setFilter } from '../Indicator/Indicator.helpers';
import useGetPollSlices from '../../statistics/queries/useGetPollSlices';
import { isOnboardingShouldStartAutomatically } from '../../helpers/onboarding';
import tour from '../StatisticsOnboarding/tour';

import useStyles from './StatisticsBody.style';
import ComparisonPredictorCards from './ComparisonPredictorCards/ComparisonPredictorCards';
import ComparisonPredictor from './ComparisonPredictor/ComparisonPredictor';

export default function StatisticsBody({ poll, polls }: { poll: IPollInfo; polls: IPollInfo[] }) {
  const [selected] = useSelected();
  const [filter] = useFilter();
  const [groupBy] = useGroupBy();
  const [{ predictor }] = usePredictor();
  const [comparison] = useComparison();
  const slices = useGetPollSlices(polls[0].id);
  const withCharts = polls.length > 1;
  const isFirstPoll = polls.length === 1;

  const filterWithSelected = useMemo(() => {
    if (!selected) {
      return filter;
    }

    const key = groupByToQuery(groupBy);
    if (key === 'tenures') {
      return { ...filter, [key]: [selected as Tenure] };
    }
    return { ...filter, [key]: [parseInt(selected as string, 10)] };
  }, [filter, groupBy, selected]);

  const filtered =
    !!filter.departments.length || !!filter.roles.length || !!filter.locations.length || !!filter.tenures.length;

  const { data: overallOverview, isLoading: isOverallOverviewLoading } = useGetOverallOverview(
    poll,
    filterWithSelected,
  );

  const comparisonsWithNames = comparison.comparison.map((c) => ({
    comparison: c,
    name: getGroupName(slices, groupBy, c.toString()),
  }));

  const comparisonOverallOverviews = useGetOverallOverviews(
    poll,
    comparison.comparison.map((c) => setFilter(filter, groupBy, c as string)),
  );

  const comparisonOverallOverviewsData = comparisonOverallOverviews.map(({ data, isLoading }, i) => {
    const activeComparisonDataName = getGroupName(slices, groupBy, comparison.comparison[i].toString());

    return {
      data,
      name: activeComparisonDataName,
      id: comparison.comparison[i],
      isLoading,
    };
  });

  const { classes, theme } = useStyles();
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  useTimeout(
    () => {
      if (isOnboardingShouldStartAutomatically()) {
        tour.start();
      }
    },
    200,
    {
      autoInvoke: true,
    },
  );

  return (
    <Stack className={classes.container}>
      <Center className={classes.selectedGroupBadgeContainer}>
        {filtered && <FilteredBadge poll={poll} />}
        {selected && <SelectedGroupBadge poll={poll} />}
      </Center>
      <Box m="auto">
        <MainPredictorCards
          isOverallOverviewLoading={isOverallOverviewLoading}
          overallOverview={overallOverview}
          polls={polls}
        />
      </Box>
      <Box m="auto">
        <div id={ELEMENT_ID.PREDICTORS}>
          {!middleScreen && predictor && <PredictorButtons />}
          {middleScreen && predictor && <PredictorSelect />}
          {predictor && withCharts && (
            <>
              <Predictor id="main" selected={selected} polls={polls} poll={poll} />
              {comparisonsWithNames.map((c) => (
                <ComparisonPredictor key={c.comparison} id={c.comparison} name={c.name} polls={polls} poll={poll} />
              ))}
            </>
          )}
          {!predictor && (
            <>
              <PredictorCards
                isOverallOverviewLoading={isOverallOverviewLoading}
                overallOverview={overallOverview}
                isFirstPoll={isFirstPoll}
                isMain
              />
              {comparisonOverallOverviewsData.map(({ data, name, isLoading, id }) => (
                <ComparisonPredictorCards
                  key={id}
                  isLoading={isLoading}
                  data={data}
                  isFirstPoll={isFirstPoll}
                  id={id}
                  name={name}
                />
              ))}
            </>
          )}
        </div>
      </Box>
    </Stack>
  );
}
