import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  predictorSelectAndFilters: {
    flexGrow: 1,
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
  },
  burger: {
    position: 'absolute',
    top: '35px',
    right: '35px',
  },
}));

export default useStyles;
