import { IPollDto } from '../../types';

export type Poll = {
  id: number;
  startedAt: Date;
  respondersCount: number;
};

function comparePolls(a: Poll, b: Poll) {
  if (a.startedAt < b.startedAt) {
    return 1;
  }
  if (a.startedAt > b.startedAt) {
    return -1;
  }
  return 0;
}

export function convertToPolls(pollDtos: IPollDto[]): Poll[] {
  return pollDtos
    .map((p) => ({
      id: p.id,
      startedAt: new Date(p.startDateTimeUtc),
      respondersCount: p.respondersCount,
    }))
    .sort(comparePolls);
}
