import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  row: {
    width: 'max-content',
    padding: '0px 16px 0px 0px',
    position: 'relative',
    zIndex: 0,
    '.border': {
      zIndex: 1,
    },
    '.border, .background': {
      pointerEvents: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      right: '0px',
      borderRight: '1px solid transparent',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        width: 'calc(100% - 2px)',
        left: '0px',
        border: 'solid transparent',
        zIndex: -1,
      },
      '&::before': {
        height: '50%',
        top: '0px',
        borderWidth: '1px 0px 0px 1px',
        transform: 'skew(-35deg)',
        transformOrigin: 'right bottom',
      },
      '&::after': {
        height: 'calc(50% + 1px)',
        bottom: '0px',
        borderWidth: '0px 0px 1px 1px',
        transform: 'skew(35deg)',
        transformOrigin: 'left top',
      },
    },
    '&:hover': {
      cursor: 'pointer',
      '.hidden': {
        display: 'block',
      },
      '.border, .border::before, .border::after': {
        borderColor: theme.colors['light-gray'][5],
      },
    },
    '&.active': {
      '.hidden': {
        display: 'block',
      },
      '.border, .border::before, .border::after': {
        borderColor: 'black',
      },
      '.background::before, .background::after': {
        backgroundColor: 'white',
      },
    },
    '&.compared': {
      '.hidden': {
        display: 'block',
      },
      '.dot': {
        backgroundColor: theme.colors['light-gray'][6],
      },
    },
    '.dot': {
      position: 'absolute',
      width: 6,
      height: 6,
      top: 'calc(50% - 3px)',
      left: '9px',
      borderRadius: '50%',
    },
    '.hidden': {
      display: 'none',
    },
  },
}));

export default useStyles;
