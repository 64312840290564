import { enUS } from 'date-fns/locale';
import { isDate, format as formatDate } from 'date-fns';
import { FormatFunction } from 'i18next';

const locales = { en: enUS };

const formatFunction: FormatFunction = (value, format) => {
  if (isDate(value)) {
    const locale: Locale = locales.en;

    return formatDate(value, format ?? 'P', { locale });
  }

  return value;
};

export default formatFunction;
