import { useQuery } from 'react-query';

import { IApiResponse, ISlicesSettingsDto } from '../types';
import axios from '../axios';

const useGetSlicesSettings = () =>
  useQuery<ISlicesSettingsDto, Error>(
    'settings/slices',
    async () => {
      const response = await axios.get<unknown, IApiResponse<ISlicesSettingsDto>>('/gateway/tilly/settings/slices');
      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetSlicesSettings;
