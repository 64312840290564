import { createStyles, keyframes, MantineTheme } from '@mantine/core';

const flashing = keyframes({
  '0%': { backgroundColor: 'rgb(128,128,128,0.15)' },
  '50%': { backgroundColor: 'rgb(128,128,128,0.07)' },
  '100%': { backgroundColor: 'rgb(128,128,128,0.15)' },
});

const useStyles = createStyles((theme: MantineTheme) => ({
  skeleton: {
    animationName: `${flashing}`,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    borderRadius: theme.radius.lg,
  },
}));

export default useStyles;
