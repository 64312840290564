import React from 'react';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SlackIcon } from '../../images/slack-logo.svg';

import useStyles from './InstallToSlack.style';

export default function InstallToSlack({ buttonClass }: { buttonClass: string }) {
  const { t } = useTranslation();
  const { cx, classes } = useStyles();

  return (
    <Button
      className={cx(classes.button, buttonClass)}
      component="a"
      href={process.env.REACT_APP_INSTALL_TO_SLACK_URI}
      leftIcon={<SlackIcon className={classes.slackIcon} />}
    >
      {t('InstallToSlack')}
    </Button>
  );
}
