import React from 'react';
import { Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import useGetSettingsUsersCount from '../../queries/useGetSettingsUsersCount';

interface Props {
  channelId: string | null;
}

export default function UsersCountInfo({ channelId }: Props) {
  const { t } = useTranslation();

  const usersCount = useGetSettingsUsersCount(channelId);

  if (usersCount.data?.code !== 'success') {
    return null;
  }

  return (
    <Text color="dimmed" size={12}>
      {t(`ScheduleSettingsForm.UsersCountInfo.${channelId ? 'Channel' : 'Workspace'}`, {
        count: usersCount.data.value.count,
      })}
    </Text>
  );
}
