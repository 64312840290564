import { useQuery } from 'react-query';

import axios from '../axios';
import { IApiResponse, IPaymentInfo } from '../types';

const useGetPaymentInfo = (enabled: boolean) =>
  useQuery<IPaymentInfo, Error>(
    'payment info',
    async () => {
      const response = await axios.get<unknown, IApiResponse<IPaymentInfo>>('/gateway/tilly/payment/info');
      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      retry: 5,
      enabled,
    },
  );

export default useGetPaymentInfo;
