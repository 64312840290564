import { useQuery } from 'react-query';

import { IPollInfo } from '../types/slicesAndInfo';
import { IFilter } from '../types/filter';
import axios from '../../axios';
import { IGroupOverviewResponse } from '../types/overview';
import { GroupBy } from '../types/enums';
import { IApiResponse } from '../../types';

const useGetGroupOverview = (poll: IPollInfo, group_by: GroupBy, filter: IFilter) =>
  useQuery<IGroupOverviewResponse, Error>(
    [`/stats/polls/${poll.id}/overview/${group_by}`, filter],
    async () => {
      const response = await axios.get<unknown, IApiResponse<IGroupOverviewResponse>>(
        `/gateway/tilly/stats/polls/${poll.id}/overview/${group_by}`,
        {
          params: { filter },
        },
      );

      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetGroupOverview;
