import React, { useCallback, useMemo, useState } from 'react';
import { Button, Group, LoadingOverlay, Modal, Select, Text } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';

import { ISettingsDepartment, ISettingsRole } from '../../types';
import CustomLoader from '../CustomLoader/CustomLoader';

import useStyles from './SliceSettingsMoveModal.styles';
import { AdditionalSlice } from './SlicesSettings.types';

interface PropsBase<TSlice extends ISettingsDepartment | ISettingsRole> {
  slice: AdditionalSlice;
  movingSlice: TSlice;
  slices: TSlice[];
  close: () => void;
  submit: (overrides: Partial<TSlice> | undefined) => void;
  isLoading: boolean;
  error: string | undefined;
}

interface DepartmentsProps extends PropsBase<ISettingsDepartment> {
  slice: AdditionalSlice.Departments;
}

interface RolesProps extends PropsBase<ISettingsRole> {
  slice: AdditionalSlice.Roles;
}

type Props = DepartmentsProps | RolesProps;

export default function SliceSettingsMoveModal({ movingSlice, slices, close, submit, isLoading, error }: Props) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [targetSliceId, setTargetSliceId] = useState(movingSlice?.parentId ? movingSlice.parentId.toString() : null);

  const targetSlices = useMemo(() => {
    if (!movingSlice) {
      return [];
    }

    return slices.filter((x) => x.id !== movingSlice.id && !x.parentId);
  }, [movingSlice, slices]);

  const onClose = useCallback(() => {
    if (isLoading) {
      return;
    }

    close();
  }, [isLoading, close]);

  const onSubmit = useCallback(() => {
    if (isLoading) {
      return;
    }

    const targetSlice = targetSlices.find((x) => x.id.toString() === targetSliceId);

    submit({ parentId: targetSlice?.id });
  }, [isLoading, submit, targetSliceId, targetSlices]);

  const selectData = useMemo(
    () => targetSlices.map((x) => ({ value: x.id.toString(), label: x.name })),
    [targetSlices],
  );

  const selectClasses = useMemo(() => ({ item: classes.selectItem }), [classes]);
  const textParams = useMemo(() => ({ slice: movingSlice.name }), [movingSlice]);

  return (
    <Modal opened onClose={onClose} withCloseButton={false} centered>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<CustomLoader />} />

      <Text weight="bold" mb="sm">
        {t('SlicesSettings.MoveModalTitle')}
      </Text>
      <Text mb="sm">
        {/* eslint-disable-next-line */}
        <Trans i18nKey="SlicesSettings.MoveModalText" values={textParams} />
      </Text>
      <Select
        data={selectData}
        value={targetSliceId}
        onChange={setTargetSliceId}
        clearable
        label={t('SlicesSettings.MoveModalSelectLabel')}
        mb="sm"
        classNames={selectClasses}
      />
      <Group position="center" grow>
        <Button variant="outline" color="gray" onClick={onClose}>
          {t('SlicesSettings.MoveModalCancelButton')}
        </Button>
        <Button variant="filled" color="violet" onClick={onSubmit}>
          {t('SlicesSettings.MoveModalSubmitButton')}
        </Button>
      </Group>
      {!!error && (
        <Text color="red" align="center" mt="sm">
          {error}
        </Text>
      )}
    </Modal>
  );
}
