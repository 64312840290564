import React from 'react';
import { Box } from '@mantine/core';

import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import useGetOverallOverview from '../../statistics/queries/useGetOverallOverview';
import useGetGroupOverview from '../../statistics/queries/useGetGroupOverview';
import useGetPollsRecent from '../../statistics/queries/useGetPollsRecent';
import useGetPollSlices from '../../statistics/queries/useGetPollSlices';
import { useFilter } from '../../globalStates/Filter';
import { useGroupBy } from '../../globalStates/GroupBy';
import { ELEMENT_ID } from '../../constants';

import useStyles from './OverviewTable.style';
import { getTableData, normalizeOverallOverview } from './Overview.helpers';
import OverviewTable from './OverviewTable';

export default function Overview({ poll }: { poll: IPollInfo }) {
  const { classes } = useStyles({
    withDiffs: false,
    withHierarchy: false,
    mobile: false,
  });

  const [groupBy] = useGroupBy();
  const [filter] = useFilter();

  const isFiltered =
    !!filter.departments.length || !!filter.locations.length || !!filter.roles.length || !!filter.tenures.length;

  const recentPolls = useGetPollsRecent();
  const groupOverview = useGetGroupOverview(poll, groupBy, filter);
  const filteredOverview = useGetOverallOverview(poll, filter, isFiltered);
  const overallOverview = useGetOverallOverview(poll, { departments: [], locations: [], roles: [], tenures: [] });
  const slices = useGetPollSlices(poll.id);

  if (recentPolls.isLoading || filteredOverview.isLoading || overallOverview.isLoading) {
    return <Box className={classes.container} />;
  }

  if (!recentPolls.isSuccess || !filteredOverview.isSuccess || !overallOverview.isSuccess) {
    throw (
      recentPolls.error ??
      filteredOverview.error ??
      groupOverview.error ??
      overallOverview.error ??
      slices.error ??
      new Error('Unknown error')
    );
  }

  const showDiff = recentPolls.data.polls.length > 1;

  const filtered = normalizeOverallOverview(filteredOverview.data);
  const overall = normalizeOverallOverview(overallOverview.data);
  const data = slices.data && groupOverview.data ? getTableData(slices.data, groupOverview.data, groupBy) : [];

  return (
    <div id={ELEMENT_ID.OVERVIEW_TABLE}>
      <OverviewTable filtered={filtered} isFiltered={isFiltered} rows={data} showDiff={showDiff} overall={overall} />
    </div>
  );
}
