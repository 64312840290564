import { createStyles, MantineTheme } from '@mantine/core';

type TableOptions = {
  withDiffs: boolean;
  withHierarchy: boolean;
  mobile: boolean;
};

const useStyles = createStyles((theme: MantineTheme, { withDiffs, withHierarchy, mobile }: TableOptions) => {
  /* eslint-disable no-nested-ternary */
  const hierarchyHeaderSize = mobile ? '0px' : '15px';
  const nameHeaderSize = '165px';
  const comparisonHeaderSize = mobile ? '' : '35px';
  const pplHeaderSize = mobile ? '55px' : withHierarchy ? '42px' : '30px';
  const valueHeaderSize = mobile ? '65px' : withDiffs ? '72px' : '85px';
  const diffHeaderSize = withDiffs && !mobile ? '25px' : '';
  const paddingLeftHeader = mobile ? '0px' : withHierarchy ? '4px' : '0px';

  const hierarchyCellSize = mobile ? '35px' : withHierarchy ? '45px' : '25px';
  const nameCellSize = mobile ? '125px' : '125px';
  const comparisonCellSize = mobile ? '' : '58px';
  const pplCellSize = mobile ? '40px' : '30px';
  const valueCellSize = mobile ? '62px' : withDiffs ? '70px' : '82px';
  const diffCellSize = withDiffs && !mobile ? '30px' : '';
  const paddingLeftCell = mobile ? '0px' : withHierarchy ? '4px' : '10px';
  /* eslint-enable no-nested-ternary */

  return {
    container: {
      backgroundColor: theme.white,
      height: '100%',
      width: '100%',
    },
    headerGrid: {
      gridTemplateColumns: `${hierarchyHeaderSize} ${nameHeaderSize} ${comparisonHeaderSize} ${pplHeaderSize} ${valueHeaderSize} ${diffHeaderSize} ${valueHeaderSize} ${diffHeaderSize}`,
      alignItems: 'center',
      position: 'sticky',
      paddingLeft: paddingLeftHeader,
    },
    grid: {
      gridTemplateColumns: `${hierarchyCellSize} ${nameCellSize} ${comparisonCellSize} ${pplCellSize} ${valueCellSize} ${diffCellSize} ${valueCellSize} ${diffCellSize}`,
      alignItems: 'center',
      position: 'sticky',
      paddingLeft: paddingLeftCell,
    },
    predictor: {
      position: 'absolute',
      top: 0,
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        left: 225,
      },
      /* eslint-disable no-nested-ternary */
      right: withDiffs && !mobile ? (withHierarchy ? 12 : 14) : withHierarchy ? 15 : 28,
      width: withDiffs && !mobile ? '155px' : mobile ? '110px' : '120px',
      /* eslint-enable no-nested-ternary */
      textAlign: 'center',
      '&::before': {
        position: 'absolute',
        content: '""',
        left: 0,
        right: 0,
        bottom: -10,
        height: '8px',
        border: `1px solid ${theme.colors['light-gray'][5]}`,
        borderBottom: 'none',
      },
    },
    headerWrapper: {
      position: 'sticky',
      top: 0,
      paddingTop: theme.spacing.xs,
      background: theme.white,
      zIndex: 3,
      padding: '0',
    },
    footerWrapper: {
      position: 'sticky',
      bottom: 0,
      paddingTop: theme.spacing.xs,
      background: theme.white,
      zIndex: 3,
      padding: '0',
    },
    withoutMargin: {
      '> div': {
        margin: '0',
      },
    },
    sortingFeaturesOverlay: {
      position: 'absolute',
      right: 10,
      width: 240,
      top: 20,
      height: 30,
    },
  };
});

export default useStyles;
