import React from 'react';
import { Button, Grid, Group, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconCircleCheckFilled } from '@tabler/icons-react';

import { Plan } from '../../../statistics/types/enums';

import useStyles from './PaymentInfoCard.style';

interface PropsBase {
  plan: Plan;
  totalPrice: string;
  tooltip?: string | null;
}

interface ProProps extends PropsBase {
  plan: Plan.Pro;
  paymentUri: string | null;
}

interface FreeProps extends PropsBase {
  plan: Plan.Free;
}

type Props = ProProps | FreeProps;

function PaymentInfoCard(props: Props) {
  const { plan, totalPrice, tooltip } = props;
  const { cx, classes } = useStyles();
  const { t } = useTranslation();

  const points: [{ order: number; text: string }] = t(`PaymentInfoCard.${plan}Plan.Texts`, { returnObjects: true });

  function getButton() {
    if (plan === Plan.Free) {
      return <Button className={cx(classes.button, classes.freeButton)}>{t('PaymentInfoCard.FreePlan.Button')}</Button>;
    }

    const { paymentUri } = props;

    if (!paymentUri) {
      return <Button className={cx(classes.button, classes.proButton)}>{t('PaymentInfoCard.ProPlan.Button')}</Button>;
    }

    return (
      <Button component="a" href={paymentUri} className={cx(classes.button, classes.proButton)}>
        {t('PaymentInfoCard.ProPlan.Button')}
      </Button>
    );
  }

  function getPriceHeaderCell() {
    return (
      <div className={cx(classes.price, classes.headerCell)}>
        <div className={classes.currencySign}>{t('PaymentInfoCard.CurrencySign')}</div>
        <div className={classes.totalPrice}>{totalPrice}</div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Group position="center">
        {tooltip ? <Tooltip label={tooltip}>{getPriceHeaderCell()}</Tooltip> : getPriceHeaderCell()}
        <div className={classes.headerCell}>{plan === Plan.Free ? null : t('PaymentInfoCard.PriceTitle')}</div>
      </Group>
      <div className={classes.planName}>{t(`PaymentInfoCard.${plan}Plan.Header`)}</div>
      <Grid columns={12}>
        {points
          .sort((x, y) => x.order - y.order)
          .map((p: { order: number; text: string }) => (
            <>
              <Grid.Col span={2}>
                <IconCircleCheckFilled />
              </Grid.Col>
              <Grid.Col span={10}>{p.text}</Grid.Col>
            </>
          ))}
      </Grid>
      <div className={classes.buttonWrapper}>
        {tooltip ? <Tooltip label={tooltip}>{getButton()}</Tooltip> : getButton()}
      </div>
    </div>
  );
}

export default PaymentInfoCard;
