import { useMutation } from 'react-query';

import axios from '../axios';
import { IAccessToken } from '../types';

const useGetAccessTokenMutation = () =>
  useMutation<IAccessToken, Error>('access-token', () => axios.get(`/gateway/tilly/access-token`), {
    retry: false,
  });

export default useGetAccessTokenMutation;
