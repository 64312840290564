import React from 'react';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { UnstyledButton } from '@mantine/core';

import useStyles from './HierarchicalButton.style';

const onClickFunc = (onClick: () => void) => (e: React.MouseEvent) => {
  onClick();
  e.stopPropagation();
};

export default function HierarchicalButton({ onClick, isExpanded }: { onClick: () => void; isExpanded?: boolean }) {
  const { classes } = useStyles();

  return (
    <UnstyledButton className={classes.button} onClick={onClickFunc(onClick)}>
      {isExpanded ? <IconMinus className={classes.icon} /> : <IconPlus className={classes.icon} />}
    </UnstyledButton>
  );
}
