import { useQuery } from 'react-query';

import { IWorkspaceInfo } from '../types/slicesAndInfo';
import axios from '../../axios';

const useGetWorkspaceInfo = () =>
  useQuery<IWorkspaceInfo, Error>(['/stats/workspace'], () => axios.get(`/gateway/tilly/stats/workspace`), {
    staleTime: Infinity,
    retry: true,
  });

export default useGetWorkspaceInfo;
