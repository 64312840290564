import React, { useCallback, useMemo } from 'react';
import { Center, Modal, SimpleGrid } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import useGetPaymentInfo from '../../queries/useGetPaymentInfo';
import useGetWorkspaceInfo from '../../statistics/queries/useGetWorkspaceInfo';
import { Plan } from '../../statistics/types/enums';
import CustomLoader from '../CustomLoader/CustomLoader';
import { usePaymentInfoModal } from '../../globalStates/PaymentInfoModal';
import { useEncryptedParam } from '../../globalStates/EncryptedParam';

import useStyles from './PaymentInfoModal.style';
import PaymentInfoCard from './PaymentInfoCard/PaymentInfoCard';

function PaymentInfoModal() {
  const { t } = useTranslation();
  const { classes, theme } = useStyles();
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const [encryptedParam] = useEncryptedParam();
  const [opened, setOpened] = usePaymentInfoModal();

  const shared = !!encryptedParam;

  const workspaceInfo = useGetWorkspaceInfo();

  const paymentInfoEnabled = !workspaceInfo.data!.uninstalled && !shared;

  const paymentInfo = useGetPaymentInfo(paymentInfoEnabled);
  const paymentInfoLoaded = paymentInfoEnabled && paymentInfo.isSuccess;

  const modalClasses = useMemo(
    () => ({
      modal: classes.modal,
      header: classes.header,
      title: classes.title,
      close: classes.close,
    }),
    [classes],
  );

  const close = useCallback(() => setOpened(false), [setOpened]);

  function tryGetWarning() {
    if (paymentInfo.isError) {
      return t('PaymentInfoModal.Error');
    }

    if (shared) {
      return t('PaymentInfoModal.Shared');
    }

    if (workspaceInfo.data?.uninstalled) {
      return t('PaymentInfoModal.Uninstalled');
    }

    return null;
  }

  function tryGetTooltip() {
    if (paymentInfo.isError) {
      return t('PaymentInfoModal.Error.Tooltip');
    }

    if (shared) {
      return t('PaymentInfoModal.Shared.Tooltip');
    }

    if (workspaceInfo.data?.uninstalled) {
      return t('PaymentInfoModal.Uninstalled.Tooltip');
    }

    return null;
  }

  function getTitle() {
    if (shared) {
      return t('PaymentInfoModal.Shared.Header');
    }

    if (workspaceInfo.data?.uninstalled) {
      return t('PaymentInfoModal.Uninstalled.Header');
    }

    return t('PaymentInfoModal.Header');
  }

  const tooltip = tryGetTooltip();
  const title = getTitle();
  const warning = tryGetWarning();

  return (
    <Modal classNames={modalClasses} opened={opened} onClose={close} title={title} centered size="xl">
      {warning && <Center className={classes.warning}>{warning}</Center>}
      {paymentInfo.isLoading ? (
        <CustomLoader />
      ) : (
        <SimpleGrid cols={middleScreen ? 1 : 2} spacing="xl">
          <PaymentInfoCard plan={Plan.Free} totalPrice="0" />
          <PaymentInfoCard
            plan={Plan.Pro}
            paymentUri={paymentInfo.data?.paymentUri ?? null}
            totalPrice={paymentInfoLoaded ? paymentInfo.data!.totalPrice.toString() : t('PaymentInfoCard.UnknownPrice')}
            tooltip={tooltip}
          />
        </SimpleGrid>
      )}
    </Modal>
  );
}

export default PaymentInfoModal;
