import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  selectItem: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    '&[data-hovered], :hover': {
      color: theme.colors.gray[9],
      backgroundColor: theme.colors.gray[2],
    },
    '&[data-selected]': {
      color: theme.colors.gray[9],
      backgroundColor: theme.colors.gray[4],
    },
  },
  selectInput: {
    fontSize: theme.fontSizes.sm,
  },
  SelectRightSection: {
    pointerEvents: 'none',
  },
}));

export default useStyles;
