import React from 'react';
import { Popover, Text, Notification, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import useLogoutMutation from '../../queries/useLogoutMutation';

import useStyles from './LogoutPopover.style';

interface Props {
  logoutText: string;
  buttonClass?: string;
  underlineText?: boolean;
}

export default function LogoutPopover({ logoutText, buttonClass, underlineText = true }: Props) {
  const { cx, classes, theme } = useStyles(!!underlineText);

  const { t } = useTranslation();

  const logoutMutation = useLogoutMutation();

  const [logoutErrorNotification, setLogoutErrorNotification] = React.useState(false);

  React.useEffect(() => {
    setLogoutErrorNotification(logoutMutation.isError);
  }, [logoutMutation.isError]);

  const closeErrorNotification = React.useCallback(() => {
    setLogoutErrorNotification(false);
  }, []);

  const logoutHandler = React.useCallback(() => {
    logoutMutation.mutate();
  }, [logoutMutation]);

  return (
    <Popover opened={logoutErrorNotification} zIndex={302}>
      <Popover.Target>
        <Button variant="white" className={cx(classes.logout, buttonClass)} onClick={logoutHandler}>
          <Text weight="normal" color={theme.black} size={16}>
            {t(logoutText)}
          </Text>
        </Button>
      </Popover.Target>
      <Popover.Dropdown className={classes.logoutErrorPopover}>
        <Notification onClose={closeErrorNotification} className={classes.logoutError}>
          {t('LogoutButton.Error')}
        </Notification>
      </Popover.Dropdown>
    </Popover>
  );
}
