import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  hierarchyButton: {
    border: `1px solid ${theme.colors['light-gray'][5]}`,
    borderRadius: '5px',
    padding: 0,
    background: 'white',
    boxShadow: 'none',
    display: 'inline-flex',
    zIndex: 1,
    width: '17px',
    height: '17px',
  },
  hierarchyButtonIcon: {
    height: '15px',
    width: '15px',
  },
  hierarchyCellContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainSlice: {
    fontWeight: 'bold',
  },
  firstOfHierarchy: {
    '&::before': {
      top: '50%',
    },
    '&::after': {
      display: 'none',
    },
  },
  hierarchy: {
    '&::before': {
      position: 'absolute',
      content: '""',
      width: '1px',
      backgroundColor: theme.colors['light-gray'][5],
      left: '50%',
      top: '0px',
      bottom: '0px',
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      height: '1px',
      top: '50%',
      left: '50%',
      right: '0px',
      backgroundColor: theme.colors['light-gray'][5],
    },
  },
  lastOfHierarchy: {
    '&::before': {
      bottom: '50%',
    },
  },
}));

export default useStyles;
