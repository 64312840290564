import React from 'react';
import { useQueryParams, StringParam, BooleanParam, withDefault } from 'use-query-params';

import { IComparison } from '../statistics/types/comparison';
import { GroupBy, Tenure } from '../statistics/types/enums';

import State from './State';

const Context = React.createContext<State<IComparison>>([
  { comparison: [], showFiltered: false, showOverall: true },
  () => {},
]);

export const useComparison = () => React.useContext(Context);

export default function ComparisonProvider({ children }: React.PropsWithChildren) {
  const [{ comparison, showFiltered, showOverall, groupBy }, setQuery] = useQueryParams({
    comparison: StringParam,
    showFiltered: withDefault(BooleanParam, true),
    showOverall: withDefault(BooleanParam, false),
    groupBy: StringParam,
  });

  const state = React.useMemo<State<IComparison>>(() => {
    const update = (newValue: IComparison) => {
      setQuery({
        comparison: newValue.comparison.length ? newValue.comparison.join(',') : null,
        showFiltered: newValue.showFiltered,
        showOverall: newValue.showOverall,
      });
    };

    if (groupBy === GroupBy.Tenures) {
      const value = {
        comparison: comparison
          ? comparison
              .split(',')
              .filter((s) => s !== '')
              .map((i) => i as Tenure)
          : [],
        showOverall,
        showFiltered,
      };

      return [value, update];
    }

    const value = {
      comparison: comparison
        ? comparison
            .split(',')
            .filter((s) => s !== '')
            .map((i) => Number(i))
        : [],
      showOverall,
      showFiltered,
    };

    return [value, update];
  }, [comparison, showFiltered, showOverall, groupBy, setQuery]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
