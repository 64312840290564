import React from 'react';
import { Title } from '@mantine/core';
import jwt_decode from 'jwt-decode';
import { Navigate } from 'react-router-dom';

import { IJwtToken } from '../../types';
import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import useGetMe from '../../queries/useGetMe';

import useStyles from './CompanyInfo.style';

export default function CompanyInfo(props: { encryptedParam: string | null }) {
  const { encryptedParam } = props;
  const { data: user, isLoading: isUserLoading } = useGetMe();
  const { classes } = useStyles();
  let workspaceName;

  if (encryptedParam) {
    try {
      workspaceName = jwt_decode<IJwtToken>(encryptedParam)['https://slack.com/team_name'];
    } catch {
      return <Navigate to="/notFound" replace />;
    }
  }

  if (isUserLoading) {
    return <CustomSkeleton className={classes.loaderContainer} />;
  }

  return (
    <Title order={2} className={classes.title}>
      {encryptedParam ? workspaceName : user?.['https://slack.com/team_name']}
    </Title>
  );
}
