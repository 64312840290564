import React from 'react';
import { Flex, Group, Image, Text, useMantineTheme, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import img from '../../images/logo.svg';
import isDemo from '../../helpers/isDemo';

import useStyles from './Footer.style';

interface Props {
  firstRow?: React.ReactNode;
}

function Footer({ firstRow }: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  return (
    <Stack className={classes.container} spacing="xl">
      {!!firstRow && <Flex className={classes.floor}>{firstRow}</Flex>}
      <Group position="apart" className={classes.floor}>
        <a href="https://teal.inc">
          <Image src={img} alt="logo" width="85px" withPlaceholder />
        </a>
        <Group className={classes.linksGroup}>
          {!middleScreen && <Text className={classes.caption}>{`${t('Footer.AboutTeal')}:`}</Text>}
          <a href={`${t('Footer.WhitepaperLink')}`} className={classes.link}>
            {t('Footer.WhitepaperTitle')}
          </a>
          <a href={`${t('Footer.CasesLink')}`} className={classes.link}>
            {t('Footer.CasesTitle')}
          </a>
          {!isDemo() && (
            <a href={`${t('Footer.DemoLink')}`} className={classes.link}>
              {t('Footer.DemoTitle')}
            </a>
          )}
        </Group>
        <Group className={classes.linksGroup}>
          <a href={`${t('Footer.PrivacyPolicyLink')}`} className={classes.link}>
            {t('Footer.PrivacyPolicyTitle')}
          </a>
          <a href={`${t('Footer.TermsOfUseLink')}`} className={classes.link}>
            {t('Footer.TermsOfUseTitle')}
          </a>
        </Group>
        <a href={`${t('Footer.ContactUsLink')}`} className={classes.link}>
          {t('Footer.ContactUsTitle')}
        </a>
      </Group>
    </Stack>
  );
}

export default Footer;
