import { useMutation } from 'react-query';

import axios from '../axios';
import { IApiResponse, IPollDto } from '../types';

import queryClient from './queryClient';

const useDeletePollMutation = (onSuccessDelete?: (pollDto: IPollDto) => void, onError?: (error: Error) => void) =>
  useMutation<IPollDto, Error, number, unknown>(
    async (pollId: number) => {
      const response = await axios.delete<unknown, IApiResponse<IPollDto>>(`/gateway/tilly/settings/polls/${pollId}`);
      if (!response || response.code !== 'success' || !response.value) {
        if (response.code === 'entity_not_found') {
          throw new Error("That poll wasn't found try to update page to refresh data.");
        }

        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (data) => {
        if (onSuccessDelete) {
          onSuccessDelete(data);
        }
        queryClient.refetchQueries({ queryKey: 'settings/polls' });
        queryClient.refetchQueries({ queryKey: 'settings/active-poll' });
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }
        queryClient.refetchQueries({ queryKey: 'settings/polls' });
        queryClient.refetchQueries({ queryKey: 'settings/active-poll' });
      },
    },
  );

export default useDeletePollMutation;
