import React from 'react';
import { ActionIcon, Box, Grid, MantineColor, Text } from '@mantine/core';
import { DatePicker, DayModifiers, TimeInput } from '@mantine/dates';
import { IconX } from '@tabler/icons-react';

import useStyles from './styles';
import TimeZoneIcon from './TimeZoneIcon';

interface Props {
  label?: React.ReactNode;
  description?: React.ReactNode;
  datePickerPlaceholder?: string;
  withinPortal?: boolean;
  color?: MantineColor;
  clearable?: boolean;
  value?: Date | null;
  onChange?: (value: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  error?: React.ReactNode;
}

export default function DateTimePicker({
  label,
  description,
  datePickerPlaceholder,
  withinPortal,
  color = 'blue',
  clearable = false,
  value: controlledValue,
  onChange: controlledOnChange,
  minDate,
  maxDate,
  error,
}: Props) {
  const { classes, cx } = useStyles({ color });
  const getDayClassName = React.useCallback(
    (_date: Date, modifiers: DayModifiers): string =>
      cx({
        [classes.weekend]: modifiers.weekend,
      }),
    [classes, cx],
  );

  const [internalValue, setInternalValue] = React.useState<Date | null>(null);

  const value = React.useMemo(() => controlledValue ?? internalValue, [controlledValue, internalValue]);
  const setValue = React.useMemo(() => controlledOnChange ?? setInternalValue, [controlledOnChange]);

  const setDate = React.useCallback(
    (date: Date) => {
      if (value === null) {
        setValue(new Date(`${date.toDateString()} ${new Date().toTimeString()}`));
      } else {
        setValue(new Date(`${date.toDateString()} ${value.toTimeString()}`));
      }
    },
    [value, setValue],
  );

  const setTime = React.useCallback(
    (time: Date) => {
      if (value === null) {
        setValue(new Date(`${new Date().toDateString()} ${time.toTimeString()}`));
      } else {
        setValue(new Date(`${value.toDateString()} ${time.toTimeString()}`));
      }
    },
    [value, setValue],
  );

  const clear = React.useCallback(() => {
    setValue(null);
  }, [setValue]);

  return (
    <Box>
      {label && (
        <Text size={14} weight={500} mb="xs">
          {label}
        </Text>
      )}
      {description && (
        <Text color="dimmed" size={12} mb={5}>
          {description}
        </Text>
      )}
      <Grid align="center">
        <Grid.Col span="auto">
          <DatePicker
            placeholder={datePickerPlaceholder}
            withinPortal={withinPortal}
            clearable={false}
            // eslint-disable-next-line
            classNames={{
              yearPickerControlActive: classes.active,
              monthPickerControlActive: classes.active,
              day: classes.day,
            }}
            dayClassName={getDayClassName}
            value={value}
            onChange={setDate}
            minDate={minDate}
            maxDate={maxDate}
            error={!!error}
          />
        </Grid.Col>
        <Grid.Col span="auto">
          <TimeInput
            clearable={false}
            value={value}
            onChange={setTime}
            error={!!error}
            rightSection={value ? <TimeZoneIcon color={color} offsetMinutes={value.getTimezoneOffset()} /> : undefined}
          />
        </Grid.Col>
        {clearable && (
          <Grid.Col span="content" p={0}>
            <ActionIcon onClick={clear} radius={4}>
              <IconX size={20} color={error ? 'red' : undefined} />
            </ActionIcon>
          </Grid.Col>
        )}
      </Grid>
      {error && (
        <Text color="red" size={12} mt={3}>
          {error}
        </Text>
      )}
    </Box>
  );
}
