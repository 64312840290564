import React, { useCallback } from 'react';
import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { ISettingsSlice } from '../../types';

import useStyles from './SliceSettingsAddNewButton.styles';

interface Props {
  handler: (defaults: Omit<ISettingsSlice, 'id'>) => void;
}

export default function SliceSettingsAddNewButton({ handler }: Props) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const onClick = useCallback(() => handler({ name: '' }), [handler]);

  return (
    <Button
      leftIcon={<IconPlus size={20} />}
      variant="white"
      color="violet"
      h={30}
      onClick={onClick}
      classNames={classes}
    >
      {t('SlicesSettingsDataTable.Add')}
    </Button>
  );
}
