import React from 'react';

import { GroupBy } from '../statistics/types/enums';

interface ContextType {
  opened: GroupBy | null;
  setOpened: (x: GroupBy | null) => void;
  fixed: boolean;
  setFixed: (x: boolean) => void;
}

const defaultValue = {
  opened: null,
  setOpened: () => {},
  fixed: false,
  setFixed: () => {},
};

const Context = React.createContext<ContextType>(defaultValue);

export const OpenedFiltersContext: ContextType = defaultValue;

export const useOpenedFilters = () => React.useContext(Context);

export default function OpenedFiltersProvider({ children }: React.PropsWithChildren) {
  const [opened, setOpened] = React.useState<GroupBy | null>(null);
  const [fixed, setFixed] = React.useState<boolean>(false);

  OpenedFiltersContext.opened = opened;
  OpenedFiltersContext.setOpened = setOpened;
  OpenedFiltersContext.fixed = fixed;
  OpenedFiltersContext.setFixed = setFixed;

  const contextValue = React.useMemo<ContextType>(
    () => ({ opened, setOpened, fixed, setFixed }),
    [opened, setOpened, fixed, setFixed],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
