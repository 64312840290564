import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  wrapper: {
    marginTop: '26px',
    marginBottom: '26px',
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
  skeleton: {
    marginTop: '26px',
    marginBottom: '26px',
    width: '100%',
    height: '160px',
    display: 'flex',
    position: 'relative',
  },
  inputLabel: {
    width: '268px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  positiveColor: {
    color: '#47944B',
  },
  negativeColor: {
    color: '#C5322A',
  },
}));

export default useStyles;
