import { useMutation } from 'react-query';

import { IApiResponse, IDeleteSliceResponse, ISettingsDepartment, ISlicesSettingsDto } from '../types';
import axios from '../axios';

import queryClient from './queryClient';

const useDeleteSettingsDepartmentMutation = () =>
  useMutation<IDeleteSliceResponse, Error, ISettingsDepartment, unknown>(
    async (department: ISettingsDepartment) => {
      const response = await axios.delete<unknown, IApiResponse<IDeleteSliceResponse>>(
        `/gateway/tilly/settings/departments/${department.id}`,
      );
      if (!response || response.code !== 'success' || !response.value) {
        if (response.code === 'entity_not_found') {
          throw new Error("That department wasn't found. Try to update page to refresh data.");
        }

        if (response.code === 'active_poll') {
          throw new Error('You cannot delete departments while the poll is in progress.');
        }

        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (response, department) => {
        const departments = queryClient.getQueryData<ISettingsDepartment[]>('settings/departments');
        if (departments) {
          queryClient.setQueryData<ISettingsDepartment[]>(
            'settings/departments',
            departments.filter((x) => x.id !== department.id),
          );
        }

        const slicesSettings = queryClient.getQueryData<ISlicesSettingsDto>('settings/slices');
        if (slicesSettings) {
          queryClient.setQueryData<ISlicesSettingsDto>('settings/slices', {
            ...slicesSettings,
            departmentsEnabled: response.sliceEnabled,
          });
        }
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: ['settings/departments'] });
      },
    },
  );

export default useDeleteSettingsDepartmentMutation;
