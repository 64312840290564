import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  root: {
    paddingLeft: 1,
    paddingRight: 20,
  },
  leftIcon: {
    marginRight: 1,
  },
}));

export default useStyles;
