import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Box } from '@mantine/core';
import dayjs from 'dayjs';

import DateTimePicker from '../DateTimePicker';

interface Props {
  date: Date | null;
  setDate: (x: Date | null) => void;
  error: string | null;
}

export default function NextDateInput({ date, setDate, error }: Props) {
  const { t } = useTranslation();

  const onChange = React.useCallback(
    (value: Date | null) => {
      const now = dayjs();
      if (value !== null && dayjs(value).subtract(10, 'minutes').isBefore(now)) {
        setDate(now.add(10, 'minutes').add(30, 'seconds').toDate());
        return;
      }

      setDate(value);
    },
    [setDate],
  );

  return (
    <Box>
      <DateTimePicker
        label={t<string>('ScheduleSettingsForm.NextDateInput.Label')}
        description={t<string>('ScheduleSettingsForm.NextDateInput.Description')}
        datePickerPlaceholder={t<string>('ScheduleSettingsForm.NextDateInput.Placeholder')}
        withinPortal
        color="violet"
        clearable
        value={date}
        onChange={onChange}
        minDate={new Date()}
        error={error}
      />
      <Text color="dimmed" size={12} mt="sm">
        {t('ScheduleSettingsForm.NextDateInput.Hint')}
      </Text>
    </Box>
  );
}
