import React from 'react';
import { Group, Flex, Image, Box } from '@mantine/core';

import img from '../../images/logo.svg';
import CompanyInfo from '../CompanyInfo/CompanyInfo';
import { useEncryptedParam } from '../../globalStates/EncryptedParam';

import useStyles from './Header.style';

/* eslint-disable react/require-default-props */
interface Props {
  firstRow?: React.ReactNode;
  secondRow?: React.ReactNode;
}

function Header({ firstRow, secondRow }: Props) {
  const [encryptedParam] = useEncryptedParam();
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Group position="apart" className={classes.topFloor}>
        <a href="https://teal.inc">
          <Image src={img} w={110} alt="logo" withPlaceholder />
        </a>
        {!!firstRow && firstRow}
      </Group>
      <Flex className={classes.bottomFloor}>
        <CompanyInfo encryptedParam={encryptedParam} />
        {!!secondRow && secondRow}
      </Flex>
    </Box>
  );
}

export default Header;
