// eslint-disable-next-line
export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  SUCCESS_INSTALL: '/success-install',
  SETTINGS: '/settings',
  UPGRADE: '/upgrade',
  UPGRADE_CANCELLED: '/upgrade-cancel',
  UPGRADE_SUCCEEDED: '/upgrade-success',
};

export const ELEMENT_ID = {
  MAIN_PREDICTORS: 'main-predictors',
  OVERVIEW_TABLE: 'overview-table',
  PREDICTORS: 'predictors',
  SHARE_LINK: 'share-link',
  SORTING_FEATURES: 'sorting-features',
  FILTERS: 'filters',
  FILTER_DROPDOWN: 'filter-dropdown',
  GROUP_BY_SELECT: 'group-by-select',
  GROUP_BY_DROPDOWN: 'group-by-dropdown',
};

export const ELEMENT_CLASS = {
  FILTER: 'filter',
  OVERVIEW_TABLE_ROW: 'overview-table-row',
  OVERVIEW_TABLE_COMPARE_BUTTON: 'overview-table-compare-button',
};
