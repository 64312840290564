import { useQuery } from 'react-query';

import { ISettingsDepartment } from '../types';
import axios from '../axios';

const useGetSettingsDepartments = (enabled = true) =>
  useQuery<ISettingsDepartment[], Error>(
    'settings/departments',
    () => axios.get<unknown, ISettingsDepartment[]>('/gateway/tilly/settings/departments'),
    {
      staleTime: Infinity,
      retry: true,
      enabled,
    },
  );

export default useGetSettingsDepartments;
