import React from 'react';
import { useQueryParams, BooleanParam, withDefault, createEnumParam } from 'use-query-params';

import { Predictor } from '../statistics/types/enums';

import State from './State';

export interface PredictorContextValue {
  predictor: Predictor | null;
  showPositive: boolean;
  showNegative: boolean;
}

export const defaultPredictor: PredictorContextValue = {
  predictor: null,
  showPositive: true,
  showNegative: true,
};

const Context = React.createContext<State<PredictorContextValue>>([defaultPredictor, () => {}]);

export const usePredictor = () => React.useContext(Context);

const PredictorParam = createEnumParam(Object.values(Predictor));

export default function PredictorProvider({ children }: React.PropsWithChildren) {
  const [{ predictor, negativePredictor: showNegative, positivePredictor: showPositive }, setQuery] = useQueryParams({
    predictor: withDefault(PredictorParam, defaultPredictor.predictor),
    negativePredictor: withDefault(BooleanParam, defaultPredictor.showNegative),
    positivePredictor: withDefault(BooleanParam, defaultPredictor.showPositive),
  });

  const state = React.useMemo<State<PredictorContextValue>>(() => {
    const value: PredictorContextValue = {
      predictor,
      showNegative,
      showPositive,
    };

    const update = (newValue: PredictorContextValue) => {
      setQuery({
        predictor: newValue.predictor,
        negativePredictor: newValue.showNegative,
        positivePredictor: newValue.showPositive,
      });
    };

    return [value, update];
  }, [predictor, showNegative, showPositive, setQuery]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
