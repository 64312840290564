import React, { useCallback, useState } from 'react';
import { Card, Text, Switch, Flex, ActionIcon, Alert, Loader, Divider, Stack, LoadingOverlay } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconTrash } from '@tabler/icons-react';

import { Poll } from './PollList.helpers';
import PollListModal from './PollListModal';
import useStyles from './PollList.styles';

type Props = {
  polls: Poll[];
  loading: boolean;
  onDelete?: (id: number) => void;
  error?: string;
  deletingPollId?: number;
};

export default function PollList({ polls, loading, onDelete, error, deletingPollId }: Props) {
  const [pollsListOpened, setpollsListOpened] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [pollToDelete, setPollToDelete] = useState<Poll | undefined>();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const onSwitchChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setpollsListOpened(e.currentTarget.checked),
    [],
  );

  const onDeleteIconClick = useCallback(
    (poll: Poll) => () => {
      setPollToDelete(poll);
      setModalOpened(true);
    },
    [],
  );

  const onCloseModal = useCallback(() => {
    setModalOpened(false);
    setPollToDelete(undefined);
  }, []);

  const onDeletePollInModalClick = useCallback(() => {
    setPollToDelete(undefined);
    if (pollToDelete && onDelete) onDelete(pollToDelete.id);
  }, [onDelete, pollToDelete]);

  return (
    <Card shadow="sm" p="lg">
      <PollListModal
        modalOpened={modalOpened}
        onCloseModal={onCloseModal}
        onDeleteButtonClick={onDeletePollInModalClick}
      />
      <Flex align="center" justify="space-between">
        <Text size="md">{t<string>('PollList.Label')}</Text>
        <Switch size="sm" checked={pollsListOpened} onChange={onSwitchChanged} color="violet" />
      </Flex>
      {pollsListOpened && (
        <>
          <Divider my="lg" />
          <div className={classes.loadingWrapper}>
            <LoadingOverlay
              visible={loading}
              overlayBlur={2}
              loader={<Loader size="md" variant="oval" color="violet" />}
            />

            {polls?.map((p) => (
              <Stack key={p.id} py="xs" px="sm" spacing="xs">
                <Flex align="center" justify="space-between">
                  <Text size="md">
                    {t<string>('PollList.SurveyText', {
                      respondersCount: p.respondersCount,
                      date: p.startedAt,
                    })}
                  </Text>
                  {deletingPollId === p.id ? (
                    <Loader size="sm" variant="dots" />
                  ) : (
                    <ActionIcon
                      color="dark"
                      size={24}
                      onClick={onDeleteIconClick(p)}
                      disabled={deletingPollId !== undefined}
                    >
                      <IconTrash width="24px" height="24px" />
                    </ActionIcon>
                  )}
                </Flex>
              </Stack>
            ))}
          </div>
        </>
      )}
      {error && (
        <Alert mt="sm" color="red">
          {error}
        </Alert>
      )}
    </Card>
  );
}
