import { useMutation } from 'react-query';

import { IApiResponse, ISettingsLocation } from '../types';
import axios from '../axios';

import queryClient from './queryClient';

const useCreateSettingsLocationMutation = () =>
  useMutation<ISettingsLocation, Error, Omit<ISettingsLocation, 'id'>, unknown>(
    async (request) => {
      const response = await axios.post<unknown, IApiResponse<ISettingsLocation>>(
        `/gateway/tilly/settings/locations`,
        request,
      );
      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (response) => {
        const locations = queryClient.getQueryData<ISettingsLocation[]>('settings/locations');
        if (locations) {
          queryClient.setQueryData<ISettingsLocation[]>('settings/locations', [...locations, response]);
        }
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: ['settings/locations'] });
      },
    },
  );

export default useCreateSettingsLocationMutation;
