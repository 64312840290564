import React from 'react';
import { Stack } from '@mantine/core';

import DeletingDataSettings from '../DeletingDataSettings/DeletingDataSettings';
import SettingsForm from '../SettingsForm/SettingsForm';
import SlicesSettings from '../SlicesSettings/SlicesSettings';
import useGetSettings from '../../queries/useGetSettings';
import useGetSettingsChannels from '../../queries/useGetSettingsChannels';
import useGetSettingsUsers from '../../queries/useGetSettingsUsers';
import CustomLoader from '../CustomLoader/CustomLoader';

import BotUninstalledSettingsBody from './SettingsBody.BotUninstalled';

export default function MainSettingsTab() {
  const users = useGetSettingsUsers();
  const channels = useGetSettingsChannels();
  const settings = useGetSettings();

  if (users.isLoading || settings.isLoading || channels.isLoading) {
    return <CustomLoader />;
  }

  if (!users.isSuccess || !settings.isSuccess || !channels.isSuccess) {
    throw users.error ?? settings.error ?? channels.error ?? new Error('Unknown error');
  }

  if (
    users.data.code === 'bot_uninstalled' ||
    channels.data.code === 'bot_uninstalled' ||
    settings.data.code === 'bot_uninstalled'
  ) {
    return <BotUninstalledSettingsBody />;
  }

  return (
    <Stack spacing="lg">
      <SettingsForm users={users.data.value} settings={settings.data.value} channels={channels.data.value} />
      <SlicesSettings />
      <DeletingDataSettings />
    </Stack>
  );
}
