import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  button: {
    textDecoration: 'underline',
    color: 'black',
    padding: 0,
  },
}));

export default useStyles;
