import { t } from 'i18next';
import Shepherd from 'shepherd.js';

import { ELEMENT_ID } from '../../../constants';
import createOverlay from '../../../helpers/createOverlay';
import { isVisible, onceHideHandlers } from '../helpers';
import { OpenedGroupByContext } from '../../../globalStates/OpenedGroupBy';
import waitForElement from '../../../helpers/waitForElement';
import { OVERLAY_INTERCEPTOR_CLASS } from '../constants';

export default function addGroupBySelectSteps(tour: Shepherd.Tour) {
  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.GroupBySelect.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.GroupBySelect.Text'),
    attachTo: {
      element: `#${ELEMENT_ID.GROUP_BY_SELECT}`,
      on: 'left',
    },
    modalOverlayOpeningPadding: 0,
    modalOverlayOpeningRadius: 8,
    beforeShowPromise() {
      const target = document.getElementById(ELEMENT_ID.GROUP_BY_SELECT);

      if (!target) {
        return Promise.resolve();
      }

      const removeOverlay = createOverlay([target], (x) => {
        Object.assign(x, {
          className: OVERLAY_INTERCEPTOR_CLASS,
        });
        x.addEventListener('click', () => {
          tour.next();
        });
      });

      onceHideHandlers.subscribe(() => removeOverlay());

      return Promise.resolve();
    },
  });

  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.GroupBySelectDropdown.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.GroupBySelectDropdown.Text'),
    attachTo: {
      element: '#group-by-overlay',
      on: 'left',
    },
    modalOverlayOpeningPadding: 0,
    modalOverlayOpeningRadius: 8,
    showOn() {
      return isVisible(`#${ELEMENT_ID.GROUP_BY_SELECT}`);
    },
    async beforeShowPromise() {
      OpenedGroupByContext.setOpened(true);
      OpenedGroupByContext.setFixed(true);

      onceHideHandlers.subscribe(() => {
        OpenedGroupByContext.setOpened(false);
        OpenedGroupByContext.setFixed(false);
      });

      const select = await waitForElement<HTMLElement>(`#${ELEMENT_ID.GROUP_BY_SELECT}`);
      const dropdown = await waitForElement<HTMLElement>(`#${ELEMENT_ID.GROUP_BY_DROPDOWN}`);

      const removeOverlay = createOverlay([select, dropdown], (x) => {
        Object.assign(x, {
          id: 'group-by-overlay',
        });
      });

      const removeSelectOverlay = createOverlay([select], (x) => {
        Object.assign(x, {
          className: OVERLAY_INTERCEPTOR_CLASS,
        });
        x.addEventListener('click', () => {
          tour.back();
        });
      });

      onceHideHandlers.subscribe(() => {
        removeOverlay();
        removeSelectOverlay();
      });
    },
  });
}
