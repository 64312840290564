import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import State from './State';

const Context = React.createContext<State<string | null>>([null, () => {}]);

export const useEncryptedParam = () => React.useContext(Context);

export default function EncryptedParamProvider({ children }: React.PropsWithChildren) {
  const [encryptedParam, setEncryptedParam] = useQueryParam('p', StringParam);

  const state = React.useMemo<State<string | null>>(() => {
    const value = encryptedParam ?? null;

    const update = (newValue: string | null) => {
      setEncryptedParam(newValue);
    };

    return [value, update];
  }, [encryptedParam, setEncryptedParam]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
