import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  errorContainer: {
    minHeight: 'calc(100vh - 62px)',
    fontSize: theme.fontSizes.xl,
  },
  oops: {
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0',
    textAlign: 'center',
    marginTop: '42px',
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
  svgImage: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      width: '200px',
      height: '80px',
    },
  },
  text: {
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: 0,
    textAlign: 'center',
    marginTop: '8px',
    marginBottom: '32px',
    color: '#18181866',
    maxWidth: '600px',
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: theme.fontSizes.md,
      lineHeight: '22px',
    },
  },
  logoutButton: {
    padding: '0px 12px',
    border: `1px solid ${theme.black}`,
    boxShadow: `0px 2px 0px ${theme.colors.black}, 0px 1px 0px ${theme.colors.black}`,
    borderRadius: '12px',
    backgroundColor: theme.colors.white[0],
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
