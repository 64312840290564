import axiosClient from 'axios';
import qs from 'qs';

const BASE_URL = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8080/api';
const searchParams = new URLSearchParams(window.location.search);
const encryptedParam = searchParams.get('p');

const axios = axiosClient.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
  paramsSerializer: {
    serialize: (p) => qs.stringify(p, { allowDots: true, arrayFormat: 'repeat' }),
  },
  params: encryptedParam ? { p: encryptedParam } : undefined,
});

axios.interceptors.response.use(
  (res) => res.data,
  (error) => {
    if (error.response && 'status' in error.response) {
      switch (error.response.status) {
        case 401: {
          if (encryptedParam && error.request.responseURL !== `${BASE_URL}/user/me?p=${encryptedParam}`) {
            window.location.replace(`${window.location.origin}/notFound`);
          }
          if (!encryptedParam && error.request.responseURL !== `${BASE_URL}/user/me`) {
            window.location.reload();
          }
          break;
        }
        case 404: {
          window.location.replace(`${window.location.origin}/notFound`);
          break;
        }
        case 400: {
          if ('data' in error.response && !!error.response.data) {
            return Promise.resolve(error.response.data);
          }
          break;
        }
        default: {
          break;
        }
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
