import { MantineTheme, createStyles } from '@mantine/core';

const width = '155px';

const useStyles = createStyles((theme: MantineTheme) => ({
  inputSlices: {
    width: '155px',
  },
  loaderContainer: {
    height: '30px',
  },
  inputWrapper: {
    zIndex: 301,
  },
  input: {
    width,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    border: `1px ${theme.black} solid`,
    minHeight: '30px',
    height: '30px',
    lineHeight: 'unset',
    backgroundColor: theme.white,
    cursor: 'pointer',
    '&:disabled': {
      border: `1px ${theme.colors.white[4]} solid`,
      color: theme.colors.white[4],
      backgroundColor: theme.white,
    },
    '&[aria-expanded="true"]': {
      backgroundColor: theme.colors.white[0],
      borderRadius: '8px 8px 0 0',
      borderBottom: 'none',
      marginBottom: '-9px',
      paddingBottom: '11px',
      minHeight: '39px',
      height: '39px',
      '&:after': {
        position: 'absolute',
        top: 'calc(100% + 8px)',
        left: '1px',
        width: 'calc(100% - 2px)',
        height: '1px',
        backgroundColor: theme.colors.white[1],
        content: "''",
      },
    },
  },
  rightSection: {
    pointerEvents: 'none',
  },
  dropdown: {
    border: `1px ${theme.black} solid`,
    borderRadius: '0 0 8px 8px',
    backgroundColor: theme.colors.white[0],
    padding: '4px 4px 0px 0px',
    top: '-10px',
  },
  dropdownSlices: {
    '&:before': {
      width,
    },
  },
  activeFilter: {
    backgroundColor: `${theme.colors['light-gray'][7]} !important`,
  },
}));

export default useStyles;
