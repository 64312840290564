import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  button: {
    color: theme.colors.green[7],
    borderRadius: '5px',
    border: `2px solid ${theme.colors.green[7]}`,
  },
  slackIcon: {
    height: '15px',
    width: '15px',
  },
}));

export default useStyles;
