import React from 'react';
import { Group } from '@mantine/core';

import { GroupBy } from '../../statistics/types/enums';
import { IPollInfo, IPollSlicesResponse } from '../../statistics/types/slicesAndInfo';
import Filter from '../Filter/Filter';
import useGetPollSlices from '../../statistics/queries/useGetPollSlices';
import { useSelected } from '../../globalStates/Selected';
import { useComparison } from '../../globalStates/Comparison';
import { useFilter } from '../../globalStates/Filter';
import { ELEMENT_CLASS, ELEMENT_ID } from '../../constants';

import useStyles from './Filters.style';

function Filters(props: { poll: IPollInfo; isFree: boolean }) {
  const { poll, isFree } = props;
  const { data: pollSlices, isLoading: isPollSlicesLoading } = useGetPollSlices(poll.id);
  const { classes } = useStyles();
  const [selected] = useSelected();
  const [comparison, setComparison] = useComparison();
  const [filter] = useFilter();

  const isFiltered =
    filter.departments.length > 0 ||
    filter.locations.length > 0 ||
    filter.roles.length > 0 ||
    filter.tenures.length > 0;

  const haveSelected = !!selected;

  React.useEffect(() => {
    if (!haveSelected) {
      setComparison({ ...comparison, showFiltered: isFiltered, showOverall: !isFiltered });
    }

    if (haveSelected && !isFiltered) {
      setComparison({ ...comparison, showFiltered: false });
    }
  }, [isFiltered, comparison, haveSelected, setComparison]);

  const filters = Object.values(GroupBy).map((groupBy) => {
    const isLoading = isPollSlicesLoading;
    const data = pollSlices?.[groupBy.toLowerCase() as keyof IPollSlicesResponse];
    return (
      <div key={groupBy} className={ELEMENT_CLASS.FILTER} data-filter={groupBy}>
        <Filter isLoading={isLoading} data={data} type={groupBy} isFree={isFree} />
      </div>
    );
  });

  return (
    <div id={ELEMENT_ID.FILTERS}>
      <Group className={classes.container}>{filters}</Group>
    </div>
  );
}

export default Filters;
