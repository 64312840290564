import { useMutation } from 'react-query';

import { IApiResponse, ISettingsDepartment } from '../types';
import axios from '../axios';

import queryClient from './queryClient';

const useCreateSettingsDepartmentMutation = () =>
  useMutation<ISettingsDepartment, Error, Omit<ISettingsDepartment, 'id'>, unknown>(
    async (request) => {
      const response = await axios.post<unknown, IApiResponse<ISettingsDepartment>>(
        `/gateway/tilly/settings/departments`,
        request,
      );
      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (response) => {
        const departments = queryClient.getQueryData<ISettingsDepartment[]>('settings/departments');
        if (departments) {
          queryClient.setQueryData<ISettingsDepartment[]>('settings/departments', [...departments, response]);
        }
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: ['settings/departments'] });
      },
    },
  );

export default useCreateSettingsDepartmentMutation;
