import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  root: {
    padding: '0px 12px',
    border: `1px solid ${theme.black}`,
    boxShadow: `0px 2px 0px ${theme.colors.black}, 0px 1px 0px ${theme.colors.black}`,
    borderRadius: '12px',
    backgroundColor: theme.colors.white[0],
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  btnText: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      fontSize: theme.fontSizes.md,
      lineHeight: '22px',
    },
  },
}));

export default useStyles;
