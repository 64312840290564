import React, { useCallback } from 'react';
import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

type Props = {
  onConfirm: () => void;
  close: () => void;
  opened: boolean;
  finishedPercent: number;
};

export default function ExtendConfirmationModal({ onConfirm, close, opened, finishedPercent }: Props) {
  const { t } = useTranslation();

  const onConfirmClick = useCallback(() => {
    onConfirm();
    close();
  }, [close, onConfirm]);

  return (
    <Modal
      onClose={close}
      opened={opened}
      title={<Text weight="bold">{t('ActivePollSettings.ExtendConfirmationModal.Title')}</Text>}
    >
      <Stack>
        <Text size="sm">
          {t('ActivePollSettings.ExtendConfirmationModal.Notification', {
            finishedPercent,
            formatParams: { finishedPercent: { maximumFractionDigits: 0 } },
          })}
        </Text>
        <Text size="sm">{t('ActivePollSettings.ExtendConfirmationModal.Question')}</Text>
        <Group position="right">
          <Button color="green" onClick={onConfirmClick}>
            {t('ActivePollSettings.ExtendConfirmationModal.Confirm')}
          </Button>
          <Button color="gray" onClick={close}>
            {t('ActivePollSettings.ExtendConfirmationModal.Cancel')}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
