import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  container: {
    marginTop: '55px',
    gap: '50px',
  },
  carouselRoot: {
    marginTop: '55px',
    maxWidth: '370px',
  },
}));

export default useStyles;
