import { createStyles, MantineSize, MantineTheme } from '@mantine/core';

const useStyles = createStyles(
  (
    theme: MantineTheme,
    {
      breakpoint,
      asideScrollbarSize,
      mx,
      haveAside,
      asideWidth,
    }: { breakpoint: MantineSize; asideScrollbarSize: number; mx: number; haveAside: boolean; asideWidth: number },
  ) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100%',
      paddingRight: haveAside ? `calc(${asideWidth}px + ${mx}px)` : '0px',
      [`@media (max-width: ${theme.breakpoints[breakpoint]}px)`]: {
        paddingTop: 0,
      },
    },
    body: {
      backgroundColor: theme.colors.white[0],
    },
    header: {
      borderBottom: `1px ${theme.colors.white[1]} solid`,
      [`@media (max-width: ${theme.breakpoints[breakpoint]}px)`]: {
        position: 'static',
      },
      '&.sticky': {
        position: 'sticky',
      },
    },
    viewport: {
      paddingRight: `${asideScrollbarSize}px`,
    },
    scrollArea: {
      height: 'calc(100vh - 114px - 73px)',
      [`@media (max-width: ${theme.breakpoints[breakpoint]}px)`]: {
        height: '100%',
      },
      '[data-orientation="horizontal"] .mantine-ScrollArea-thumb': {
        display: 'none',
      },
      '.mantine-ScrollArea-viewport': {
        paddingRight: '0px',
      },
    },
  }),
);

export default useStyles;
