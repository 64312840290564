export default function groupBy<T, K>(arr: T[], keyGetter: (item: T) => K): Record<string, T[]> {
  return arr.reduce((result: Record<string, T[]>, item: T) => {
    const key = String(keyGetter(item));
    if (!result[key]) {
      /* eslint-disable-next-line */
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
}
