import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    padding: `${theme.spacing.xs}px 0 ${theme.spacing.lg}px`,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingTop: `${theme.spacing.lg}px`,
    },
    height: '100%',
  },
  bottomFloor: {
    marginTop: '18px',
    alignItems: 'baseline',
    gap: theme.spacing.md,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginTop: '13px',
    },
    flexWrap: 'nowrap',
  },
  topFloor: {
    flexWrap: 'nowrap',
    gap: 0,
  },
}));

export default useStyles;
