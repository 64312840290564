import { useMutation } from 'react-query';

import { IApiResponse, IDeleteSliceResponse, ISettingsRole, ISlicesSettingsDto } from '../types';
import axios from '../axios';

import queryClient from './queryClient';

const useDeleteSettingsRoleMutation = () =>
  useMutation<IDeleteSliceResponse, Error, ISettingsRole, unknown>(
    async (role: ISettingsRole) => {
      const response = await axios.delete<unknown, IApiResponse<IDeleteSliceResponse>>(
        `/gateway/tilly/settings/roles/${role.id}`,
      );
      if (!response || response.code !== 'success' || !response.value) {
        if (response.code === 'entity_not_found') {
          throw new Error("That role wasn't found. Try to update page to refresh data.");
        }

        if (response.code === 'active_poll') {
          throw new Error('You cannot delete roles while the poll is in progress.');
        }

        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (response, role) => {
        const roles = queryClient.getQueryData<ISettingsRole[]>('settings/roles');
        if (roles) {
          queryClient.setQueryData<ISettingsRole[]>(
            'settings/roles',
            roles.filter((x) => x.id !== role.id),
          );
        }

        const slicesSettings = queryClient.getQueryData<ISlicesSettingsDto>('settings/slices');
        if (slicesSettings) {
          queryClient.setQueryData<ISlicesSettingsDto>('settings/slices', {
            ...slicesSettings,
            rolesEnabled: response.sliceEnabled,
          });
        }
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: ['settings/roles'] });
      },
    },
  );

export default useDeleteSettingsRoleMutation;
