import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import axios from '../axios';
import { IApiResponse, IPollDto } from '../types';

import queryClient from './queryClient';

interface ExtendedPollResult {
  code: 'extended_poll';
}

interface SuccessResult {
  code: 'success';
  value: IPollDto;
}

const useExtendPollMutation = () =>
  useMutation<SuccessResult | ExtendedPollResult, Error, number, unknown>(
    async (pollId: number) => {
      try {
        const response = await axios.put<unknown, IApiResponse<IPollDto>>(`/gateway/tilly/settings/polls/${pollId}`);

        return {
          code: 'success',
          value: response.value!,
        };
      } catch (er) {
        const error = er as AxiosError<IApiResponse<IPollDto>>;

        if (error.response && error.response.data?.code === 'entity_not_found') {
          throw new Error("The poll wasn't found.");
        }

        if (error.response && error.response.data?.code === 'extended_poll') {
          return {
            code: 'extended_poll',
          };
        }

        throw new Error(`Response was not successful. Code: ${error.response && error.response.data?.code}.`);
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries({ queryKey: 'settings/progress/poll' });
      },
      onError: () => {
        queryClient.refetchQueries({ queryKey: 'settings/polls' });
        queryClient.refetchQueries({ queryKey: 'settings/active-poll' });
      },
    },
  );

export default useExtendPollMutation;
