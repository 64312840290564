import React from 'react';
import { ActionIcon, Badge } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconX } from '@tabler/icons-react';

import useGetPollSlices from '../../statistics/queries/useGetPollSlices';
import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import { useFilter } from '../../globalStates/Filter';

import useStyles from './FilteredBadge.styles';

export default function FilteredBadge({ poll }: { poll: IPollInfo }) {
  const { classes, theme } = useStyles();
  const [filter, setFilter] = useFilter();

  const slices = useGetPollSlices(poll.id);

  const { t } = useTranslation();

  const slicesSeparator = t('FilteredBadge.SlicesSeparator');
  const sliceSeparator = t('FilteredBadge.SliceSeparator');

  const clearFilter = React.useCallback(
    () => setFilter({ departments: [], roles: [], locations: [], tenures: [] }),
    [setFilter],
  );

  const filteredBadge = React.useMemo(() => {
    if (!slices.data) {
      return null;
    }

    function getTenuresText() {
      const tenures = filter.tenures.map((name) => t(`Tenures.${name}`)).join(sliceSeparator);

      if (!tenures.length) {
        return null;
      }

      return t('FilteredBadge.Tenures', { tenures });
    }

    function getDepartmentsText() {
      const departments = filter.departments
        .map((id) => slices.data!.departments.find((d) => d.id === id)!.name)
        .join(sliceSeparator);

      if (!departments.length) {
        return null;
      }

      return t('FilteredBadge.Departments', { departments });
    }

    function getRolesText() {
      const roles = filter.roles.map((id) => slices.data!.roles.find((r) => r.id === id)!.name).join(sliceSeparator);

      if (!roles.length) {
        return null;
      }

      return t('FilteredBadge.Roles', { roles });
    }

    function getLocationsText() {
      const locations = filter.locations
        .map((id) => slices.data!.locations.find((l) => l.id === id)!.name)
        .join(sliceSeparator);

      if (!locations.length) {
        return null;
      }

      return t('FilteredBadge.Locations', { locations });
    }

    const filters = [getTenuresText(), getDepartmentsText(), getRolesText(), getLocationsText()]
      .filter((slice) => slice)
      .join(slicesSeparator);

    return t('FilteredBadge', { filters });
  }, [slices, slicesSeparator, sliceSeparator, t, filter]);

  if (slices.isLoading || !filteredBadge) {
    return <div />;
  }

  return (
    <Badge
      className={classes.badge}
      rightSection={
        <ActionIcon size="xs" variant="transparent" onClick={clearFilter}>
          <IconX size={14} color={theme.colors.red[4]} />
        </ActionIcon>
      }
    >
      {filteredBadge}
    </Badge>
  );
}
