import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  wrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    minHeight: '100vh',
  },
  message: {
    maxWidth: '450px',
    lineHeight: '36px',
    fontSize: '24px',
  },
  link: {
    alignItems: 'center',
    color: theme.black,
    backgroundColor: theme.colors.white[0],
    border: '1px solid #ddd',
    borderRadius: theme.radius.xs,
    display: 'inline-flex',
    fontFamily: 'Lato, sans-serif',
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    height: '48px',
    justifyContent: 'center',
    textDecoration: 'none',
    width: '256px',
  },
  slackIcon: {
    height: '20px',
    width: '20px',
    marginRight: '12px',
  },
}));

export default useStyles;
