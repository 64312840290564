import { useMutation } from 'react-query';

import { IApiResponse, ISettingsDepartment } from '../types';
import axios from '../axios';

import queryClient from './queryClient';

const useUpdateSettingsDepartmentMutation = () =>
  useMutation<ISettingsDepartment, Error, ISettingsDepartment, unknown>(
    async (department: ISettingsDepartment) => {
      const response = await axios.put<unknown, IApiResponse<ISettingsDepartment>>(
        `/gateway/tilly/settings/departments/${department.id}`,
        {
          name: department.name,
          parentId: department.parentId,
        },
      );
      if (!response || response.code !== 'success' || !response.value) {
        if (response.code === 'entity_not_found') {
          throw new Error("That department wasn't found. Try to update page to refresh data.");
        }

        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (response, request) => {
        const departments = queryClient.getQueryData<ISettingsDepartment[]>('settings/departments');
        if (departments) {
          queryClient.setQueryData<ISettingsDepartment[]>(
            'settings/departments',
            departments.map((x) => (x.id === request.id ? response : x)),
          );
        }
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: ['settings/departments'] });
      },
    },
  );

export default useUpdateSettingsDepartmentMutation;
