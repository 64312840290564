import Shepherd from 'shepherd.js';

import isElementVisible from '../../helpers/isElementVisible';

export function addProgress(step: Shepherd.Step) {
  const activeTour = step.getTour();
  const currentStepElement = step.getElement();

  if (!currentStepElement) {
    return;
  }

  const progress = document.createElement('span');
  progress.className = 'shepherd-progress';
  progress.innerText = `${activeTour.steps.indexOf(step) + 1}/${activeTour.steps.length}`;

  const title = currentStepElement.querySelector('.shepherd-title');
  title?.prepend(progress);
}

function createDisposableHandlers() {
  const handlers: (() => void)[] = [];

  const subscribe = (handler: () => void) => {
    handlers.push(handler);
  };

  const invoke = () => {
    handlers.forEach((handler) => {
      handler();
    });
    handlers.splice(0);
  };

  return {
    subscribe,
    invoke,
  };
}

export const onceHideHandlers = createDisposableHandlers();

export function isVisible(
  attachToParam: string | HTMLElement | (() => string | HTMLElement | null | undefined) | undefined,
) {
  const attachTo = typeof attachToParam === 'function' ? attachToParam() : attachToParam;

  if (attachTo === undefined) {
    return true;
  }

  const element = typeof attachTo === 'string' ? document.querySelector<HTMLElement>(attachTo) : attachTo;

  return element !== null && isElementVisible(element);
}
