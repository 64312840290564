import { autoUpdate } from '@floating-ui/dom';

export default function createOverlay(targets: HTMLElement[], configure?: (overlay: HTMLDivElement) => void) {
  if (targets.length === 0) {
    throw new Error('Empty arrays are not supported');
  }

  const overlay = document.createElement('div');

  if (configure) {
    configure(overlay);
  }

  Object.assign(overlay.style, {
    position: 'absolute',
  });

  document.body.appendChild(overlay);

  function updatePosition() {
    const firstRect = targets[0].getBoundingClientRect();
    const coordinates = { x0: firstRect.left, x1: firstRect.right, y0: firstRect.top, y1: firstRect.bottom };

    for (let index = 1; index < targets.length; index += 1) {
      const target = targets[index];
      const rect = target.getBoundingClientRect();

      Object.assign(coordinates, {
        x0: Math.min(coordinates.x0, rect.left),
        x1: Math.max(coordinates.x1, rect.right),
        y0: Math.min(coordinates.y0, rect.top),
        y1: Math.max(coordinates.y1, rect.bottom),
      });
    }

    Object.assign(overlay.style, {
      left: `${coordinates.x0}px`,
      top: `${coordinates.y0}px`,
      width: `${coordinates.x1! - coordinates.x0!}px`,
      height: `${coordinates.y1! - coordinates.y0!}px`,
    });
  }

  const stopUpdatingArray = targets.map((target) => autoUpdate(target, overlay, updatePosition));

  return () => {
    overlay.remove();
    stopUpdatingArray.forEach((stopUpdating) => stopUpdating());
  };
}
