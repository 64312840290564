import React from 'react';
import { Button, Group, Modal, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../constants';

interface Props {
  code: 'success' | 'bot_uninstalled' | 'active_poll' | 'not_configured' | undefined;
  errors?: string[];
  opened: boolean;
  close: () => void;
}

export default function ErrorModal({ code, errors, opened, close }: Props) {
  const { t } = useTranslation();
  return (
    <Modal opened={opened} onClose={close} centered withCloseButton={false}>
      {code === 'not_configured' && (
        <>
          <Text weight="bold" mb="sm">
            {t('ScheduleSettingsForm.ErrorModal.NotConfigured.Header')}
          </Text>

          {errors?.map((error) => (
            <Text key={error} mt="2px">
              {t('ScheduleSettingsForm.ErrorModal.NotConfigured.Body', { context: error })}
            </Text>
          ))}

          <Text mt="10px">{t('ScheduleSettingsForm.ErrorModal.NotConfigured.Text')}</Text>
          <Group position="center" grow mt="10px">
            <Button variant="outline" color="gray" onClick={close}>
              {t('ScheduleSettingsForm.ErrorModal.Cancel')}
            </Button>
            <Button component={Link} to={ROUTES.SETTINGS} variant="filled" color="violet">
              {t('ScheduleSettingsForm.ErrorModal.Settings')}
            </Button>
          </Group>
        </>
      )}
    </Modal>
  );
}
