import React, { PropsWithChildren } from 'react';
import { Row, Table } from '@tanstack/react-table';

import HierarchicalButton from '../HierarchicalButton/HierarchicalButton';

import useStyles from './HierarchicalCell.styles';

interface IProps<TRowData> {
  table: Table<TRowData>;
  row: Row<TRowData>;
}

export default function HierarchicalCell<TRowData>({ table, row }: PropsWithChildren<IProps<TRowData>>) {
  const { classes, cx } = useStyles();
  const { rows } = table.getRowModel();

  if (!rows.some((r) => r.subRows.length)) {
    return <div className={classes.hierarchyCellContainer} />;
  }

  const parent = row.parentId ? rows.find((r) => r.id === row.parentId) : undefined;
  const main = row.getCanExpand();

  function getHierarchyClassName() {
    if (main) {
      return cx(classes.hierarchy, classes.firstOfHierarchy);
    }

    if (parent?.subRows.at(-1)?.id === row.id) {
      return cx(classes.hierarchy, classes.lastOfHierarchy);
    }

    return classes.hierarchy;
  }

  function isExpanded() {
    if (main) {
      return row.getIsExpanded();
    }

    if (parent) {
      return parent.getIsExpanded();
    }

    return false;
  }

  const className = isExpanded()
    ? cx(getHierarchyClassName(), classes.hierarchyCellContainer)
    : classes.hierarchyCellContainer;

  return (
    <div className={className}>
      {main && <HierarchicalButton onClick={row.getToggleExpandedHandler()} isExpanded={isExpanded()} />}
    </div>
  );
}
