import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Text, Group, Button, Input, Stack } from '@mantine/core';

type Props = {
  modalOpened: boolean;
  onCloseModal: () => void;
  onDeleteButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function PollListModal({ modalOpened, onCloseModal, onDeleteButtonClick }: Props) {
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  const { t } = useTranslation();

  const onDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (t('PollList.DeleteKeyword') !== inputRef.current?.value) {
        setError(t<string>('PollList.DeleteKeywordNotMatched'));
        return;
      }

      setDeleteDisabled(true);
      if (onDeleteButtonClick) onDeleteButtonClick(e);
      onCloseModal();
      setDeleteDisabled(false);
    },
    [onDeleteButtonClick, onCloseModal, t],
  );

  const onClose = useCallback(() => {
    onCloseModal();
    setError(undefined);
  }, [onCloseModal, setError]);

  return (
    <Modal
      title={<Text>{t<string>('PollList.ModalTitle')}</Text>}
      opened={modalOpened}
      onClose={onClose}
      centered
      size="sm"
    >
      <Stack>
        <Text size="sm">{t<string>('PollList.ModalText')}</Text>
        <Input placeholder={t('PollList.DeleteKeyword')} ref={inputRef} />
        {error && (
          <Text color="red" size="sm">
            {error}
          </Text>
        )}
        <Group position="right">
          <Button variant="filled" color="red" onClick={onDeleteClick} disabled={deleteDisabled}>
            {t<string>('PollList.ModalDeleteButtonText')}
          </Button>
          <Button color="gray" onClick={onClose}>
            {t<string>('PollList.ModalCancelButtonText')}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
