import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  button: {
    border: `1px solid ${theme.colors['light-gray'][5]}`,
    borderRadius: '5px',
    background: 'white',
    boxShadow: 'none',
    display: 'inline-flex',
    zIndex: 1,
    width: '17px',
    height: '17px',
  },
  icon: {
    height: '15px',
    width: '15px',
  },
}));

export default useStyles;
