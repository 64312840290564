import { AssessmentZone } from '../types';

export default function evaluate(value: number | null | undefined, zones: AssessmentZone[]) {
  if (typeof value === 'undefined') return 'undefined';
  if (value === null) return 'null';

  const sortedZones = zones.sort((a, b) => a.value - b.value);

  for (let i = 0; i < sortedZones.length; i += 1) {
    if (value <= sortedZones[i].value) {
      return sortedZones[i].level;
    }
  }

  throw new Error('Level out of boudaries');
}
