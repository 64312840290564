import React from 'react';
import { Center, Container, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import LogoutPopover from '../../../../components/Logout/LogoutPopover';
import { ReactComponent as ErrorImage } from '../../../../images/Error.svg';
import useStyles from '../../styles';
import ErrorPage from '../..';

interface Props {
  currentWorkspaceName: string;
  fromWorkspaceName: string;
}

function ReLoginErrorBody({ currentWorkspaceName, fromWorkspaceName }: Props): JSX.Element {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Center dir="column" className={classes.errorContainer}>
      <Container>
        <Center>
          <ErrorImage className={classes.svgImage} />
        </Center>
        <Text className={classes.oops}>{t('ReLoginErrorPage.Title')}</Text>
        <Text className={classes.text}>
          {t('ReLoginErrorPage.Text', { fromWorkspace: fromWorkspaceName, currentWorkspace: currentWorkspaceName })}
        </Text>
        <Center>
          <LogoutPopover logoutText="SignInAgainButton" underlineText={false} />
        </Center>
      </Container>
    </Center>
  );
}

export default function ReLoginErrorPage(props: Props) {
  return (
    <ErrorPage>
      <ReLoginErrorBody {...props} />
    </ErrorPage>
  );
}
