import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  errorContainer: {
    marginTop: theme.spacing.xl,
    fontSize: theme.fontSizes.xl,
  },
  oops: {
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0',
    textAlign: 'center',
    marginTop: '42px',
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
  svgImage: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      width: '200px',
      height: '80px',
    },
  },
  text: {
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: 0,
    textAlign: 'center',
    marginTop: '8px',
    marginBottom: '32px',
    color: '#18181866',
    maxWidth: '600px',
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: theme.fontSizes.md,
      lineHeight: '22px',
    },
  },
  btnText: {
    margin: '8px 12px',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      fontSize: theme.fontSizes.md,
      lineHeight: '22px',
    },
  },
}));

export default useStyles;
