import React from 'react';

interface ContextType {
  opened: boolean;
  setOpened: (x: boolean) => void;
  fixed: boolean;
  setFixed: (x: boolean) => void;
}

const defaultValue = {
  opened: false,
  setOpened: () => {},
  fixed: false,
  setFixed: () => {},
};

const Context = React.createContext<ContextType>(defaultValue);

export const OpenedGroupByContext: ContextType = defaultValue;

export const useOpenedGroupBy = () => React.useContext(Context);

export default function OpenedGroupByProvider({ children }: React.PropsWithChildren) {
  const [opened, setOpened] = React.useState(false);
  const [fixed, setFixed] = React.useState(false);

  OpenedGroupByContext.opened = opened;
  OpenedGroupByContext.setOpened = setOpened;
  OpenedGroupByContext.fixed = fixed;
  OpenedGroupByContext.setFixed = setFixed;

  const contextValue = React.useMemo<ContextType>(
    () => ({ opened, setOpened, fixed, setFixed }),
    [opened, setOpened, fixed, setFixed],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
