import { MantineTheme, createStyles } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  modal: {
    backgroundColor: theme.colors.blue[5],
    padding: '20px 70px 30px 70px !important',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '20px 30px 30px 30px !important',
    },
  },
  header: {
    fontWeight: 'bold',
    fontSize: theme.fontSizes.xl,
  },
  title: {
    margin: 'auto',
    width: '50%',
    textAlign: 'center',
  },
  close: {
    position: 'relative',
    svg: {
      height: '100%',
      width: '100%',
      path: {
        fill: theme.colors.black[0],
      },
    },
  },
  warning: {
    marginBottom: '10px',
  },
}));

export default useStyles;
