import { UnstyledButton } from '@mantine/core';
import React from 'react';

import { ROW_ID } from '../OverviewTable.constants';
import { useComparison } from '../../../globalStates/Comparison';
import { useGroupBy } from '../../../globalStates/GroupBy';
import { GroupBy, Tenure } from '../../../statistics/types/enums';
import { ELEMENT_CLASS } from '../../../constants';

import useStyles from './CompareByButton.styles';

interface Props extends React.PropsWithChildren {
  className?: string;
  add: boolean;
  row: string;
}

export default function CompareByButton({ children, className, add, row }: Props) {
  const { classes, cx } = useStyles();
  const [comparison, setComparison] = useComparison();
  const [groupBy] = useGroupBy();

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const compared = groupBy === GroupBy.Tenures ? (row as Tenure) : Number(row);

      if (row === ROW_ID.OVERALL) {
        setComparison({ ...comparison, showOverall: add });
      } else if (row === ROW_ID.FILTERED) {
        setComparison({ ...comparison, showFiltered: add });
      } else if (add) {
        setComparison({ ...comparison, comparison: [...comparison.comparison, compared] as number[] | Tenure[] });
      } else {
        comparison.comparison.splice(comparison.comparison.indexOf(compared as never), 1);
        setComparison(comparison);
      }
    },
    [add, row, comparison, setComparison, groupBy],
  );

  const buttonClassName = cx(classes.button, className, ELEMENT_CLASS.OVERVIEW_TABLE_COMPARE_BUTTON);

  return (
    <UnstyledButton onClick={onClick} className={buttonClassName}>
      {children}
    </UnstyledButton>
  );
}
