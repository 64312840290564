import React from 'react';
import { Center, Container, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import useStyles from '../../styles';
import { ReactComponent as ErrorImage } from '../../../../images/Error.svg';
import LogoutPopover from '../../../../components/Logout/LogoutPopover';
import ErrorPage from '../..';

function AccessDeniedErrorBody() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Center dir="column" className={classes.errorContainer}>
      <Container>
        <Center>
          <ErrorImage className={classes.svgImage} />
        </Center>
        <Text className={classes.oops}>{t('AccessDeniedErrorPage.Title')}</Text>
        <Text className={classes.text}>{t('AccessDeniedErrorPage.Text')}</Text>
        <Center>
          <LogoutPopover logoutText="SignInAgainButton" underlineText={false} buttonClass={classes.logoutButton} />
        </Center>
      </Container>
    </Center>
  );
}

export default function AccessDeniedErrorPage() {
  return (
    <ErrorPage>
      <AccessDeniedErrorBody />
    </ErrorPage>
  );
}
