import React, { useEffect } from 'react';
import { Box, Stack, Tabs } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from '../../constants';
import EmailPermissionSettings from '../EmailPermissionSettings';

import MainSettingsTab from './MainSettingsTab';
import SurveySettingsTab from './SurveySettingsTab';
import SettingsGreeting from './SettingsGreeting';

enum TabValue {
  Root = 'root',
  Survey = 'survey',
}

export default function SettingsBody() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tabValue } = useParams();

  useEffect(() => {
    if (!!tabValue && tabValue !== TabValue.Survey) {
      navigate(ROUTES.SETTINGS);
    }
  }, [tabValue, navigate]);

  const onTabChange = React.useCallback(
    (value: string) => {
      if (value === TabValue.Root) {
        navigate(ROUTES.SETTINGS);
      } else {
        navigate(`${ROUTES.SETTINGS}/${value}`);
      }
    },
    [navigate],
  );

  return (
    <Box maw="500px" w="100%" py="lg" m="auto">
      <Stack mb="md" spacing="xs">
        <SettingsGreeting />
        <EmailPermissionSettings />
      </Stack>
      <Tabs
        variant="pills"
        color="violet"
        value={tabValue ?? TabValue.Root}
        onTabChange={onTabChange}
        keepMounted={false}
      >
        <Tabs.List>
          <Tabs.Tab value={TabValue.Root}>{t('SettingsBody.RootTab')}</Tabs.Tab>
          <Tabs.Tab value={TabValue.Survey}>{t('SettingsBody.SurveyTab')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={TabValue.Root} py="lg">
          <MainSettingsTab />
        </Tabs.Panel>

        <Tabs.Panel value={TabValue.Survey} py="lg">
          <SurveySettingsTab />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
}
