import { useMutation } from 'react-query';

import { IApiResponse, ISettingsLocation } from '../types';
import axios from '../axios';

import queryClient from './queryClient';

const useUpdateSettingsLocationMutation = () =>
  useMutation<ISettingsLocation, Error, ISettingsLocation, unknown>(
    async (location: ISettingsLocation) => {
      const response = await axios.put<unknown, IApiResponse<ISettingsLocation>>(
        `/gateway/tilly/settings/locations/${location.id}`,
        {
          name: location.name,
        },
      );
      if (!response || response.code !== 'success' || !response.value) {
        if (response.code === 'entity_not_found') {
          throw new Error("That location wasn't found. Try to update page to refresh data.");
        }

        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (response, request) => {
        const locations = queryClient.getQueryData<ISettingsLocation[]>('settings/locations');
        if (locations) {
          queryClient.setQueryData<ISettingsLocation[]>(
            'settings/locations',
            locations.map((x) => (x.id === request.id ? response : x)),
          );
        }
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: ['settings/locations'] });
      },
    },
  );

export default useUpdateSettingsLocationMutation;
