import React, { useMemo } from 'react';
import { Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { IOverallOverview, IOverviewStatistics, IPredictorStats } from '../../statistics/types/overview';
import { Plan, Predictor } from '../../statistics/types/enums';
import Carousel from '../Carousel/Carousel';
import PredictorCard from '../PredictorCard/PredictorCard';
import useGetWorkspaceInfo from '../../statistics/queries/useGetWorkspaceInfo';
import { usePaymentInfoModal } from '../../globalStates/PaymentInfoModal';
import { usePredictor } from '../../globalStates/Predictor';

import useStyles from './PredictorCards.style';

interface IPredictorCardsProps {
  overallOverview: IOverallOverview | undefined;
  isOverallOverviewLoading: boolean;
  isFirstPoll: boolean;
  isMain?: boolean;
}

export default function PredictorCards({
  isOverallOverviewLoading,
  overallOverview,
  isFirstPoll,
  isMain,
}: IPredictorCardsProps) {
  const { classes, theme } = useStyles();
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const [, setOpened] = usePaymentInfoModal();
  const [{ showNegative, showPositive }, setPredictor] = usePredictor();

  const { data: workspaceInfo, isLoading: isWorkspaceInfoLoading } = useGetWorkspaceInfo();
  const isFree = workspaceInfo?.plan === Plan.Free;
  const isLoading = isWorkspaceInfoLoading || (!isFree && isOverallOverviewLoading);
  let data: IOverviewStatistics | undefined | null;

  const onClickPredictor = React.useCallback(
    (value: Predictor) => () => {
      if (isFree) {
        setOpened(true);
      } else {
        setPredictor({ predictor: value, showNegative, showPositive });
      }
    },
    [isFree, setOpened, setPredictor, showNegative, showPositive],
  );

  const generateRandomData = useMemo(
    () =>
      Object.values(Predictor).reduce(
        (o, key) => ({
          ...o,
          [key.toLowerCase()]: {
            positive: { value: Math.random(), difference: Math.random() },
            negative: { value: Math.random(), difference: Math.random() },
          },
        }),
        {},
      ) as IOverviewStatistics,
    [],
  );

  if (!isFree) {
    data = overallOverview?.statistics;
  } else {
    data = generateRandomData;
  }

  const predictorCards = Object.values(Predictor).map((type) => (
    <PredictorCard
      key={type}
      data={data?.[type.toLowerCase() as keyof IOverviewStatistics] as IPredictorStats}
      isLoading={isLoading}
      isFree={isFree}
      onClick={onClickPredictor}
      type={type}
      isFirstPoll={isFirstPoll}
      describe={isMain}
    />
  ));

  if (middleScreen) {
    return <Carousel rootClassName={classes.carouselRoot}>{predictorCards}</Carousel>;
  }

  return <Group className={classes.container}>{predictorCards}</Group>;
}
