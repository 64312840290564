import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  share: {
    textDecoration: 'underline',
  },
  dropDownNotification: { padding: '0', border: 'none', maxWidth: '100%' },
}));

export default useStyles;
