import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Group, LoadingOverlay, Modal, Text, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { ZodTypeAny, z } from 'zod';

import { ISettingsSlice } from '../../types';
import CustomLoader from '../CustomLoader/CustomLoader';

interface FormValues {
  name: string;
}

interface Props {
  renamingSlice: ISettingsSlice;
  slices: ISettingsSlice[];
  close: () => void;
  submit: (overrides: Partial<ISettingsSlice>) => void;
  isLoading: boolean;
  error: string | undefined;
}

export default function SliceSettingsRenameModal({ renamingSlice, slices, close, submit, isLoading, error }: Props) {
  const { t } = useTranslation();

  const schema = z.object<Record<keyof FormValues, ZodTypeAny>>({
    name: z
      .string()
      .nonempty(t<string>('SlicesSettings.NonEmptyError'))
      .max(75, t<string>('SlicesSettings.MaxLengthError', { maxLength: 75 }))
      .refine(
        (name) => !slices.some((x) => x.id !== renamingSlice.id && x.name === name),
        t<string>('SlicesSettings.UniqueError'),
      ),
  });

  const form = useForm<FormValues>({
    initialValues: {
      name: renamingSlice.name,
    },

    validate: zodResolver(schema),
  });

  const onClose = useCallback(() => {
    if (isLoading) {
      return;
    }

    close();
  }, [isLoading, close]);

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (isLoading) {
        return;
      }

      submit({
        name: values.name,
      });
    },
    [isLoading, submit],
  );

  const textParams = useMemo(() => ({ slice: renamingSlice.name }), [renamingSlice]);

  return (
    <Modal opened onClose={onClose} withCloseButton={false} centered>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<CustomLoader />} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text weight="bold" mb="sm">
          {t('SlicesSettings.RenameModalTitle')}
        </Text>
        <Text mb="sm">
          <Trans i18nKey="SlicesSettings.RenameModalText" values={textParams} />
        </Text>
        <TextInput label={t('SlicesSettings.RenameModalInputLabel')} mb="sm" {...form.getInputProps('name')} />
        <Group position="center" grow>
          <Button variant="outline" color="gray" onClick={onClose}>
            {t('SlicesSettings.RenameModalCancelButton')}
          </Button>
          <Button variant="filled" color="violet" type="submit">
            {t('SlicesSettings.RenameModalSubmitButton')}
          </Button>
        </Group>
      </form>
      {!!error && (
        <Text color="red" align="center" mt="sm">
          {error}
        </Text>
      )}
    </Modal>
  );
}
