import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Text } from '@mantine/core';

import SettingsCard from '../SettingsCard/SettingsCard';
import useDeleteSettingsDepartmentMutation from '../../queries/useDeleteSettingsDepartmentMutation';
import useDeleteSettingsRoleMutation from '../../queries/useDeleteSettingsRoleMutation';
import useGetSettingsDepartments from '../../queries/useGetSettingsDepartments';
import useUpdateSettingsDepartmentMutation from '../../queries/useUpdateSettingsDepartmentMutation';
import useUpdateSettingsRoleMutation from '../../queries/useUpdateSettingsRoleMutation';
import useCreateSettingsDepartmentMutation from '../../queries/useCreateSettingsDepartmentMutation';
import useCreateSettingsRoleMutation from '../../queries/useCreateSettingsRoleMutation';
import useGetSlicesSettings from '../../queries/useGetSlicesSettings';
import useGetSettingsRoles from '../../queries/useGetSettingsRoles';
import useGetSettingsLocations from '../../queries/useGetSettingsLocations';
import useDeleteSettingsLocationMutation from '../../queries/useDeleteSettingsLocationMutation';
import useUpdateSettingsLocationMutation from '../../queries/useUpdateSettingsLocationMutation';
import useCreateSettingsLocationMutation from '../../queries/useCreateSettingsLocationMutation';

import SliceSettings from './SliceSettings';
import { AdditionalSlice } from './SlicesSettings.types';

export default function SlicesSettings() {
  const { t } = useTranslation();

  const slicesEnabledSettings = useGetSlicesSettings();

  const settingsDepartments = useGetSettingsDepartments();
  const deleteSettingsDepartment = useDeleteSettingsDepartmentMutation();
  const updateSettingsDepartment = useUpdateSettingsDepartmentMutation();
  const createSettingsDepartment = useCreateSettingsDepartmentMutation();

  const settingsRoles = useGetSettingsRoles();
  const deleteSettingsRole = useDeleteSettingsRoleMutation();
  const updateSettingsRole = useUpdateSettingsRoleMutation();
  const createSettingsRole = useCreateSettingsRoleMutation();

  const settingsLocations = useGetSettingsLocations();
  const deleteSettingsLocation = useDeleteSettingsLocationMutation();
  const updateSettingsLocation = useUpdateSettingsLocationMutation();
  const createSettingsLocation = useCreateSettingsLocationMutation();

  return (
    <SettingsCard
      label={t<string>('SlicesSettings.AdditionalDataSlicesLabel')}
      description={t<string>('SlicesSettings.AdditionalDataSlicesDescription')}
    >
      <Tabs color="violet" variant="pills" defaultValue={AdditionalSlice.Departments}>
        <Tabs.List>
          <Tabs.Tab value={AdditionalSlice.Departments}>
            <Text size={16} weight={500}>
              {t(`SlicesSettings.AdditionalDataSlices_DepartmentsLabel`)}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value={AdditionalSlice.Roles}>
            <Text size={16} weight={500}>
              {t(`SlicesSettings.AdditionalDataSlices_RolesLabel`)}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value={AdditionalSlice.Locations}>
            <Text size={16} weight={500}>
              {t(`SlicesSettings.AdditionalDataSlices_LocationsLabel`)}
            </Text>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={AdditionalSlice.Departments} pt="xs">
          <SliceSettings
            slice={AdditionalSlice.Departments}
            slicesEnabledSettings={slicesEnabledSettings}
            settingsSlices={settingsDepartments}
            deleteSettingsSlice={deleteSettingsDepartment}
            updateSettingsSlice={updateSettingsDepartment}
            createSettingsSlice={createSettingsDepartment}
          />
        </Tabs.Panel>

        <Tabs.Panel value={AdditionalSlice.Roles} pt="xs">
          <SliceSettings
            slice={AdditionalSlice.Roles}
            slicesEnabledSettings={slicesEnabledSettings}
            settingsSlices={settingsRoles}
            deleteSettingsSlice={deleteSettingsRole}
            updateSettingsSlice={updateSettingsRole}
            createSettingsSlice={createSettingsRole}
          />
        </Tabs.Panel>

        <Tabs.Panel value={AdditionalSlice.Locations} pt="xs">
          <SliceSettings
            slice={AdditionalSlice.Locations}
            slicesEnabledSettings={slicesEnabledSettings}
            settingsSlices={settingsLocations}
            deleteSettingsSlice={deleteSettingsLocation}
            updateSettingsSlice={updateSettingsLocation}
            createSettingsSlice={createSettingsLocation}
          />
        </Tabs.Panel>
      </Tabs>
    </SettingsCard>
  );
}
