import React, { useMemo } from 'react';
import { Center } from '@mantine/core';

import CustomLoader from '../../components/CustomLoader/CustomLoader';

export default function LoadingPage(): JSX.Element {
  const style = useMemo(() => ({ height: '100vh' }), []);

  return (
    <Center style={style}>
      <CustomLoader />
    </Center>
  );
}
