import React from 'react';
import { ActionIcon, Button, Flex, Group, List, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconReload } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

import { IPollDto, IPollProgress } from '../../types';
import SettingsCard from '../SettingsCard/SettingsCard';
import useDeletePollMutation from '../../queries/useDeletePollMutation';
import useExtendPollMutation from '../../queries/useExtendPollMutation';

import { getTimeToDeadline } from './helpers';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ExtendConfirmationModal from './ExtendConfirmationModal';
import ErrorModal from './ErrorModal';

interface IProp {
  pollProgress: IPollProgress;
  pollProgressLoading: boolean;
  updatePollProgress: () => void;
  activePoll: IPollDto;
}

export default function ActivePollSettings({
  pollProgress,
  pollProgressLoading,
  updatePollProgress,
  activePoll,
}: IProp) {
  const { t } = useTranslation();

  const pollDeleteMutation = useDeletePollMutation();
  const pollExtendMutation = useExtendPollMutation();

  const [activePollDeleteModalOpened, { open: openActivePollDeleteModal, close: closeActivePollDeleteModal }] =
    useDisclosure(false);

  const [activePollExtendModalOpened, { open: openActivePollExtendModal, close: closeActivePollExtendModal }] =
    useDisclosure(false);

  const [errorModalOpened, { open: openErrorModal, close: closeErrorModal }] = useDisclosure(false);

  const startedAt = new Date(pollProgress.startedAt);
  const deadline = new Date(pollProgress.deadline);

  const timeToDeadline = getTimeToDeadline(deadline);
  const finishedPercent = Math.floor((pollProgress.finishedPpl / pollProgress.totalPpl) * 100);
  const rejectedPercent = Math.floor((pollProgress.rejectedPpl / pollProgress.totalPpl) * 100);

  const label = (
    <Flex justify="space-between">
      <Text>{t<string>('ActivePollSettings.PollProgress.Header')}</Text>

      <ActionIcon
        onClick={updatePollProgress}
        size={21}
        color="light-gray.4"
        loading={pollProgressLoading}
        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
        loaderProps={{ size: 21 }}
      >
        <IconReload />
      </ActionIcon>
    </Flex>
  );

  const onActivePollDeleteConfirmation = React.useCallback(() => {
    if (activePoll) {
      pollDeleteMutation.mutate(activePoll.id);
    }
  }, [activePoll, pollDeleteMutation]);

  const onActivePollExtendConfirmation = React.useCallback(() => {
    if (activePoll) {
      pollExtendMutation.mutate(activePoll.id, {
        onSuccess: (response) => {
          if (response.code === 'extended_poll') {
            openErrorModal();
          }
        },
      });
    }
  }, [activePoll, openErrorModal, pollExtendMutation]);

  const onDeleteClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      openActivePollDeleteModal();
    },
    [openActivePollDeleteModal],
  );

  const onExtendClick = React.useCallback(() => {
    openActivePollExtendModal();
  }, [openActivePollExtendModal]);

  return (
    <>
      <ErrorModal opened={errorModalOpened} close={closeErrorModal} />
      <DeleteConfirmationModal
        close={closeActivePollDeleteModal}
        opened={activePollDeleteModalOpened}
        poll={activePoll}
        onConfirm={onActivePollDeleteConfirmation}
      />
      <ExtendConfirmationModal
        close={closeActivePollExtendModal}
        opened={activePollExtendModalOpened}
        finishedPercent={finishedPercent}
        onConfirm={onActivePollExtendConfirmation}
      />
      <Stack>
        <SettingsCard label={label}>
          <div>
            <Text size={14} weight={500}>
              {t('ActivePollSettings.PollProgress.StartedAt', { startedAt })}
            </Text>
            <List size={14} w={500} mt="xs">
              <List.Item>{t('ActivePollSettings.PollProgress.TimeToDeadline', { time: timeToDeadline })}</List.Item>
              <List.Item>
                {t('ActivePollSettings.PollProgress.Finished', {
                  finishedPpl: pollProgress.finishedPpl,
                  finishedPercent,
                  totalPpl: pollProgress.totalPpl,
                  formatParams: { finishedPercent: { maximumFractionDigits: 0 } },
                })}
              </List.Item>
              <List.Item>
                {t('ActivePollSettings.PollProgress.Rejected', {
                  rejectedPpl: pollProgress.rejectedPpl,
                  rejectedPercent,
                  formatParams: { rejectedPercent: { maximumFractionDigits: 0 } },
                })}
              </List.Item>
            </List>
          </div>
        </SettingsCard>
        <Group position="center">
          <Button color="green" onClick={onExtendClick} loading={pollExtendMutation.isLoading}>
            {t('ActivePollSettings.Extend')}
          </Button>
          <Button color="red" onClick={onDeleteClick} loading={pollDeleteMutation.isLoading}>
            {t('ActivePollSettings.Delete')}
          </Button>
        </Group>
      </Stack>
    </>
  );
}
