import { useQuery } from 'react-query';

import { ISettingsLocation } from '../types';
import axios from '../axios';

const useGetSettingsLocations = (enabled = true) =>
  useQuery<ISettingsLocation[], Error>(
    'settings/locations',
    () => axios.get<unknown, ISettingsLocation[]>('/gateway/tilly/settings/locations'),
    {
      staleTime: Infinity,
      retry: true,
      enabled,
    },
  );

export default useGetSettingsLocations;
