import React from 'react';
import { Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { getFormattedDate } from '../../helpers/getFormattedDate';
import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import toPercent from '../../helpers/toPercent';

import useStyles from './SurveyInfo.style';

export default function SurveyInfo(props: { poll: IPollInfo }) {
  const { poll } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Group className={classes.container}>
      <Text className={classes.date}>{`${getFormattedDate(poll.startedAt)} ${t('SurveyInfo.Survey')}`}</Text>
      <Text className={classes.stats}>{`${toPercent(poll.completedPpl / poll.totalPpl)}% ${t('SurveyInfo.Completed', {
        count: poll.totalPpl,
      })}`}</Text>
      <Text className={classes.stats}>{t('SurveyInfo.Rejected', { count: poll.rejectedPpl })}</Text>
    </Group>
  );
}
