import React from 'react';
import { Center, RingProgress } from '@mantine/core';

import RING_LOADER_SECTIONS from './CustomLoader.constants';
import useStyles from './CustomLoader.style';

function CustomLoader() {
  const { classes } = useStyles();
  return (
    <Center className={classes.loader}>
      <RingProgress
        className={classes.ring}
        size={45}
        thickness={5}
        sections={RING_LOADER_SECTIONS}
        rootColor="transparent"
      />
    </Center>
  );
}

export default CustomLoader;
