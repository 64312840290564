import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import axios from '../axios';
import { IApiResponse, IScheduleSettingsDto, IScheduleSettingsWebDto } from '../types';

interface BotInstalledResult {
  code: 'bot_uninstalled';
}

interface SuccessResult {
  code: 'success';
  value: IScheduleSettingsDto;
}

const useGetScheduleSettings = (enabled: boolean) =>
  useQuery<SuccessResult | BotInstalledResult, Error>(
    'settings/schedule',
    async () => {
      const response = await axios.get<unknown, IApiResponse<IScheduleSettingsWebDto>>(
        '/gateway/tilly/settings/schedule',
      );
      if (!response || response.code !== 'success' || !response.value) {
        if (response.code === 'bot_uninstalled') {
          return {
            code: 'bot_uninstalled',
          };
        }

        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      const dto = response.value;

      return {
        code: 'success',
        value: {
          start: dto.start !== null ? new Date(dto.start) : null,
          interval: dto.interval !== null ? dayjs.duration(dto.interval) : null,
          channelId: dto.channelId,
          notificationText: dto.notificationText,
        },
      };
    },
    {
      enabled,
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetScheduleSettings;
