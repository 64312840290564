import React from 'react';
import { Box, Mark } from '@mantine/core';
import { Trans } from 'react-i18next';

import useGetMe from '../../queries/useGetMe';
import { IUser } from '../../types';

export default function SettingsGreeting() {
  const { data: user } = useGetMe() as { data: IUser };

  return (
    <Box>
      {/* eslint-disable-next-line react-perf/jsx-no-new-object-as-prop */}
      <Trans i18nKey="SettingsBody.Greeting" values={{ name: user.name }} components={{ mark: <Mark /> }} />
    </Box>
  );
}
