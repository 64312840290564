import { useQueries } from 'react-query';

import { IPollInfo } from '../types/slicesAndInfo';
import { IFilter } from '../types/filter';
import axios from '../../axios';
import { IOverallOverview } from '../types/overview';
import { IApiResponse } from '../../types';

const useGetOverallOverviews = (poll: IPollInfo, filters: IFilter[]) =>
  useQueries(
    filters.map((filter) => ({
      queryKey: [`/stats/polls/${poll.id}/overview`, filter],
      queryFn: async () => {
        const response = await axios.get<unknown, IApiResponse<IOverallOverview>>(
          `/gateway/tilly/stats/polls/${poll.id}/overview`,
          {
            params: { filter },
          },
        );

        if (!response || response.code !== 'success' || !response.value) {
          throw new Error(
            `Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`,
          );
        }

        return response.value;
      },
      options: {
        staleTime: Infinity,
        retry: true,
      },
    })),
  );

export default useGetOverallOverviews;
