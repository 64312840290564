import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme, withCharts: boolean) => ({
  container: {
    gap: theme.spacing.xs,
    width: '340px',
    height: withCharts ? '340px' : '120px',
  },
  values: {
    gap: theme.spacing.xs,
  },
  title: {
    paddingTop: '5px',
  },
  enpsTitle: {
    width: '55px',
  },
  turnoverTitle: {
    width: '110px',
  },
  value: {
    fontSize: '42px',
    fontWeight: 700,
    minWidth: '50px',
  },
  differenceCaption: {
    fontSize: theme.fontSizes.xs,
    lineHeight: 1,
    width: '65px',
    paddingTop: '5px',
  },
  differenceValue: {
    fontWeight: 700,
    fontSize: theme.fontSizes.lg,
  },
  unknown: {
    color: theme.colors.white[4],
  },
  enps: {
    color: theme.colors.green[2],
  },
  turnover: {
    color: theme.colors.red[4],
  },
  description: {
    fontSize: theme.fontSizes.sm,
  },
}));

export default useStyles;
