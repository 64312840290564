import React from 'react';
import { Card, Divider, Flex, Stack, Text } from '@mantine/core';

import useStyles from './SettingsCard.style';

type Props = {
  label: string | JSX.Element;
  htmlFor?: string;
  description?: string;
};

export default function SettingsCard({ children, label, htmlFor, description }: React.PropsWithChildren<Props>) {
  const { classes } = useStyles();

  return (
    <Card shadow="sm" p="lg" radius="md">
      <label htmlFor={htmlFor} className={classes.label}>
        {typeof label === 'string' ? (
          <Flex gap="4px" align="center">
            <Text>{label}</Text>
          </Flex>
        ) : (
          label
        )}
      </label>
      {description && (
        <Text color="dimmed" size={12} mt="xs">
          {description}
        </Text>
      )}
      <Divider mt="xs" mb="lg" />
      <Stack spacing="lg">{children}</Stack>
    </Card>
  );
}
