import React, { useCallback, useState } from 'react';

import useDeletePollMutation from '../../queries/useDeletePollMutation';
import useGetSettingsPollsList from '../../queries/useGetSettingsPollsList';
import CustomLoader from '../CustomLoader/CustomLoader';

import PollList from './PollList';
import { convertToPolls } from './PollList.helpers';

export default function DeletingDataSettings() {
  const polls = useGetSettingsPollsList(6);
  const [deletingPollId, setDeletingPollId] = useState<number | undefined>();

  const onErrorPollDelete = useCallback(() => {
    setDeletingPollId(undefined);
  }, []);

  const onSuccessPollDelete = useCallback(() => {
    setDeletingPollId(undefined);
  }, []);

  const deletePollMutation = useDeletePollMutation(onSuccessPollDelete, onErrorPollDelete);

  const onDeletePoll = useCallback(
    (pollId: number) => {
      deletePollMutation.mutate(pollId);
      setDeletingPollId(pollId);
    },
    [deletePollMutation],
  );

  if (polls.isLoading) {
    return <CustomLoader />;
  }

  if (!polls.isSuccess) {
    throw polls.error ?? new Error('Unknown error');
  }

  return (
    <PollList
      polls={convertToPolls(polls.data)}
      onDelete={onDeletePoll}
      error={deletePollMutation.error?.message}
      deletingPollId={deletingPollId}
      loading={polls.isRefetching}
    />
  );
}
