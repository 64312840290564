import React, { useState, useCallback, useEffect } from 'react';
import { Popover, Text, Notification, Button } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import useGetAccessTokenMutation from '../../queries/useGetAccessTokenMutation';
import { IAccessToken } from '../../types';
import { ELEMENT_ID } from '../../constants';

import useStyles from './ShareLink.style';

interface Props {
  buttonClass?: string;
}

export default function ShareLink({ buttonClass }: Props) {
  const { cx, classes, theme } = useStyles();

  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const mutation = useGetAccessTokenMutation();
  const clipboard = useClipboard({ timeout: 2000 });

  const shareHandler = useCallback(() => {
    function copyLink(data: IAccessToken) {
      const accessLink = new URL(window.location.href);
      accessLink.searchParams.set('p', data.token);
      clipboard.reset();
      clipboard.copy(accessLink.toString());

      if (clipboard.error) {
        console.error(clipboard.error);
      }
    }

    if (!isOpen) {
      setOpen(true);
      if (mutation.data) {
        copyLink(mutation.data);
      } else {
        mutation.mutate(undefined, {
          onSuccess: copyLink,
        });
      }
    }
  }, [isOpen, mutation, clipboard]);

  useEffect(() => {
    if (!isOpen || mutation.isLoading) {
      return () => {};
    }

    const timeOut = setTimeout(() => {
      setOpen(false);
    }, 2000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [isOpen, mutation.isLoading]);

  const closeNotification = useCallback(() => {
    setOpen(false);
  }, []);

  function getNotificationText() {
    if (mutation.isLoading) {
      return t('ShareLink.States.Loading');
    }

    return clipboard.error || mutation.isError ? t('ShareLink.States.Error') : t('ShareLink.States.Success');
  }

  function getNotificationColor() {
    if (mutation.isLoading) {
      return 'indigo';
    }

    return clipboard.error || mutation.isError ? 'red' : 'green';
  }

  return (
    <div id={ELEMENT_ID.SHARE_LINK}>
      <Popover opened={isOpen} zIndex={302}>
        <Popover.Target>
          <Button variant="white" className={cx(classes.share, buttonClass)} onClick={shareHandler}>
            <Text weight="normal" color={theme.black} size={16}>
              {t('ShareLink.Button')}
            </Text>
          </Button>
        </Popover.Target>
        <Popover.Dropdown className={classes.dropDownNotification}>
          <Notification onClose={closeNotification} loading={mutation.isLoading} color={getNotificationColor()}>
            {getNotificationText()}
          </Notification>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}

ShareLink.defaultProps = {
  buttonClass: '',
};
