import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  body: {
    justifyContent: 'space-between',
    'input:disabled + .mantine-Switch-track': {
      backgroundColor: theme.colors.violet[6],
      borderColor: theme.colors.violet[6],
    },
    '.mantine-Switch-label[data-disabled]': {
      color: theme.black,
      cursor: 'not-allowed',
    },
  },
}));

export default useStyles;
