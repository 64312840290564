import { useForceUpdate, useWindowEvent } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';

export default function useIsTruncated(deps?: unknown[]) {
  const ref = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const forseUpdate = useForceUpdate();

  useEffect(() => {
    const element = ref.current;
    setIsTruncated(element ? element.offsetWidth < element.scrollWidth : false);
  }, [deps]);

  useWindowEvent('resize', forseUpdate);

  return {
    ref,
    isTruncated,
  };
}
