import React from 'react';
import { Burger, Group } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { StringParam, useQueryParams } from 'use-query-params';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import LoadingPage from '../loading';
import StatisticsBody from '../../components/StatisticsBody/StatisticsBody';
import PredictorSelect from '../../components/PredictorSelect/PredictorSelect';
import SurveyInfo from '../../components/SurveyInfo/SurveyInfo';
import Filters from '../../components/Filters/Filters';
import Overview from '../../components/Overview/Overview';
import useGetPollsRecent from '../../statistics/queries/useGetPollsRecent';
import useGetPollSlices from '../../statistics/queries/useGetPollSlices';
import useGetWorkspaceInfo from '../../statistics/queries/useGetWorkspaceInfo';
import { GroupBy, Plan } from '../../statistics/types/enums';
import PaymentInfoModal from '../../components/PaymentInfoModal/PaymentInfoModal';
import isDemo from '../../helpers/isDemo';
import NoDataErrorPage from '../error/concrete/NoDataErrorPage';
import StartOnboardingButton from '../../components/StatisticsOnboarding/StartOnboardingButton';

import useStyles from './styles';
import HeaderButtons from './HeaderButtons/HeaderButtons';
import FooterButtons from './FooterButtons/FooterButtons';
import GlobalStateProvider from './GlobalStateProvider';

export default function StatisticsPage(): JSX.Element {
  const { data: pollsRecent, isLoading: isPollsRecentLoading } = useGetPollsRecent();
  const slices = useGetPollSlices(pollsRecent?.polls.length ? pollsRecent.polls[0].id : 0, !!pollsRecent?.polls.length);
  const [{ groupBy }, setQuery] = useQueryParams({ groupBy: StringParam });
  const workspaceInfo = useGetWorkspaceInfo();
  const { classes, theme } = useStyles();
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const [mobileTableOpened, { toggle }] = useDisclosure();

  const isFree = workspaceInfo.data?.plan === Plan.Free;

  const getGroupBy = React.useCallback(() => {
    if (isFree) {
      return GroupBy.Tenures;
    }

    if (groupBy) {
      return groupBy;
    }

    if (!slices.data) {
      return null;
    }

    if (slices.data.departments.length) {
      return GroupBy.Departments;
    }

    if (slices.data.roles.length) {
      return GroupBy.Roles;
    }

    return slices.data.locations.length ? GroupBy.Locations : GroupBy.Tenures;
  }, [groupBy, isFree, slices]);

  React.useEffect(() => {
    if (!workspaceInfo.isLoading) {
      const newGroupBy = getGroupBy();
      if (!groupBy && newGroupBy) {
        setQuery({
          groupBy: newGroupBy,
        });
      }
    }
  }, [getGroupBy, groupBy, setQuery, workspaceInfo]);

  if (!pollsRecent?.polls?.length && !isPollsRecentLoading) {
    return <NoDataErrorPage />;
  }

  if (isPollsRecentLoading || slices.isLoading || workspaceInfo.isLoading || !groupBy) {
    return <LoadingPage />;
  }

  const recentPoll = pollsRecent.polls[0];

  return (
    <GlobalStateProvider>
      <Layout
        header={
          <Header
            firstRow={
              <>
                {!middleScreen && (
                  <>
                    <SurveyInfo poll={recentPoll} />
                    <HeaderButtons />
                  </>
                )}
                {middleScreen && (
                  <Burger opened={mobileTableOpened} onClick={toggle} size="md" className={classes.burger} />
                )}
              </>
            }
            secondRow={
              !middleScreen && (
                <Group className={classes.predictorSelectAndFilters}>
                  <PredictorSelect />
                  <Filters poll={recentPoll} isFree={isFree} />
                </Group>
              )
            }
          />
        }
        body={<StatisticsBody poll={recentPoll} polls={pollsRecent.polls} />}
        footer={
          <Footer
            firstRow={
              middleScreen && (
                <>
                  <SurveyInfo poll={recentPoll} />
                  <FooterButtons />
                </>
              )
            }
          />
        }
        aside={(mobileTableOpened || !middleScreen) && <Overview poll={recentPoll} />}
      />
      {!isDemo() && isFree && <PaymentInfoModal />}
      {process.env.REACT_APP_ENVIRONMENT !== 'Production' && !mobileTableOpened && !middleScreen && (
        <StartOnboardingButton />
      )}
    </GlobalStateProvider>
  );
}
