import React from 'react';
import { Textarea } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  onChange: (x: string) => void;
  error?: string | null;
}

export default function NotificationTextInput({ value, onChange, error }: Props) {
  const { t } = useTranslation();

  const onChangeEvent: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
    (event) => {
      onChange(event.currentTarget.value);
    },
    [onChange],
  );

  return (
    <Textarea
      label={t('ScheduleSettingsForm.NotificationTextInput.Label')}
      placeholder={t<string>('ScheduleSettingsForm.NotificationTextInput.Placeholder')}
      value={value}
      onChange={onChangeEvent}
      error={error}
      autosize
      maxRows={5}
      withAsterisk
      // eslint-disable-next-line
      labelProps={{ mb: 'xs' }}
      description={t('ScheduleSettingsForm.NotificationTextInput.Description')}
    />
  );
}
