import React from 'react';
import { Button, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import useStyles from './BackToLanding.style';

export default function BackToLanding({ buttonClass }: { buttonClass: string }) {
  const { cx, classes } = useStyles();

  const { t } = useTranslation();

  return (
    <Button className={cx(classes.button, buttonClass)} component="a" href={process.env.REACT_APP_LANDING_URI}>
      <Text>{t('BackToLanding')}</Text>
    </Button>
  );
}
