import React, { useCallback, useRef, useState } from 'react';
import { Button, Group, Input, Modal, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { IPollDto } from '../../types';

type Props = {
  onConfirm: () => void;
  close: () => void;
  opened: boolean;
  poll?: IPollDto;
};

export default function DeleteConfirmationModal({ onConfirm, close, opened, poll }: Props) {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  const onClose = useCallback(() => {
    close();
    setError(undefined);
  }, [close, setError]);

  const onConfirmClick = useCallback(() => {
    if (t('ActivePollSettings.DeleteConfirmationModal.DeleteKeyword') !== inputRef.current?.value) {
      setError(t<string>('ActivePollSettings.DeleteConfirmationModal.DeleteKeywordNotMatched'));
      return;
    }

    onConfirm();
    setError(undefined);
    close();
  }, [close, onConfirm, t, setError]);

  return poll ? (
    <Modal
      onClose={onClose}
      opened={opened}
      title={<Text>{t('ActivePollSettings.DeleteConfirmationModal.Title')}</Text>}
    >
      <Stack>
        <Text size="sm">
          {t('ActivePollSettings.DeleteConfirmationModal.Notification', {
            date: new Date(poll.startDateTimeUtc),
            respondersCount: poll.respondersCount,
          })}
        </Text>
        <Input placeholder={t('ActivePollSettings.DeleteConfirmationModal.DeleteKeyword')} ref={inputRef} />
        {error && (
          <Text color="red" size="sm">
            {error}
          </Text>
        )}
        <Group position="right">
          <Button color="red" onClick={onConfirmClick}>
            {t('ActivePollSettings.DeleteConfirmationModal.Confirm')}
          </Button>
          <Button color="gray" onClick={onClose}>
            {t('ActivePollSettings.DeleteConfirmationModal.Cancel')}
          </Button>
        </Group>
      </Stack>
    </Modal>
  ) : null;
}
