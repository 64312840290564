import React from 'react';
import { Global, MantineProvider, MantineTheme, MantineThemeOverride, Tuple } from '@mantine/core';
import { CSSObject } from '@emotion/react';

import light from '../../fonts/Inter-Light.woff2';
import regular from '../../fonts/Inter-Regular.woff2';
import medium from '../../fonts/Inter-Medium.woff2';
import semiBold from '../../fonts/Inter-SemiBold.woff2';
import bold from '../../fonts/Inter-Bold.woff2';

const customFonts: CSSObject[] = [
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${light}') format("woff2")`,
      fontWeight: 300,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${regular}') format("woff2")`,
      fontWeight: 400,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${medium}') format("woff2")`,
      fontWeight: 500,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${semiBold}') format("woff2")`,
      fontWeight: 600,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${bold}') format("woff2")`,
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
];

function CustomFonts() {
  return <Global styles={customFonts} />;
}

type GlobalStylesT = (theme: MantineTheme) => CSSObject;

export const blueColor: Tuple<string, 10> = [
  '#56BDFF',
  '#86DFEF',
  '#518BF2',
  '#B286F8',
  '#D6DFEE',
  '#A1E0E5',
  '#228BE6',
  '',
  '',
  '',
];
export const whiteColor: Tuple<string, 10> = [
  '#FFFFFF',
  '#BEBEBE',
  '#E7E7E7',
  '#B2C2C5',
  '#8B8B8B',
  '#CBCCCF',
  '#B8C1CC',
  '#DDDDDD',
  '',
  '',
];
export const redColor: Tuple<string, 10> = [
  '#FF7F7B',
  '#F1A4E5',
  '#E873A5',
  '#FF966D',
  '#C5322A',
  '',
  '#F03E3E',
  '',
  '',
  '',
];
export const yellowColor: Tuple<string, 10> = ['#FFC457', '#DDBFA0', '#FBF1CD', '#FFBE22', '', '', '', '', '', ''];
export const greenColor: Tuple<string, 10> = [
  '#6BCC8E',
  '#54B071',
  '#47944B',
  '',
  '',
  '',
  '#47944B',
  '#0A8E61',
  '',
  '',
];

const getAllColor = (colors: Array<Array<string>>) => {
  const result: Array<string> = [];
  for (let i = 0; i < 10; i += 1) {
    colors.forEach((item) => {
      result.push(item[i]);
    });
  }
  return result.filter((item) => !!item);
};

export const colorsSet = getAllColor([blueColor, redColor, yellowColor, greenColor]);

export const colorsMap: { [key: string]: string } = { null: '#A6A9AE' };

const globalStyles: GlobalStylesT = (theme: MantineTheme) => ({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
  },

  '.card': {
    backgroundColor: theme.colors['light-gray'][2],
    padding: '20px 24px 24px 24px',
    border: `1px solid ${theme.black}`,
    borderRadius: theme.radius.md,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      padding: theme.spacing.sm,
    },
  },
});

function GlobalStyles() {
  return <Global styles={globalStyles} />;
}

const theme: MantineThemeOverride = {
  fontFamily: 'Inter',
  lineHeight: 1.2,

  black: '#181818',
  white: '#F4F4F4',

  // Determines whether elements that do not have pointer cursor by default
  // (checkboxes, radio, native select) should have it
  cursorType: 'pointer',

  // По умолчанию (при записи типа theme.colors.blue) выбирается оттенок по индексу 0, чтобы не поломать уже настроенные цвета, добавляем оттенки не изменяя текущий порядок в массиве
  colors: {
    blue: [...blueColor],
    red: redColor,
    yellow: yellowColor,
    green: greenColor,
    white: [...whiteColor],
    black: ['#000000'],
    'light-gray': ['#E7E7E7', '#DFDFE2', '#F9FAFB', '#E9E9E9', '#7A7A7A', '#C3C3C3', '#898989', '#D9D9D9'],
  },

  // Font sizes in px, other units are not supported
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 20,
    xl: 24,
  },
  radius: {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 20,
  },
  spacing: {
    xs: 8,
    sm: 12,
    md: 16,
    lg: 20,
    xl: 42,
  },

  primaryColor: 'black',

  headings: {
    // properties for all headings
    fontWeight: 400,
    fontFamily: 'Inter',

    // properties for individual headings, all of them are optional
    sizes: {
      h1: { fontWeight: 500, fontSize: 40, lineHeight: 1.2 },
      h2: { fontWeight: 600, fontSize: 20, lineHeight: 1.2 },
      h3: { fontWeight: 500, fontSize: 18, lineHeight: 1.2 },
      h4: { fontWeight: 500, fontSize: 16, lineHeight: 1.2 },
    },
  },
};

export default function StylesProvider({ children }: { children: React.ReactNode }) {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <CustomFonts />
      <GlobalStyles />
      {children}
    </MantineProvider>
  );
}
