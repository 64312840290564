import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  button: {
    whiteSpace: 'nowrap',
    textDecoration: 'underline dotted',
    fontWeight: 'normal',
    '.icon': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: '-0.9em',
      display: 'none',
    },
    '&.sorted, &:hover': {
      '.icon': {
        display: 'block',
      },
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      '.icon': {
        display: 'block',
        position: 'relative',
        right: 0,
      },
      '.iconRight': {
        position: 'relative',
        bottom: 0,
        right: '0.5em',
      },
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
