import React from 'react';
import { Button, Group, Modal, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface Props {
  opened: boolean;
  close: () => void;
}

export default function ErrorModal({ opened, close }: Props) {
  const { t } = useTranslation();

  return (
    <Modal opened={opened} onClose={close} centered withCloseButton={false}>
      <>
        <Text weight="bold">{t('ActivePollSettings.ErrorModal.Header')}</Text>
        <Text size="sm">{t('ActivePollSettings.ErrorModal.Text')}</Text>
        <Group position="center" grow mt="10px">
          <Button variant="outline" color="gray" onClick={close}>
            {t('ActivePollSettings.ErrorModal.Cancel')}
          </Button>
        </Group>
      </>
    </Modal>
  );
}
