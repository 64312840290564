export function getFormattedDate(dateToFormat: string | Date) {
  const date = new Date(dateToFormat);

  return date.toLocaleDateString('en-US', { day: 'numeric', year: 'numeric', month: 'short' });
}

export function getDateInMonthYearFormat(dateToFormat: string | Date) {
  const date = new Date(dateToFormat);

  return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
}
