import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { quokkaPostProcessor } from './quokkaPostProcessor';
import * as en from './en.json';
import formatFunction from './localization.helpers';

/*
  To add variable into template, use prefix {{ and suffix }} into value in translate dictionary.
  For example { AnyKeyToTranslate: "Your number is {{count}}" }
  Give this variable into t-function in component: t("AnyKeyToTranslate", {count: 42})
  and i18 replace it to "Your number is 42".

  For more information about plural rules, see  https://www.i18next.com/translation-function/plurals
  For example en.json have simple rule for plurals, can test it: t("examplePlurals", {count: 0, 1 or any another number}).
*/

i18n
  .use(quokkaPostProcessor)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // print i18n config to console
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      prefix: '{{',
      suffix: '}}',
      format: formatFunction,
    },
    postProcess: 'quokkaProcessor',
    resources: {
      en: {
        translation: en,
      },
    },
  });

export default i18n;
