import React from 'react';
import { Stack } from '@mantine/core';

import ScheduleSettingsForm from '../ScheduleSettingsForm';
import CustomLoader from '../CustomLoader/CustomLoader';
import useGetScheduleSettings from '../../queries/useGetScheduleSettings';
import useGetSettingsChannels from '../../queries/useGetSettingsChannels';
import useGetPollProgress from '../../queries/useGetPollProgress';
import ActivePollSettings from '../ActivePollSettings';
import useGetSettingsActivePoll from '../../queries/useGetSettingsActivePoll';

import BotUninstalledSettingsBody from './SettingsBody.BotUninstalled';

export default function SurveySettingsTab() {
  const pollProgress = useGetPollProgress();
  const activePoll = useGetSettingsActivePoll();
  const scheduleSettings = useGetScheduleSettings(pollProgress.data?.code !== 'success');
  const channels = useGetSettingsChannels(pollProgress.data?.code !== 'success');

  if (scheduleSettings.isLoading || channels.isLoading || pollProgress.isLoading || activePoll.isLoading) {
    return <CustomLoader />;
  }

  if (
    (!scheduleSettings.isIdle && !scheduleSettings.isSuccess) ||
    (!channels.isIdle && !channels.isSuccess) ||
    !pollProgress.isSuccess ||
    !activePoll.isSuccess
  ) {
    throw (
      scheduleSettings.error ?? channels.error ?? pollProgress.error ?? activePoll.error ?? new Error('Unknown error')
    );
  }

  if (pollProgress.data?.code === 'success' && activePoll.data) {
    return (
      <ActivePollSettings
        pollProgress={pollProgress.data.value}
        pollProgressLoading={pollProgress.isLoading || pollProgress.isRefetching}
        updatePollProgress={pollProgress.refetch}
        activePoll={activePoll.data}
      />
    );
  }

  if (scheduleSettings.isIdle || channels.isIdle) {
    throw new Error('Unreachable');
  }

  if (scheduleSettings.data.code === 'bot_uninstalled' || channels.data.code === 'bot_uninstalled') {
    return <BotUninstalledSettingsBody />;
  }

  return (
    <Stack spacing="lg">
      <ScheduleSettingsForm scheduleSettings={scheduleSettings.data.value} channels={channels.data.value} />
    </Stack>
  );
}
