import React, { Children, ReactNode, useMemo } from 'react';
import { Carousel as MantineCarousel } from '@mantine/carousel';

import useStyles from './Carousel.style';

interface CarouselProps {
  rootClassName?: string;
  children: ReactNode;
}

function Carousel({ rootClassName, children }: CarouselProps) {
  const { classes } = useStyles();

  const carouselClasses = useMemo(
    () => ({
      control: classes.carouselControl,
      indicator: classes.carouselIndicator,
      root: rootClassName,
    }),
    [classes, rootClassName],
  );

  return (
    <MantineCarousel
      align="start"
      controlsOffset="xs"
      controlSize={32}
      classNames={carouselClasses}
      includeGapInSize={false}
      slideGap="xl"
      withIndicators
    >
      {Children.map(children, (child) => (
        <MantineCarousel.Slide>{child}</MantineCarousel.Slide>
      ))}
    </MantineCarousel>
  );
}

Carousel.defaultProps = {
  rootClassName: '',
};

export default Carousel;
