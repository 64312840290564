import { useQuery } from 'react-query';

import axios from '../axios';
import { IApiResponse, IPollDto } from '../types';

const useGetSettingsPollsList = (limit: number) =>
  useQuery<IPollDto[], Error>(
    'settings/polls',
    async () => {
      const response = await axios.get<unknown, IApiResponse<IPollDto[]>>(
        `/gateway/tilly/settings/polls?limit=${limit}`,
      );
      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetSettingsPollsList;
