import React, { useMemo } from 'react';
import { Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Plan, Predictor } from '../../statistics/types/enums';
import useGetWorkspaceInfo from '../../statistics/queries/useGetWorkspaceInfo';
import { usePaymentInfoModal } from '../../globalStates/PaymentInfoModal';
import { usePredictor } from '../../globalStates/Predictor';

import useStyles from './PredictorSelect.style';

export default function PredictorSelect() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [, setOpened] = usePaymentInfoModal();
  const [{ predictor, showNegative, showPositive }, setPredictor] = usePredictor();

  const { data: workspaceInfo, isLoading: isWorkspaceInfoLoading } = useGetWorkspaceInfo();
  const isFree = workspaceInfo?.plan === Plan.Free;

  const changePredictor = React.useCallback(
    (value: Predictor | null) => {
      if (isFree) {
        setOpened(true);
      } else {
        setPredictor({ predictor: value, showNegative, showPositive });
      }
    },
    [isFree, setPredictor, setOpened, showNegative, showPositive],
  );

  const selectClasses = useMemo(
    () => ({
      dropdown: classes.dropdown,
      input: classes.input,
      item: classes.item,
      itemsWrapper: classes.itemsWrapper,
      rightSection: classes.rightSection,
      root: classes.root,
    }),
    [classes],
  );

  return (
    <Select
      placeholder={`${t('PredictorSelect.Placeholder')}`}
      data={Object.values(Predictor).map((i) => ({ value: i, label: `${t(`Predictor.${i}`)}` }))}
      classNames={selectClasses}
      value={predictor}
      onChange={changePredictor}
      dropdownPosition="bottom"
      disabled={isWorkspaceInfoLoading}
      clearable
    />
  );
}
