import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  container: {
    gap: 0,
    position: 'relative',
  },
  selectedGroupBadgeContainer: {
    position: 'absolute',
    width: '100%',
    top: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
  },
}));

export default useStyles;
