import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    flexGrow: 1,
    justifyContent: 'right',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing.xs,
    },
    flexWrap: 'nowrap',
  },
  date: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    color: theme.colors.white[4],
    lineHeight: '20px',
  },
  stats: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.white[4],
    lineHeight: '20px',
  },
}));

export default useStyles;
