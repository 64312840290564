import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  badge: {
    textTransform: 'none',
    fontWeight: 'normal',
    backgroundColor: theme.colors.blue[4],
    fontSize: '14px',
    borderRadius: '7px',
  },
}));

export default useStyles;
