import { Template, FunctionRegistry } from '@mindbox/quokka';
import { PostProcessorModule, TOptions } from 'i18next';

interface LocaleData {
  templates: Record<string, Template>;
  functions: FunctionRegistry;
}

interface PluralRule {
  plurals(count: number): number;
}

interface PluralResolver {
  getRule(language: string): PluralRule;
}

interface Translator {
  language: string;
  pluralResolver: PluralResolver;
}

const localeDataRegistry: Record<string, LocaleData> = {};

function createPluralizer(translator: Translator) {
  return (count: number, ...forms: string[]): string => {
    const formIndex = translator.pluralResolver.getRule(translator.language).plurals(count);
    const form = forms[formIndex];

    if (form == null) {
      throw new Error(`Form ${formIndex} for count ${count} is not set`);
    }

    return form;
  };
}

function getLocaleData(translator: Translator): LocaleData {
  const { language } = translator;

  if (localeDataRegistry[language] == null) {
    localeDataRegistry[language] = {
      templates: {},
      functions: {
        forms: createPluralizer(translator),
      },
    };
  }

  return localeDataRegistry[language];
}

export const quokkaPostProcessor: PostProcessorModule = {
  name: 'quokkaProcessor',
  process(value: string, key: string | string[], options: TOptions, translator: Translator): string {
    const localeData = getLocaleData(translator);

    try {
      if (localeData.templates[value] == null) {
        localeData.templates[value] = new Template(value, localeData.functions);
      }

      return localeData.templates[value].render(options);
    } catch (error) {
      if (Array.isArray(key)) {
        return key.length > 0 ? key[0] : '';
      }

      return key;
    }
  },
  type: 'postProcessor',
};

export default {};
