import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import SlackSignIn from '../../components/SlackSignIn/SlackSignIn';
import useGetMe from '../../queries/useGetMe';
import LoadingPage from '../loading';
import { ROUTES } from '../../constants';

export default function LoginPage() {
  const { data: user, isLoading } = useGetMe();

  const location = useLocation();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (user) {
    return <Navigate to={ROUTES.HOME} replace />;
  }

  return <SlackSignIn tealRedirectUrl={location.state?.fromUrl} />;
}
