import React from 'react';
import { Button, Group, Stack, Divider } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useForm, zodResolver } from '@mantine/form';
import dayjs from 'dayjs';
import { z } from 'zod';
import { useDisclosure } from '@mantine/hooks';

import { IChannelDto, IScheduleSettingsDto } from '../../types';
import useUpdateScheduleSettingsMutation from '../../queries/useUpdateScheduleSettingsMutation';
import SettingsCard from '../SettingsCard/SettingsCard';

import ScheduleIntervalInput from './ScheduleIntervalInput';
import NextDateInput from './NextDateInput';
import AudienceInput from './AudienceInput';
import { Audience } from './types';
import ChannelSelect from './ChannelSelect';
import NotificationTextInput from './NotificationTextInput';
import UsersCountInfo from './UsersCountInfo';
import ErrorModal from './ErrorModal';

interface Props {
  scheduleSettings: IScheduleSettingsDto;
  channels: IChannelDto[];
}

export default function ScheduleSettingsForm({ scheduleSettings, channels }: Props) {
  const { t } = useTranslation();
  const mutation = useUpdateScheduleSettingsMutation();
  const [errorModalOpened, { open: openErrorModal, close: closeErrorModal }] = useDisclosure(false);

  const schema = z
    .object({
      start: z
        .date()
        .refine((x) => dayjs(x).subtract(10, 'minutes').isAfter(dayjs()), {
          message: t<string>('ScheduleSettingsForm.NextDateInput.TooEarlyError'),
        })
        .or(z.null()),
      intervalWeeks: z.number().min(2).max(25).or(z.null()),
      channelId: z.string().or(z.null()),
      notificationText: z
        .string()
        .nonempty({ message: t<string>('ScheduleSettingsForm.NotificationTextInput.RequiredError') })
        .max(500),
      audience: z.enum([Audience.Channel, Audience.Workspace]),
    })
    .refine((x) => x.start !== null || x.intervalWeeks === null, {
      path: ['intervalWeeks'],
      message: 'Should be empty if start is empty',
    })
    .refine((x) => x.audience !== Audience.Channel || x.channelId !== null, {
      path: ['channelId'],
      message: t<string>('ScheduleSettingsForm.ChannelSelect.RequiredError'),
    });

  const form = useForm({
    initialValues: {
      start: scheduleSettings.start,
      intervalWeeks: scheduleSettings.interval?.asWeeks() ?? null,
      channelId: scheduleSettings.channelId,
      notificationText: scheduleSettings.notificationText,
      audience: scheduleSettings.channelId === null ? Audience.Workspace : Audience.Channel,
    },
    transformValues: (values): IScheduleSettingsDto => ({
      start: values.start,
      interval: values.intervalWeeks !== null ? dayjs.duration(values.intervalWeeks, 'weeks') : null,
      channelId: values.audience === Audience.Channel ? values.channelId : null,
      notificationText: values.notificationText,
    }),
    validate: zodResolver(schema),
  });

  const onSubmit = React.useCallback(
    (values: IScheduleSettingsDto) => {
      if (mutation.isLoading) {
        return;
      }

      mutation.mutate(values, {
        onSuccess: (response) => {
          if (response.code === 'not_configured') {
            openErrorModal();
          }
        },
      });
    },
    [mutation, openErrorModal],
  );

  const setStartDate = React.useCallback(
    (value: Date | null) => {
      if (value === null) {
        form.setFieldValue('start', null);
        form.setFieldValue('intervalWeeks', null);
        form.setFieldValue('audience', Audience.Workspace);
        form.setFieldValue('channelId', null);
        form.setFieldValue('notificationText', scheduleSettings.notificationText);
      } else {
        form.setFieldValue('start', value);
      }
    },
    [form, scheduleSettings],
  );

  const setAudience = React.useCallback(
    (value: Audience) => {
      if (value === Audience.Workspace) {
        form.setFieldValue('channelId', null);
      }

      form.setFieldValue('audience', value);
    },
    [form],
  );

  return (
    <>
      <ErrorModal
        code={mutation.data?.code}
        errors={mutation.data && 'errors' in mutation.data ? mutation.data?.errors : undefined}
        opened={errorModalOpened}
        close={closeErrorModal}
      />
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="lg">
          <SettingsCard label={t<string>('ScheduleSettingsForm.CardLabel')}>
            <NextDateInput
              date={form.getInputProps('start').value}
              setDate={setStartDate}
              error={form.getInputProps('start').error}
            />
            {form.values.start !== null && (
              <>
                <ScheduleIntervalInput
                  scheduleWeeks={form.getInputProps('intervalWeeks').value}
                  setScheduleWeeks={form.getInputProps('intervalWeeks').onChange}
                />
                <Divider my="sm" />
                <AudienceInput
                  audience={form.getInputProps('audience').value}
                  setAudience={setAudience}
                  error={form.getInputProps('audience').error}
                />
                {form.values.audience === Audience.Channel && (
                  <ChannelSelect
                    channelId={form.getInputProps('channelId').value}
                    setChannelId={form.getInputProps('channelId').onChange}
                    error={form.getInputProps('channelId').error}
                    channels={channels}
                  />
                )}
                {(form.values.audience === Audience.Workspace || form.values.channelId !== null) && (
                  <UsersCountInfo channelId={form.values.channelId} />
                )}
                <NotificationTextInput {...form.getInputProps('notificationText')} />
              </>
            )}
          </SettingsCard>
          <Group position="right">
            <Button color="green" type="submit" loading={mutation.isLoading}>
              {t('ScheduleSettingsForm.Submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
}
