import React from 'react';
import { Center, Container, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ErrorImage } from '../../images/Error.svg';
import InstallToSlack from '../InstallToSlack/InstallToSlack';

import useStyles from './SettingsBody.BotUninstalled.styles';

export default function BotUninstalledSettingsBody(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Center dir="column" className={classes.errorContainer}>
      <Container>
        <Center>
          <ErrorImage className={classes.svgImage} />
        </Center>
        <Text className={classes.oops}>{t('BotUninstalledSettingsBody.Title')}</Text>
        <Text className={classes.text}>{t('BotUninstalledSettingsBody.Text')}</Text>
        <Center>
          <InstallToSlack buttonClass={classes.btnText} />
        </Center>
      </Container>
    </Center>
  );
}
