export const settings = {
  width: 340,
  xAxisWidth: 332,
  yAxisHeight: 140,
  height: 160,
  padding: 10,
};

export type ObjectDataValues = {
  dateTitle: string;
  date: string;
  value: number;
  nullValue?: boolean;
};

export type ObjectData = {
  type: string;
  positiveValues: ObjectDataValues[];
  negativeValues: ObjectDataValues[];
  dataTitle?: string;
};
