import React from 'react';
import { ActionIcon, Badge } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconX } from '@tabler/icons-react';

import useGetPollSlices from '../../statistics/queries/useGetPollSlices';
import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import { GroupBy } from '../../statistics/types/enums';
import { useSelected } from '../../globalStates/Selected';
import { useGroupBy } from '../../globalStates/GroupBy';
import { useComparison } from '../../globalStates/Comparison';

import useStyles from './SelectedGroupBadge.styles';

export default function SelectedGroupBadge({ poll }: { poll: IPollInfo }) {
  const { t } = useTranslation();
  const { classes, theme } = useStyles();

  const [groupBy] = useGroupBy();
  const [selected, setSelected] = useSelected();
  const [, setComparison] = useComparison();

  const slices = useGetPollSlices(poll.id);

  const groupName = React.useMemo(() => {
    if (!slices.data) {
      return null;
    }

    let key: 'departments' | 'roles' | 'locations' | 'tenures';

    switch (groupBy) {
      case GroupBy.Locations:
        key = 'locations';
        break;

      case GroupBy.Roles:
        key = 'roles';
        break;

      case GroupBy.Tenures:
        key = 'tenures';
        break;

      case GroupBy.Departments:
      default:
        key = 'departments';
        break;
    }

    // eslint-disable-next-line
    // @ts-ignore
    const name = slices.data[key].find((x) => ('id' in x ? x.id.toString() === selected : x.name === selected))?.name;

    if (!name || groupBy !== GroupBy.Tenures) {
      return name;
    }

    return t(`SelectedGroupBadge.${name}`);
  }, [slices, groupBy, selected, t]);

  const clear = React.useCallback(() => {
    setComparison({ showOverall: true, showFiltered: false, comparison: [] });
    setSelected(null);
  }, [setSelected, setComparison]);

  if (slices.isLoading || !groupName) {
    return <div />;
  }

  if (slices.isError) {
    throw slices.error || new Error('Unknown error');
  }

  return (
    <Badge
      className={classes.badge}
      rightSection={
        <ActionIcon size="xs" variant="transparent" onClick={clear}>
          <IconX size={14} color={theme.colors.red[4]} />
        </ActionIcon>
      }
    >
      {t('SelectedGroupBadge', { groupBy: t(`SelectedGroupBadge.${groupBy}`), groupName })}
    </Badge>
  );
}
