import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '165px',
    padding: '3px 7px 3px 10px',
    fontSize: '20px',
    fontWeight: 'bold',
    textDecoration: 'underline dotted',
    borderRadius: '8px 8px 0 0',
    '&[aria-expanded="true"]': {
      backgroundColor: theme.colors.white[0],
    },
  },
  selectorIcon: {
    width: '16px',
    height: '16px',
    color: theme.colors['light-gray'][6],
  },
  item: {
    padding: '8px 10px',
    fontSize: '16px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.white,
    },
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    paddingTop: '10px',
    backgroundColor: theme.colors.white[0],
    borderRadius: '0 0 8px 8px',
    borderTop: 'none',
    borderColor: theme.colors.black[0],
  },
}));

export default useStyles;
