import { MantineTheme, createStyles } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    backgroundColor: theme.colors.white[0],
    padding: '0px 20px 80px 20px',
    borderRadius: '8px',
  },
  price: {
    backgroundColor: theme.colors.yellow[3],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  totalPrice: {
    fontSize: 40,
    fontWeight: 'bold',
  },
  currencySign: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: '12px',
  },
  headerCell: {
    width: '110px',
    height: '60px',
    borderRadius: '0px 0px 15px 15px',
    marginBottom: '40px',
  },
  planName: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  button: {
    position: 'absolute',
    bottom: '50px',
    borderRadius: '15px',
    width: '150px',
    color: theme.black,
  },
  freeButton: {
    border: `2px solid ${theme.colors.yellow[3]}`,
  },
  proButton: {
    backgroundColor: theme.colors.yellow[3],
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export default useStyles;
