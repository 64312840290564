import React, { useEffect } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import RelativeTime from 'dayjs/plugin/relativeTime';

import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import LoginPage from './pages/login';
import SuccessInstallPage from './pages/success-install';
import StatisticsPage from './pages/statistics';
import StylesProvider from './components/StylesProvider/StylesProvider';
import './localization/i18n';
import useGetMe from './queries/useGetMe';
import SettingsPage from './pages/settings';
import UpgradePage from './pages/upgradePlan';
import UpgradePageCancelled from './pages/upgradePlan/cancelled';
import UpgradePageSucceded from './pages/upgradePlan/succeded';
import { ROUTES } from './constants';
import EncryptedParamProvider from './globalStates/EncryptedParam';
import DefaultErrorPage from './pages/error/concrete/DefaultErrorPage';
import NotFoundErrorPage from './pages/error/concrete/NotFoundErrorPage';

dayjs.extend(RelativeTime);
dayjs.extend(Duration);

const router = createBrowserRouter(
  [
    {
      element: (
        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
        <QueryParamProvider adapter={ReactRouter6Adapter} options={{ removeDefaultsFromUrl: true }}>
          <EncryptedParamProvider>
            <Outlet />
          </EncryptedParamProvider>
        </QueryParamProvider>
      ),
      children: [
        {
          path: ROUTES.HOME,
          element: (
            <ProtectedRoute>
              <StatisticsPage />
            </ProtectedRoute>
          ),
          errorElement: <DefaultErrorPage />,
        },
        {
          path: ROUTES.LOGIN,
          element: <LoginPage />,
        },
        {
          path: ROUTES.SUCCESS_INSTALL,
          element: <SuccessInstallPage />,
        },
        {
          path: `${ROUTES.SETTINGS}/:tabValue?`,
          element: (
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          ),
          errorElement: <DefaultErrorPage />,
        },
        {
          path: ROUTES.UPGRADE,
          element: (
            <ProtectedRoute>
              <UpgradePage />
            </ProtectedRoute>
          ),
          errorElement: <DefaultErrorPage />,
        },
        {
          path: ROUTES.UPGRADE_CANCELLED,
          element: (
            <ProtectedRoute>
              <UpgradePageCancelled />
            </ProtectedRoute>
          ),
          errorElement: <DefaultErrorPage />,
        },
        {
          path: ROUTES.UPGRADE_SUCCEEDED,
          element: (
            <ProtectedRoute>
              <UpgradePageSucceded />
            </ProtectedRoute>
          ),
          errorElement: <DefaultErrorPage />,
        },
        {
          path: '*',
          element: <NotFoundErrorPage />,
        },
      ],
    },
  ],
  {
    basename: process.env.PUBLIC_URL,
  },
);

export default function App() {
  const { data: user, isLoading } = useGetMe();
  const { t } = useTranslation();

  const workspaceName = user?.['https://slack.com/team_name'];

  useEffect(() => {
    if (isLoading) {
      return;
    }

    document.addEventListener('keyup', (event) => {
      if (
        event.code === 'PrintScreen' ||
        (event.shiftKey &&
          event.metaKey &&
          (event.code === 'Digit3' || event.code === 'Digit4' || event.code === 'Digit5' || event.code === 'Digit6'))
      ) {
        amplitude.logEvent({
          event_type: 'Print Screen',
          event_properties: {
            workspace_name: workspaceName ?? 'Unknown',
          },
        });
      }
    });
  }, [isLoading, workspaceName]);

  return (
    <StylesProvider>
      <Helmet>
        <title>{t('HeadTags.Title')}</title>
        <meta name="description" content={t<string>('HeadTags.Description', { param: workspaceName })} />
      </Helmet>
      <RouterProvider router={router} />
    </StylesProvider>
  );
}
