import { useMutation } from 'react-query';

import { IApiResponse, ISlicesSettingsDto } from '../types';
import axios from '../axios';

import queryClient from './queryClient';

const useUpdateSlicesSettingsMutation = () =>
  useMutation<ISlicesSettingsDto, Error, ISlicesSettingsDto, unknown>(
    async (settings: ISlicesSettingsDto) => {
      const response = await axios.put<unknown, IApiResponse<ISlicesSettingsDto>>(
        '/gateway/tilly/settings/slices',
        settings,
      );

      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData('settings/slices', data);
      },
    },
  );

export default useUpdateSlicesSettingsMutation;
