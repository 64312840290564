import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  carouselControl: {
    display: 'none',
  },
  carouselIndicator: {
    width: '10px',
    height: '10px',
    backgroundColor: theme.colors.white[1],
    '&[data-active]': {
      backgroundColor: theme.colors.white[4],
    },
  },
}));

export default useStyles;
