import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { t } from 'i18next';
import { offset } from '@floating-ui/dom';

import { disableOnboardingStartAutomatic } from '../../helpers/onboarding';

import './styles.css';
import { addProgress, isVisible, onceHideHandlers } from './helpers';
import addMainPredictorsStep from './steps/MainPredictors';
import addOverviewTableStep from './steps/OverviewTable';
import addSortingFeaturesStep from './steps/SortingFeatures';
import addPredictorsStep from './steps/Predictors';
import addFiltersSteps from './steps/Filters';
import addShareLinkStep from './steps/ShareLink';
import addGroupBySelectSteps from './steps/GroupBySelect';
import addComparisonStep from './steps/Comparison';

const tour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: true,
    modalOverlayOpeningPadding: 3,
    modalOverlayOpeningRadius: 5,
    cancelIcon: { enabled: true },
    floatingUIOptions: {
      middleware: [offset(20)],
    },
    showOn() {
      return isVisible(this.attachTo?.element);
    },
    buttons: [
      {
        text: () => t<string>('StatisticsOnboarding.Buttons.Next'),
        action() {
          this.next();
        },
      },
    ],
    when: {
      show() {
        addProgress(this);
      },
      hide() {
        onceHideHandlers.invoke();
      },
    },
  },
});

['complete', 'cancel'].forEach((event) =>
  tour.on(event, () => {
    onceHideHandlers.invoke();
    disableOnboardingStartAutomatic();
  }),
);

addMainPredictorsStep(tour);
addOverviewTableStep(tour);
addSortingFeaturesStep(tour);
addGroupBySelectSteps(tour);
addPredictorsStep(tour);
addComparisonStep(tour);
addFiltersSteps(tour);
addShareLinkStep(tour);

export default tour;
