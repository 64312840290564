import React from 'react';
import { useQueryParams, StringParam } from 'use-query-params';

import State, { MutableState } from './State';

type ContextType = State<string | null | undefined>;

const defaultValue: ContextType = [undefined, () => {}];

const Context = React.createContext<ContextType>(defaultValue);

export const SelectedContext: ContextType = defaultValue;

export const useSelected = () => React.useContext(Context);

export default function SelectedProvider({ children }: React.PropsWithChildren) {
  const [{ selected }, setQuery] = useQueryParams({
    selected: StringParam,
  });

  const update = React.useCallback(
    (newSelected: string | null | undefined) => {
      setQuery({
        selected: newSelected,
      });
    },
    [setQuery],
  );

  (SelectedContext as MutableState<string | null | undefined>)[0] = selected;
  (SelectedContext as MutableState<string | null | undefined>)[1] = update;

  const contextValue = React.useMemo<ContextType>(() => [selected, update], [selected, update]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
