import React from 'react';
import { Group, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Predictor } from '../../statistics/types/enums';
import { usePredictor } from '../../globalStates/Predictor';

import useStyles from './PredictorButtons.style';

export default function PredictorButtons() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [{ predictor, showNegative, showPositive }, setPredictor] = usePredictor();

  const changePredictor = React.useCallback(
    (value: Predictor) => () => {
      setPredictor({ predictor: predictor !== value ? value : null, showNegative, showPositive });
    },
    [setPredictor, predictor, showNegative, showPositive],
  );

  const predictorButtons = Object.values(Predictor).map((i) => (
    <Button
      className={i === predictor ? classes.selectedPredictorButton : classes.notSelectedPredictorButton}
      key={i}
      onClick={changePredictor(i)}
      variant={i === predictor ? 'outline' : 'white'}
    >
      {t(`Predictor.${i}`)}
    </Button>
  ));

  return <Group className={classes.container}>{predictorButtons}</Group>;
}
