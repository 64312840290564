import { t } from 'i18next';
import Shepherd from 'shepherd.js';

import { ELEMENT_ID } from '../../../constants';

export default function addOverviewTableStep(tour: Shepherd.Tour) {
  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.OverviewTable.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.OverviewTable.Text'),
    attachTo: {
      element: `#${ELEMENT_ID.OVERVIEW_TABLE}`,
      on: 'left',
    },
  });
}
