import { useQuery } from 'react-query';

import { IPollInfo } from '../types/slicesAndInfo';
import { IFilter } from '../types/filter';
import axios from '../../axios';
import { IPredictorHistoryResponse } from '../types/history';
import { Predictor } from '../types/enums';
import { IApiResponse } from '../../types';

const useGetPredictorHistory = (poll: IPollInfo, predictor: Predictor, filter: IFilter, limit = 6) =>
  useQuery<IPredictorHistoryResponse, Error>(
    [`/stats/polls/${poll.id}/history/${predictor}`, filter],
    async () => {
      const response = await axios.get<unknown, IApiResponse<IPredictorHistoryResponse>>(
        `/gateway/tilly/stats/polls/${poll.id}/history/${predictor}`,
        {
          params: { filter, limit },
        },
      );

      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetPredictorHistory;
