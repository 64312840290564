import { Column, Row } from '@tanstack/react-table';

import goodMinus from '../../helpers/goodMinus';
import { IPollSlicesResponse } from '../../statistics/types/slicesAndInfo';
import { GroupBy } from '../../statistics/types/enums';

import { ITableDataRow } from './Overview.types';

export const getNumberValues = (column: Column<ITableDataRow, unknown>, rows: Row<ITableDataRow>[]) =>
  rows.map((row) => row.getValue<number | undefined>(column.id)).filter((val): val is number => val !== undefined);

export const getDifferenceValue = (value: number) => {
  if (Math.abs(value) <= Number.EPSILON) {
    return '≈';
  }

  return value > 0 ? `+${value}` : goodMinus(value);
};

export const getGroupByItems = (data: IPollSlicesResponse, isFree: boolean) => {
  const groupByItems = [];

  if (data.departments.length || isFree) {
    groupByItems.push(GroupBy.Departments);
  }

  if (data.roles.length || isFree) {
    groupByItems.push(GroupBy.Roles);
  }

  if (data.locations.length || isFree) {
    groupByItems.push(GroupBy.Locations);
  }

  groupByItems.push(GroupBy.Tenures);

  return groupByItems;
};
