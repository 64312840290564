import { t } from 'i18next';
import Shepherd from 'shepherd.js';

import { ELEMENT_CLASS } from '../../../constants';
import createOverlay from '../../../helpers/createOverlay';
import { onceHideHandlers } from '../helpers';
import { SelectedContext } from '../../../globalStates/Selected';
import waitForElement from '../../../helpers/waitForElement';
import { OVERLAY_INTERCEPTOR_CLASS } from '../constants';

const ROW_OVERLAY_ID = `${ELEMENT_CLASS.OVERVIEW_TABLE_ROW}-overlay`;
const COMPARE_BUTTON_OVERLAY_ID = `${ELEMENT_CLASS.OVERVIEW_TABLE_COMPARE_BUTTON}-overlay`;

export default function addComparisonStep(tour: Shepherd.Tour) {
  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.ComparisonRow.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.ComparisonRow.Text'),
    attachTo: {
      element: `#${ROW_OVERLAY_ID}`,
      on: 'left',
    },
    modalOverlayOpeningPadding: 0,
    showOn() {
      const rows = document.querySelectorAll<HTMLElement>(`.${ELEMENT_CLASS.OVERVIEW_TABLE_ROW}`);
      return rows.length >= 2;
    },
    beforeShowPromise() {
      const rows = document.querySelectorAll<HTMLElement>(`.${ELEMENT_CLASS.OVERVIEW_TABLE_ROW}`);

      const [, setSelected] = SelectedContext;
      setSelected(null);

      const firstRow = rows[0];

      const removeOverlay = createOverlay([firstRow], (x) => {
        Object.assign(x, {
          id: ROW_OVERLAY_ID,
          className: OVERLAY_INTERCEPTOR_CLASS,
        });
        x.addEventListener('click', () => {
          tour.next();
        });
      });

      onceHideHandlers.subscribe(() => removeOverlay());

      return Promise.resolve();
    },
  });

  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.ComparisonButton.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.ComparisonButton.Text'),
    attachTo: {
      element: `#${COMPARE_BUTTON_OVERLAY_ID}`,
      on: 'left',
    },
    modalOverlayOpeningPadding: 0,
    showOn() {
      const rows = document.querySelectorAll<HTMLElement>(`.${ELEMENT_CLASS.OVERVIEW_TABLE_ROW}`);
      return rows.length >= 2;
    },
    async beforeShowPromise() {
      const rows = document.querySelectorAll<HTMLElement>(`.${ELEMENT_CLASS.OVERVIEW_TABLE_ROW}`);

      const firstRow = rows[0];

      const selected = firstRow.getAttribute('data-group-id')!;

      const [, setSelected] = SelectedContext;
      setSelected(selected);

      const secondRow = rows[1];

      const compareButton = await waitForElement<HTMLElement>(
        `.${ELEMENT_CLASS.OVERVIEW_TABLE_COMPARE_BUTTON}`,
        secondRow,
      );

      compareButton.style.display = 'block';

      const removeOverlay = createOverlay([compareButton], (x) => {
        Object.assign(x, {
          id: COMPARE_BUTTON_OVERLAY_ID,
          className: OVERLAY_INTERCEPTOR_CLASS,
        });
        x.addEventListener('click', () => {
          tour.next();
        });
      });

      onceHideHandlers.subscribe(() => {
        removeOverlay();
        compareButton.removeAttribute('style');
        setSelected(null);
      });

      return Promise.resolve();
    },
  });
}
