import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';
import * as amplitude from '@amplitude/analytics-browser';

import App from './App';
import queryClient from './queries/queryClient';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.setTag('appName', 'Frontend');

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT || 'Development',
    release: process.env.REACT_APP_REVISION || 'Unknown',
    sampleRate: 1.0,
  });
}

if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);

// eslint-disable-next-line no-console
reportWebVitals(console.log);
