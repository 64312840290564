import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme, options: { describe?: boolean }) => ({
  unstyledButton: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
    },
  },
  container: {
    gap: theme.spacing.xs,
    width: '120px',
    height: options?.describe ? '320px' : '140px',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      margin: '0 auto',
      width: '240px',
      height: options?.describe ? '330px' : '240px',
    },
  },
  title: {
    textAlign: 'center',
  },
  caption: {
    fontSize: theme.fontSizes.sm,
    paddingLeft: '5px',
  },
  captionValue: {
    fontWeight: 600,
  },
  unknown: {
    color: theme.colors.white[4],
  },
  positive: {
    color: theme.colors.green[2],
  },
  negative: {
    color: theme.colors.red[4],
  },
  blurred: {
    userSelect: 'none',
    filter: 'blur(5px)',
  },
}));

export default useStyles;
