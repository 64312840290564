import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Flex, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '../../images/logo-black.svg';
import { ReactComponent as Check } from '../../images/icons/outline-check.svg';
import { ROUTES } from '../../constants';
import ButtonInStab from '../../components/ButtonInStab/ButtonInStab';

import useStyles from './styles';

const REDIRECT_URL = 'https://teal.inc';

export default function SuccessInstallPage() {
  const { classes } = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Container className={classes.container}>
        <Link to={ROUTES.HOME} className={classes.logoLink}>
          <Logo className={classes.logo} />
        </Link>
        <Flex className={classes.main}>
          <Check />
          <Flex className={classes.messageContainer}>
            <Text align="center" className={classes.message}>
              {t('SuccessInstallPage.Message')}
            </Text>
          </Flex>
          <a href={REDIRECT_URL}>
            <ButtonInStab text={t('SuccessInstallPage.ReturnToHomePage')} />
          </a>
        </Flex>
      </Container>
    </div>
  );
}
