import React from 'react';
import { Center, Text, Container } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import useStyles from '../../styles';
import { ReactComponent as ErrorTitle } from '../../../../images/Error.svg';
import { ROUTES } from '../../../../constants';
import useGetPollsRecent from '../../../../statistics/queries/useGetPollsRecent';
import ButtonInStub from '../../../../components/ButtonInStab/ButtonInStab';
import ErrorPage from '../..';

function DefaultErrorBody(): JSX.Element {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { classes } = useStyles();

  const error = useRouteError();

  const { data } = useGetPollsRecent();

  /* eslint-disable-next-line no-console */
  console.log(error);
  Sentry.captureException(error, {
    tags: {
      pollId: data?.polls.length ? data?.polls[0].id : 'unknown',
    },
  });

  const onClickHandler = React.useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  return (
    <Center dir="column" className={classes.errorContainer}>
      <Container>
        <Center>
          <ErrorTitle className={classes.svgImage} />
        </Center>
        <Text className={classes.oops}>{t('ErrorPage.Title')}</Text>
        <Text className={classes.text}>{t('ErrorPage.Text')}</Text>
        <Center>
          <ButtonInStub text={t('ErrorPage.ReturnButton')} onClick={onClickHandler} />
        </Center>
      </Container>
    </Center>
  );
}

export default function DefaultErrorPage() {
  return (
    <ErrorPage>
      <DefaultErrorBody />
    </ErrorPage>
  );
}
