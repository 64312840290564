import React from 'react';
import { Flex, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SlackIcon } from '../../images/slack-logo.svg';
import { ReactComponent as TealLogo } from '../../images/logo-color.svg';

import useStyles from './SlackSignIn.style';

/* eslint-disable react/require-default-props */
interface Props {
  tealRedirectUrl?: string;
}

export default function SlackSignIn({ tealRedirectUrl }: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const signInPath = `${process.env.REACT_APP_BACKEND_URL}/user/slack-login-redirect?tealRedirectUrl=${tealRedirectUrl}`;

  return (
    <Flex className={classes.wrapper}>
      <TealLogo />
      <Text className={classes.message} align="center">
        {t('SignInMessage')}
      </Text>
      <a href={signInPath} className={classes.link} rel="noreferrer" data-testid="sign-in-button">
        <SlackIcon className={classes.slackIcon} />
        {t('SignInButton')}
      </a>
    </Flex>
  );
}
