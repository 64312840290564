import React, { useCallback, useMemo } from 'react';
import { Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';

import CustomLoader from '../CustomLoader/CustomLoader';
import { ISettingsSlice } from '../../types';

interface Props {
  deletingSlice: ISettingsSlice;
  close: () => void;
  submit: () => void;
  isLoading: boolean;
  error: string | undefined;
}

export default function SliceSettingsDeleteModal({ deletingSlice, close, submit, isLoading, error }: Props) {
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    if (isLoading) {
      return;
    }

    close();
  }, [isLoading, close]);

  const onSubmit = useCallback(() => {
    if (isLoading) {
      return;
    }

    submit();
  }, [isLoading, submit]);

  const textParams = useMemo(() => ({ slice: deletingSlice.name }), [deletingSlice]);

  return (
    <Modal opened onClose={onClose} withCloseButton={false} centered>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<CustomLoader />} />

      <Text align="center" weight="bold" mb="sm">
        {t<string>('SlicesSettings.DeleteModalTitle')}
      </Text>
      <Text align="center" mb="sm">
        <Trans i18nKey="SlicesSettings.DeleteModalText" values={textParams} />
      </Text>
      <Group position="center" grow>
        <Button variant="outline" color="gray" onClick={onClose}>
          {t<string>('SlicesSettings.DeleteModalCancelButton')}
        </Button>
        <Button variant="filled" color="red" onClick={onSubmit}>
          {t<string>('SlicesSettings.DeleteModalSubmitButton')}
        </Button>
      </Group>
      {!!error && (
        <Text color="red" align="center" mt="sm">
          {error}
        </Text>
      )}
    </Modal>
  );
}
