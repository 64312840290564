import React, { PropsWithChildren } from 'react';

import PaymentInfoModalProvider from '../../globalStates/PaymentInfoModal';
import FilterProvider from '../../globalStates/Filter';
import SelectedProvider from '../../globalStates/Selected';
import ComparisonProvider from '../../globalStates/Comparison';
import GroupByProvider from '../../globalStates/GroupBy';
import PredictorProvider from '../../globalStates/Predictor';
import OpenedFiltersProvider from '../../globalStates/OpenedFilters';
import OpenedGroupByProvider from '../../globalStates/OpenedGroupBy';

export default function GlobalStateProvider({ children }: PropsWithChildren) {
  return (
    <PaymentInfoModalProvider>
      <FilterProvider>
        <SelectedProvider>
          <ComparisonProvider>
            <GroupByProvider>
              <PredictorProvider>
                <OpenedFiltersProvider>
                  <OpenedGroupByProvider>{children}</OpenedGroupByProvider>
                </OpenedFiltersProvider>
              </PredictorProvider>
            </GroupByProvider>
          </ComparisonProvider>
        </SelectedProvider>
      </FilterProvider>
    </PaymentInfoModalProvider>
  );
}
