import { GroupBy } from '../statistics/types/enums';

export default function groupByToQuery(groupBy: GroupBy) {
  let key: 'departments' | 'roles' | 'locations' | 'tenures';

  switch (groupBy) {
    case GroupBy.Locations:
      key = 'locations';
      break;

    case GroupBy.Roles:
      key = 'roles';
      break;

    case GroupBy.Tenures:
      key = 'tenures';
      break;

    case GroupBy.Departments:
    default:
      key = 'departments';
      break;
  }

  return key;
}
