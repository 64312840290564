import { createColumnHelper } from '@tanstack/react-table';

import { ISettingsSlice } from '../../types';

import { HierarchyCell, NameCell, OptionsCell } from './SliceSettingsDataTable.cells';

const columnHelper = createColumnHelper<ISettingsSlice>();

export const COLUMN_IDS = {
  ID: 'id',
  FULL_NAME: 'full-name',
  HIERARCHY: 'hierarchy',
  NAME: 'name',
  OPTIONS: 'options',
};

export const COLUMNS = [
  columnHelper.accessor((x) => x.id, {
    id: COLUMN_IDS.ID,
  }),
  columnHelper.group({
    id: COLUMN_IDS.FULL_NAME,
    columns: [
      columnHelper.display({
        id: COLUMN_IDS.HIERARCHY,
        cell: HierarchyCell,
        size: 23,
      }),
      columnHelper.accessor((x) => x.name, {
        id: COLUMN_IDS.NAME,
        cell: NameCell,
        size: 280,
      }),
    ],
    size: 23 + 280 + 2,
  }),
  columnHelper.display({
    id: COLUMN_IDS.OPTIONS,
    cell: OptionsCell,
    size: 30,
  }),
];
