import { useQuery } from 'react-query';

import axios from '../axios';
import { IApiResponse } from '../types';

interface UsersCountDto {
  count: number;
}

interface SuccessResult {
  code: 'success';
  value: UsersCountDto;
}

interface BotInstalledResult {
  code: 'bot_uninstalled';
}

const useGetSettingsUsersCount = (channelId?: string | null) =>
  useQuery<SuccessResult | BotInstalledResult, Error>(
    ['settings/users-count', channelId],
    async () => {
      const response = await axios.get<unknown, IApiResponse<UsersCountDto>>('/gateway/tilly/settings/users-count', {
        params: channelId ? { channelId } : undefined,
      });
      if (!response || response.code !== 'success' || !response.value) {
        if (response.code === 'bot_uninstalled') {
          return {
            code: 'bot_uninstalled',
          };
        }

        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return {
        code: 'success',
        value: response.value,
      };
    },
    {
      staleTime: Infinity,
      retry: true,
    },
  );

export default useGetSettingsUsersCount;
