import { RefObject } from 'react';
import * as d3 from 'd3';

import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import { Predictor } from '../../statistics/types/enums';
import { IPredictorHistoryResponse } from '../../statistics/types/history';
import { getDay, getMonth, getOriginDay, getOriginMonth, getYear } from '../Indicator/Indicator.helpers';

import { settings, ObjectData, ObjectDataValues } from './Predictor.constants';

export const createAxis = (elementRef: RefObject<HTMLDivElement>, data: ObjectData, type: string, id: string) => {
  const { height, padding, xAxisWidth } = settings;
  const svg = d3.select(elementRef.current).selectAll('svg');

  const x = d3
    .scaleBand()
    .domain(data.positiveValues.map((d) => d.dateTitle))
    .range([0, xAxisWidth]);

  const tickProps = {
    size: -5,
    padding: 8,
    sizeOuter: 0,
  };

  const xAxis = d3
    .axisBottom(x)
    .tickSize(tickProps.size)
    .tickPadding(tickProps.padding)
    .tickSizeOuter(tickProps.sizeOuter);

  const axis = svg
    .append('g')
    .attr('transform', `translate(${0},${height - padding * 2})`)
    .style('color', '#ccc')
    .attr('data-axis', true)
    .call(xAxis);

  axis.selectAll('text').style('color', '#7f7f7f');

  axis
    .selectAll('.tick')
    .nodes()
    .forEach((el, i) => {
      // const value = d3.select(el).data()[0] as string;
      const value = data.positiveValues[i].date;
      d3.select(el)
        .style('cursor', 'pointer')
        .style('font-size', '9px')
        .on('mouseover', () => {
          d3.selectAll(`[data-date="${type}/${id}/${value}"]`).attr('opacity', 1);
          d3.selectAll(`[data-dateTitle="${type}/${id}/${value}"]`).attr('opacity', 1);
          d3.selectAll(`[data-mainDateTitle="${type}/${id}/${value}"]`).attr('opacity', 0);
        })
        .on('mouseout', () => {
          d3.selectAll(`[data-date="${type}/${id}/${value}"]`).attr('opacity', 0);
          d3.selectAll(`[data-dateTitle="${type}/${id}/${value}"]`).attr('opacity', 0);
          d3.selectAll(`[data-mainDateTitle="${type}/${id}/${value}"]`).attr('opacity', 1);
        });
    });

  // add arrow marker
  svg
    .append('defs')
    .append('marker')
    .attr('id', 'arrowhead')
    .attr('refX', 5)
    .attr('refY', 5)
    .attr('markerWidth', 16)
    .attr('markerHeight', 13)
    .append('path')
    .attr('d', 'M 0 0 L 5 5 L 0 10')
    .attr('stroke', '#ccc')
    .attr('stroke-width', 1)
    .attr('fill', 'none');

  axis.select('path').attr('marker-end', 'url(#arrowhead)');
};

export const initSvg = (elementRef: RefObject<HTMLDivElement>, predictor: string, id: string, data?: ObjectData) => {
  if (!data) {
    return;
  }
  const { width, height } = settings;
  d3.select(elementRef.current)
    .append('svg')
    .attr(`data-type${predictor}-${id}`, true)
    .attr('width', width)
    .attr('height', height);

  createAxis(elementRef, data, predictor, id);
};

export const clearSvg = (elementRef: RefObject<HTMLDivElement>) => {
  d3.select(elementRef.current).select('svg').remove();
};

type LinesProps = {
  elementRef: RefObject<HTMLDivElement>;
  datasetDefault: ObjectData[];
  datasetByFilterData?: ObjectData;
  activeType: Predictor;
  activeDepartment: string | undefined | null;
  activeNegative: boolean;
  activePositive: boolean;
  positiveColor: string;
  negativeColor: string;
  id: string;
  showOverall: boolean;
  showFiltered: boolean;
};

type Points = {
  value: number;
  isPositive: boolean;
  isDefault?: boolean;
  x: number;
  y: number;
  dy?: number;
  title?: string;
  nullValue?: boolean;
}[];

export const createLines = ({
  activeDepartment,
  datasetDefault,
  datasetByFilterData,
  elementRef,
  activeNegative,
  activePositive,
  positiveColor,
  negativeColor,
  id,
  showOverall,
  showFiltered,
}: LinesProps) => {
  let activeData: ObjectData | undefined;
  const overallData = datasetDefault[0];
  if (datasetByFilterData) {
    activeData = datasetByFilterData;
  } else {
    activeData = overallData;
  }
  if (!activeData) {
    return;
  }

  const { padding, xAxisWidth, yAxisHeight } = settings;

  const svg = d3.select(elementRef.current).selectAll('svg');

  const xScalePositive = d3.scaleLinear().domain([0, activeData.positiveValues.length]).range([0, xAxisWidth]);
  const yScalePositive = d3
    .scaleLinear()
    .domain([0, 100])
    .range([yAxisHeight - 4, 4]);

  const xScaleNegative = d3.scaleLinear().domain([0, activeData.negativeValues.length]).range([0, xAxisWidth]);
  const yScaleNegative = d3
    .scaleLinear()
    .domain([0, 100])
    .range([4, yAxisHeight - 4]);

  const startD = xAxisWidth / activeData.positiveValues.length / 2;

  const negativePoints = activeData.negativeValues.map((d, i) => [
    xScaleNegative(i + 1) - startD,
    yScaleNegative(d.value),
  ]);
  const negativeAreaGenerator = d3.area();

  const positivePoints = activeData.positiveValues.map((d, i) => [
    xScalePositive(i + 1) - startD,
    yScalePositive(d.value),
  ]);
  const positiveAreaGenerator = d3.area().y0(yAxisHeight);

  const createLine = (
    active: ObjectData,
    points: number[][],
    areaGenerator: d3.Area<[number, number]>,
    isPositive: boolean,
  ) => {
    // dyTop 18 title size by font & padding
    const dyTop = -6;
    // dyBottom 18 title size by font & padding
    const dyBottom = 16;
    const heightBetweenPoints = 20;
    const activeLinePath = d3.line()(points as [number, number][]);
    const area = areaGenerator(points as [number, number][]);
    svg
      .append('g')
      .attr(`data-area${id}`, true)
      .append('path')
      .attr('d', area)
      .style('pointer-events', 'none')
      .style('opacity', 0.2)
      .attr('fill', isPositive ? positiveColor : negativeColor);

    svg
      .append('g')
      .attr(`data-activeLine${id}`, true)
      .append('path')
      .attr('d', activeLinePath)
      .attr('stroke', isPositive ? positiveColor : negativeColor)
      .attr('stroke-width', 1)
      .attr('fill', 'none');

    const activeCircles = svg.append('g').attr('data-activeCircle', true).selectAll('circles').data(points);
    // hover path
    activeCircles
      .enter()
      .append('path')
      .attr('d', (data) =>
        d3.line()([
          [data[0], data[1]],
          [data[0], yAxisHeight - padding],
        ]),
      )
      .attr('data-date', (d, i) => `${activeData?.type}/${id}/${active.positiveValues[i].date}`)
      .attr('stroke', '#ccc')
      .style('stroke-dasharray', '3, 3')
      .attr('stroke-width', 1)
      .attr('fill', 'none')
      .attr('opacity', 0);

    activeCircles
      .enter()
      .append('circle')
      .attr('data-hover', (d, i) => `${activeData?.type}/${id}/${active.positiveValues[i].date}`)
      .attr('cx', (data) => data[0])
      .attr('cy', (data) => data[1])
      .attr('r', '3.5')
      .attr('fill', isPositive ? positiveColor : negativeColor)
      .style('cursor', 'pointer')
      .on('mouseover', (e: MouseEvent) => {
        const target = e.target as SVGAElement;
        const hoverData = target.dataset.hover;
        if (hoverData) {
          d3.select(`[data-date="${hoverData}"]`).attr('opacity', 1);
          d3.selectAll(`[data-dateTitle="${hoverData}"]`).attr('opacity', 1);
          d3.selectAll(`[data-mainDateTitle="${hoverData}"]`).attr('opacity', 0);
        }
      })
      .on('mouseout', (e: MouseEvent) => {
        const target = e.target as SVGAElement;
        const hoverData = target.dataset.hover;
        if (hoverData) {
          d3.selectAll(`[data-date="${hoverData}"]`).attr('opacity', 0);
          d3.selectAll(`[data-dateTitle="${hoverData}"]`).attr('opacity', 0);
          d3.selectAll(`[data-mainDateTitle="${hoverData}"]`).attr('opacity', 1);
        }
      });

    const getYPosition = (y: number) => {
      if (y < heightBetweenPoints) {
        return y + dyBottom;
      }
      return y + dyTop;
    };

    if (!activeNegative || !activePositive) {
      activeCircles
        .enter()
        .append('text')
        .attr('data-mainDateTitle', (d, i) => `${activeData?.type}/${id}/${active.positiveValues[i].date}`)
        .attr('x', (data) => data[0])
        .attr('y', (data) => getYPosition(data[1]))
        .style('pointer-events', 'none')
        .style('font-size', '14px')
        .text((d, i) => {
          if (isPositive) {
            return activeData!.positiveValues[i].nullValue ? '?' : activeData!.positiveValues[i].value!;
          }
          return activeData!.negativeValues[i].nullValue ? '?' : activeData!.negativeValues[i].value!;
        })
        .attr('text-anchor', 'middle')
        .attr('fill', isPositive ? positiveColor : negativeColor)
        .style('stroke', '#FFFFFF')
        .style('stroke-width', '2');
      activeCircles
        .enter()
        .append('text')
        .attr('data-mainDateTitle', (d, i) => `${activeData?.type}/${id}/${active.positiveValues[i].date}`)
        .attr('x', (data) => data[0])
        .attr('y', (data) => getYPosition(data[1]))
        .style('pointer-events', 'none')
        .style('font-size', '14px')
        .text((d, i) => {
          if (isPositive) {
            return activeData!.positiveValues[i].nullValue ? '?' : activeData!.positiveValues[i].value!;
          }
          return activeData!.negativeValues[i].nullValue ? '?' : activeData!.negativeValues[i].value!;
        })
        .attr('text-anchor', 'middle')
        .attr('fill', isPositive ? positiveColor : negativeColor);
    }
    activeCircles.exit().remove();
  };

  const createOtherLine = (defaultData: ObjectData, points: number[][], isPositive: boolean) => {
    const linePath = d3.line()(points as [number, number][]);
    svg
      .append('g')
      .attr(`data-line${id}`, true)
      .append('path')
      .attr('d', linePath)
      .attr('stroke', isPositive ? positiveColor : negativeColor)
      .attr('stroke-width', 1)
      .style('opacity', 0.2)
      .attr('fill', 'none');

    const circles = svg.append('g').attr('data-circle', true).selectAll('circles').data(points);
    circles
      .enter()
      .append('circle')
      .attr('data-hover', (d, i) => `${activeData?.type}/${id}/${defaultData.positiveValues[i].date}`)
      .attr('cx', (data) => data[0])
      .attr('cy', (data) => data[1])
      .attr('r', '3.5')
      .attr('fill', isPositive ? positiveColor : negativeColor)
      .style('cursor', 'pointer')
      .style('opacity', 0.2)
      .on('mouseover', (e: MouseEvent) => {
        const target = e.target as SVGAElement;
        const hoverData = target.dataset.hover;
        if (hoverData) {
          d3.select(`[data-date="${hoverData}"]`).attr('opacity', 1);
          d3.selectAll(`[data-dateTitle="${hoverData}"]`).attr('opacity', 1);
          d3.selectAll(`[data-mainDateTitle="${hoverData}"]`).attr('opacity', 0);
        }
      })
      .on('mouseout', (e: MouseEvent) => {
        const target = e.target as SVGAElement;
        const hoverData = target.dataset.hover;
        if (hoverData) {
          d3.selectAll(`[data-date="${hoverData}"]`).attr('opacity', 0);
          d3.selectAll(`[data-dateTitle="${hoverData}"]`).attr('opacity', 0);
          d3.selectAll(`[data-mainDateTitle="${hoverData}"]`).attr('opacity', 1);
        }
      });

    circles.exit().remove();
  };

  const titlesPointsY: Record<
    string,
    {
      value: number;
      y: number;
      x: number;
      isPositive: boolean;
      isDefault?: boolean;
      title?: string;
      nullValue?: boolean;
    }[]
  > = {};

  activeData.positiveValues.forEach((d) => {
    titlesPointsY[d.date] = [];
  });

  const addTitlesPoints = (
    d: ObjectDataValues,
    points: number[][],
    i: number,
    isPositive: boolean,
    isDefault?: boolean,
    title?: string,
  ) => {
    if (activeNegative && activePositive) {
      if (d.value !== 50 || !titlesPointsY[d.date].find((titlePoint) => titlePoint.value === d.value)) {
        titlesPointsY[d.date].push({
          value: d.value,
          x: points[i][0],
          y: points[i][1],
          isPositive,
          isDefault,
          title,
          nullValue: d.nullValue,
        });
        titlesPointsY[d.date].sort((a, b) => a.y - b.y);
      } else {
        const point = titlesPointsY[d.date].find((titlePoint) => titlePoint.value === d.value);
        if (point) {
          point.isDefault = false;
        }
      }
    } else if (!titlesPointsY[d.date].find((titlePoint) => titlePoint.value === d.value)) {
      titlesPointsY[d.date].push({
        value: d.value,
        x: points[i][0],
        y: points[i][1],
        isPositive,
        isDefault,
        title,
        nullValue: d.nullValue,
      });
      titlesPointsY[d.date].sort((a, b) => a.y - b.y);
    }
  };

  if (activeNegative) {
    createLine(activeData, negativePoints, negativeAreaGenerator, false);
    activeData.negativeValues.forEach((d, i) => {
      addTitlesPoints(d, negativePoints, i, false, false, activeData?.dataTitle);
    });
  } else if (activeDepartment && activePositive) {
    datasetDefault.forEach((defaultData) => {
      if (defaultData.dataTitle === 'Overall') {
        if (showOverall) {
          const points = defaultData.positiveValues.map((d, i) => [
            xScalePositive(i + 1) - startD,
            yScalePositive(d.value),
          ]);
          createOtherLine(defaultData, points, true);
          defaultData.positiveValues.forEach((d, i) => {
            addTitlesPoints(d, points, i, true, true, defaultData.dataTitle);
          });
        }
      } else if (defaultData.dataTitle === 'Filtered') {
        if (showFiltered) {
          const points = defaultData.positiveValues.map((d, i) => [
            xScalePositive(i + 1) - startD,
            yScalePositive(d.value),
          ]);
          createOtherLine(defaultData, points, true);
          defaultData.positiveValues.forEach((d, i) => {
            addTitlesPoints(d, points, i, true, true, defaultData.dataTitle);
          });
        }
      } else {
        const points = defaultData.positiveValues.map((d, i) => [
          xScalePositive(i + 1) - startD,
          yScalePositive(d.value),
        ]);
        createOtherLine(defaultData, points, true);
        defaultData.positiveValues.forEach((d, i) => {
          addTitlesPoints(d, points, i, true, true, defaultData.dataTitle);
        });
      }
    });
  }

  if (activePositive) {
    createLine(activeData, positivePoints, positiveAreaGenerator, true);
    activeData.positiveValues.forEach((d, i) => {
      addTitlesPoints(d, positivePoints, i, true, false, activeData?.dataTitle);
    });
  } else if (activeDepartment && activeNegative) {
    datasetDefault.forEach((defaultData) => {
      if (defaultData.dataTitle === 'Overall') {
        if (showOverall) {
          const points = defaultData.negativeValues.map((d, i) => [
            xScaleNegative(i + 1) - startD,
            yScaleNegative(d.value),
          ]);
          createOtherLine(defaultData, points, false);
          defaultData.negativeValues.forEach((d, i) => {
            addTitlesPoints(d, points, i, false, true, defaultData.dataTitle);
          });
        }
      } else if (defaultData.dataTitle === 'Filtered') {
        if (showFiltered) {
          const points = defaultData.negativeValues.map((d, i) => [
            xScaleNegative(i + 1) - startD,
            yScaleNegative(d.value),
          ]);
          createOtherLine(defaultData, points, false);
          defaultData.negativeValues.forEach((d, i) => {
            addTitlesPoints(d, points, i, false, true, defaultData.dataTitle);
          });
        }
      } else {
        const points = defaultData.negativeValues.map((d, i) => [
          xScaleNegative(i + 1) - startD,
          yScaleNegative(d.value),
        ]);
        createOtherLine(defaultData, points, false);
        defaultData.negativeValues.forEach((d, i) => {
          addTitlesPoints(d, points, i, false, true, defaultData.dataTitle);
        });
      }
    });
  }

  const h = 20;
  const hmin = 20;
  const dTop = -6;
  const dBottom = 16;
  const dNextBottom = 14;
  const dNextTop = -14;

  const calcTitles = (titlesPoints: Points) => {
    for (let i = 0; i < titlesPoints.length; i += 1) {
      const p = titlesPoints[i];
      const pNext = titlesPoints[i - 1];
      const pPrev = titlesPoints[i + 1];
      if (datasetDefault.length >= 3 && (!activePositive || !activeNegative)) {
        if (p.y > hmin) {
          p.dy = p.y + dTop;
        } else if (yAxisHeight - padding - p.y > h) {
          p.dy = p.y + dBottom;
        }
      } else if (!pNext && !pPrev) {
        if (p.y > hmin) {
          p.dy = p.y + dTop;
        } else if (yAxisHeight - padding - p.y > h) {
          p.dy = p.y + dBottom;
        }
      } else if (!pNext && pPrev) {
        if (p.y > hmin) {
          p.dy = p.y + dTop;
        } else if (pPrev.y - p.y >= h) {
          p.dy = p.y + dBottom;
        } else if (titlesPoints[i + 2]) {
          const pPrev2 = titlesPoints[i + 2];
          if (pPrev2.y - pPrev.y > h) {
            p.dy = pPrev.y + dBottom;
            pPrev.dy = pPrev.y + dBottom;
          } else if (yAxisHeight - padding - pPrev2.y > h) {
            p.dy = pPrev2.y + dBottom;
            pPrev2.dy = p.dy;
          }
        } else {
          p.dy = pPrev.y + dBottom;
          pPrev.dy = p.dy;
        }
      } else if (pNext && pPrev) {
        if (p.dy && pPrev.y - p.dy > h) {
          p.dy += dNextBottom;
        } else if (p.dy && pPrev.y - p.y > h * 2) {
          p.dy += dNextBottom;
        } else if (p.y - pNext.y > h && pNext.dy && p.y - pNext.dy > h) {
          p.dy = p.y + dTop;
        } else if (!p.dy && pPrev.y - p.y > h) {
          p.dy = p.y + dBottom;
        } else if (pPrev.dy) {
          p.dy = pPrev.dy + dNextBottom;
          pPrev.dy = p.dy + dNextBottom;
        } else if (yAxisHeight - padding - pPrev.y >= h * 2) {
          p.dy = pPrev.y + dBottom;
          pPrev.dy = p.dy + dNextBottom;
        } else if (pNext.dy) {
          p.dy = pNext.dy;
          pNext.dy = p.dy + dNextTop;
        }
      } else if (pNext && !pPrev) {
        if (!p.dy) {
          if (yAxisHeight - padding - p.y <= h && p.y - pNext.y > h && pNext.dy && p.y - pNext.dy > h) {
            p.dy = p.y + dTop;
          } else if (p.y - pNext.y >= h && pNext.dy && p.y - pNext.dy > h) {
            p.dy = p.y + dTop;
          } else if (yAxisHeight - padding - p.y > h) {
            p.dy = p.y + dBottom;
          } else if (pNext.dy && p.y - pNext.dy > dBottom * 2 && p.y - pNext.y >= h) {
            p.dy = p.y + dTop;
          } else if (p.y + dBottom < yAxisHeight - padding) {
            p.dy = p.y + dBottom;
          } else if (titlesPoints[i - 2]) {
            const pNext2 = titlesPoints[i - 2];
            if (pNext2.dy && p.y - pNext.y >= h) {
              const { dy } = pNext2;
              pNext2.dy += dNextTop;
              p.dy = p.y + dTop;
              pNext.dy = dy;
            } else if (pNext.dy && pNext.y - pNext2.y > h * 2) {
              p.dy = pNext.dy;
              pNext.dy = p.dy + dNextTop;
            } else if (pNext2.dy) {
              const { dy } = pNext2;
              pNext2.dy += dNextTop;
              p.dy = pNext.dy;
              pNext.dy = dy;
            }
          }
        } else if (p.dy && titlesPoints.length === 2) {
          p.dy += dNextBottom;
        }
      }
    }
  };

  const titles = svg.append('g').attr(`data-otherTitle${id}`, true);
  activeData.positiveValues.forEach((d, index) => {
    const titlesPoints: Points = titlesPointsY[d.date].map((p) => ({
      value: p.value,
      isPositive: p.isPositive,
      isDefault: p.isDefault,
      x: p.x,
      y: p.y,
      dy: undefined,
      title: p.title,
      nullValue: p.nullValue,
    }));
    titlesPoints.sort((a, b) => a.y - b.y);
    calcTitles(titlesPoints);

    for (let j = 0; j < titlesPoints.length; j += 1) {
      const p = titlesPoints[j];
      const py = p.dy ? p.dy : p.y;
      let color = '';
      if (p.isPositive) {
        color = positiveColor;
      } else {
        color = negativeColor;
      }

      if (activeNegative && activePositive) {
        titles
          .append('text')
          .attr('x', p.x)
          .attr('y', py)
          .text(p.nullValue ? '?' : p.value)
          .style('pointer-events', 'none')
          .style('font-size', '14px')
          .attr('text-anchor', 'middle')
          .style('stroke', '#FFFFFF')
          .style('stroke-width', '2')
          .attr('fill', color)
          .attr('opacity', 1);
        // .attr('data-dateTitle', `${activeData?.type}/${d.date}`);

        titles
          .append('text')
          .attr('x', p.x)
          .attr('y', py)
          .text(p.nullValue ? '?' : p.value)
          .style('pointer-events', 'none')
          .style('font-size', '14px')
          .attr('text-anchor', 'middle')
          .attr('fill', color)
          .attr('opacity', 1);
        // .attr('data-dateTitle', `${activeData?.type}/${d.date}`);
      } else {
        titles
          .append('text')
          .attr('x', p.x)
          .attr('y', py)
          .text(p.nullValue ? '?' : p.value)
          .style('pointer-events', 'none')
          .style('font-size', '14px')
          .attr('text-anchor', 'middle')
          .style('stroke', '#FFFFFF')
          .style('stroke-width', '2')
          .attr('fill', color)
          .attr('opacity', 0)
          .attr('data-dateTitle', `${activeData?.type}/${id}/${d.date}`);

        titles
          .append('text')
          .attr('x', p.x)
          .attr('y', py)
          .text(p.nullValue ? '?' : p.value)
          .style('pointer-events', 'none')
          .style('font-size', '14px')
          .attr('text-anchor', 'middle')
          .attr('fill', color)
          .attr('opacity', 0)
          .attr('data-dateTitle', `${activeData?.type}/${id}/${d.date}`);
      }

      if (activeData && index === activeData.positiveValues.length - 1) {
        const dataTitle = p.title ? p.title : 'Overall';
        const textTitle = dataTitle.length > 7 ? `${dataTitle.substring(0, 7)}...` : dataTitle;
        const title = d3
          .select(elementRef.current)
          .append('span')
          .attr(`data-activeTitle${id}`, true)
          .style('position', 'absolute')
          .style('font-size', '14px')
          .style('color', p.isPositive ? positiveColor : negativeColor)
          .style('top', `${py - 13}px`)
          .style('left', `${p.x + 12}px`)
          .style('white-space', 'nowrap')
          .style('cursor', 'pointer');
        title.text(textTitle);
        if (p.isDefault) {
          title.style('color', p.isPositive ? positiveColor : negativeColor);
          title.style('opacity', 0.2);
        }
        if (dataTitle.length > 7) {
          const titleElement = title
            .append('span')
            .style('position', 'absolute')
            .style('font-size', '14px')
            .style('color', p.isPositive ? positiveColor : negativeColor)
            .style('top', `0px`)
            .style('left', `0px`)
            .style('opacity', 0)
            .style('z-index', 100)
            .style('white-space', 'nowrap')
            .style('pointer-events', 'none')
            .text(dataTitle);

          title.on('mouseover', () => {
            titleElement.style('opacity', 1);
            title.style('color', 'transparent');
          });
          title.on('mouseout', () => {
            titleElement.style('opacity', 0);
            title.style('color', p.isPositive ? positiveColor : negativeColor);
          });
        }
      }
    }
  });
};

export const clearLines = (type: string) => {
  d3.select(`[data-activeLine${type}]`).remove();
  d3.select(`[data-activeCircle${type}]`).remove();
  d3.selectAll(`[data-activeTitle${type}]`).remove();
  d3.selectAll(`[data-otherTitle${type}]`).remove();
  d3.selectAll(`[data-line${type}]`).remove();
  d3.selectAll(`[data-circle${type}]`).remove();
  d3.selectAll(`[data-title${type}]`).remove();
};

export const getData = (
  polls: IPollInfo[],
  data: IPredictorHistoryResponse | undefined,
  type: string,
  dataTitle?: string,
) => {
  if (data) {
    let idDayTitle = false;
    const monthNames = polls.map((p) => {
      const date = new Date(p.startedAt);
      return getOriginMonth(date);
    });
    monthNames.forEach((m, i) => {
      if (monthNames.findIndex((month) => month === m) !== i) {
        idDayTitle = true;
      }
    });
    const positiveValues = data.data
      .map((d) => {
        const poll = polls.find((p) => p.id === d.pollId);
        if (poll) {
          const date = new Date(poll.startedAt);
          let dateTitle = `${getOriginMonth(date)}, ${date.getFullYear()}`;
          if (idDayTitle) {
            dateTitle = `${getOriginMonth(date)} ${getOriginDay(date)}, ${date.getFullYear()}`;
          }
          const dateValue = `${getDay(date)}/${getMonth(date)}/${getYear(date)}`;
          const positiveValue = d.positive;
          let positiveValueData = 0;
          if (positiveValue) {
            positiveValueData = Math.round(positiveValue * 100);
          }
          return { date: dateValue, dateTitle, value: positiveValueData, nullValue: d.positive === null };
        }
        return [] as ObjectData[];
      })
      .reverse()
      .filter(Boolean) as ObjectDataValues[];
    const negativeValues = data.data
      .map((d) => {
        const poll = polls.find((p) => p.id === d.pollId);
        if (poll) {
          const date = new Date(poll.startedAt);
          let dateTitle = `${getOriginMonth(date)} ${date.getFullYear()}`;
          if (idDayTitle) {
            dateTitle = `${getOriginMonth(date)} ${getOriginDay(date)} ${date.getFullYear()}`;
          }
          const dateValue = `${getDay(date)}/${getMonth(date)}/${getYear(date)}`;
          const negativeValue = d.negative;
          let negativeValueData = 0;
          if (negativeValue) {
            negativeValueData = Math.round(negativeValue * 100);
          }
          return { date: dateValue, dateTitle, value: negativeValueData, nullValue: d.negative === null };
        }
        return undefined;
      })
      .reverse()
      .filter(Boolean) as ObjectDataValues[];
    return [{ type, positiveValues, negativeValues, dataTitle }] as ObjectData[];
  }
  return [] as ObjectData[];
};
