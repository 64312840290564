import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    position: 'relative',
    margin: '-15px 0 -15px -11px',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      margin: '-15px auto',
    },
  },
  negativeRing: {
    position: 'absolute',
    top: '5px',
    left: '5px',
    transform: 'scaleX(-1)',
  },
  ringLabel: {
    gap: 0,
  },
  value: {
    fontWeight: 700,
    fontSize: theme.fontSizes.lg,
    lineHeight: 1,
    textAlign: 'center',
  },
  difference: {
    fontSize: theme.fontSizes.sm,
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'center',
  },
  questionContainer: {
    width: '120px',
    height: '120px',
  },
  question: {
    fontSize: '42px',
    fontWeight: 700,
  },
}));

export default useStyles;
