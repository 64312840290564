import React, { useCallback } from 'react';
import {
  createRow,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowSelectionState,
  TableState,
  Updater,
  useReactTable,
  Cell,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Flex } from '@mantine/core';

import { GroupBy, Tenure } from '../../../statistics/types/enums';
import { useSelected } from '../../../globalStates/Selected';
import { useComparison } from '../../../globalStates/Comparison';
import { useGroupBy } from '../../../globalStates/GroupBy';

import COLUMN_DEF from './FilterTable.column-defs';
import { ITableDataRow } from './FilterTable.types';
import { COLUMN_ID, ROW_ID } from './FilterTable.constants';
import useStyles from './FilterTable.style';

interface IProps {
  rows: ITableDataRow[];
  showHierarchy: boolean;
  slices: string[];
  setSlices: (x: string[]) => void;
}

export default function FilterTable({ rows, showHierarchy, slices, setSlices }: IProps) {
  const { t } = useTranslation();
  const { type } = rows[0];
  const { classes } = useStyles();

  const [selected, setSelected] = useSelected();
  const [comparison, setComparison] = useComparison();
  const [groupBy] = useGroupBy();

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const sorting = React.useMemo(() => {
    if (type === GroupBy.Tenures) {
      return [
        {
          id: COLUMN_ID.NAME,
          desc: false,
        },
      ];
    }

    return [
      {
        id: COLUMN_ID.PEOPLE_COUNT,
        desc: true,
      },
    ];
  }, [type]);

  const state = React.useMemo<Partial<TableState>>(() => {
    const rowSelection: Record<string, boolean> = {};

    slices.forEach((s) => {
      rowSelection[s] = true;
    });

    return {
      expanded,
      rowSelection,
      sorting,
    };
  }, [expanded, slices, sorting]);

  const onRowSelectionChanged: OnChangeFn<RowSelectionState> = (updater: Updater<RowSelectionState>) => {
    // eslint-disable-next-line
    // @ts-ignore
    const newRowSelection = updater(state.rowSelection);
    const newSlices = Object.keys(newRowSelection).filter((e) => newRowSelection[e] === true);

    function getNewSelected() {
      if (type !== groupBy || newSlices.some((s) => s === selected) || newSlices.length === 0) {
        return selected;
      }

      return null;
    }

    function getNewComparison() {
      if (type !== groupBy || newSlices.length === 0) {
        return comparison.comparison;
      }

      return newSlices.filter((el) => comparison.comparison.map((comp) => comp.toString()).includes(el)) as
        | number[]
        | Tenure[];
    }

    const newSelected = getNewSelected();

    setSlices(newSlices);

    setComparison({
      ...comparison,
      comparison: newSelected ? getNewComparison() : [],
    });

    setSelected(newSelected);
  };

  const table = useReactTable<ITableDataRow>({
    data: rows,
    columns: COLUMN_DEF,
    onExpandedChange: setExpanded,
    onRowSelectionChange: onRowSelectionChanged,
    getRowId: (row) => row.id,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state,
  });

  const rowPlaceholder = t(`Filter.${type}Placeholder`);

  const mainRow: ITableDataRow = {
    ppl: rows.map((row) => row.ppl).reduce((pr, acc) => pr + acc),
    type,
    name: rowPlaceholder,
    id: ROW_ID.MAIN,
  };

  const getElementByCell = useCallback(
    (cell: Cell<ITableDataRow, unknown>) => {
      if (cell.column.id === COLUMN_ID.HIERARCHY) {
        return (
          <Flex key={cell.id} w={showHierarchy ? cell.column.getSize() : 10} h={30} align="center">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Flex>
        );
      }

      return (
        <Flex key={cell.id} w={cell.column.getSize()} h={30} align="center">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Flex>
      );
    },
    [showHierarchy],
  );

  return (
    <>
      <Flex h={30}>
        {createRow(table, mainRow.id, mainRow, 0, 0, undefined, undefined)
          .getVisibleCells()
          .map((cell) => getElementByCell(cell))}
      </Flex>
      {table.getRowModel().rows.map((row) => (
        <Flex key={row.id} className={classes.row} align="center">
          {row.getVisibleCells().map((cell) => getElementByCell(cell))}
        </Flex>
      ))}
    </>
  );
}
