import React from 'react';
import { Stack } from '@mantine/core';

import { Tenure } from '../../../statistics/types/enums';
import { IOverallOverview } from '../../../statistics/types/overview';
import PredictorCards from '../../PredictorCards/PredictorCards';
import ComparisonBadge from '../ComparisonBadge/ComparisonBadge';

type Props = {
  id: number | Tenure;
  isLoading: boolean;
  data: IOverallOverview | undefined;
  isFirstPoll: boolean;
  name: string | null | undefined;
};

export default function ComparisonPredictorCards({ id, isLoading, data, isFirstPoll, name }: Props) {
  return (
    <Stack spacing="sm" mt="lg" align="center">
      <ComparisonBadge comparisonId={id} name={name} />
      <PredictorCards isOverallOverviewLoading={isLoading} overallOverview={data} isFirstPoll={isFirstPoll} />
    </Stack>
  );
}
