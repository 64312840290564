import React from 'react';
import { Text, DefaultMantineColor, Flex } from '@mantine/core';

import goodMinus from '../../helpers/goodMinus';

interface Props {
  color: DefaultMantineColor;
  offsetMinutes: number;
}

export default function TimeZoneIcon({ color, offsetMinutes }: Props) {
  const str = React.useMemo(() => {
    const hours = -(offsetMinutes / 60);
    return hours > 0 ? `+${hours}` : goodMinus(hours);
  }, [offsetMinutes]);

  return (
    <Flex direction="column">
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <Text color={color} size={10} align="center" weight="bold">
        UTC
      </Text>
      <Text color={color} size={14} align="center" weight="bold">
        {str}
      </Text>
    </Flex>
  );
}
