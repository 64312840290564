import React from 'react';
import { Radio } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Audience } from './types';

interface Props {
  audience: Audience;
  setAudience: (x: Audience) => void;
  error: string | null;
}

export default function AudienceInput({ audience, setAudience, error }: Props) {
  const { t } = useTranslation();

  return (
    <Radio.Group
      value={audience}
      onChange={setAudience}
      error={error}
      name="audience"
      orientation="vertical"
      label={t('ScheduleSettingsForm.AudienceInput.Label')}
      withAsterisk
    >
      <Radio color="violet" value={Audience.Workspace} label={t('ScheduleSettingsForm.AudienceInput.Workspace')} />
      <Radio color="violet" value={Audience.Channel} label={t('ScheduleSettingsForm.AudienceInput.Channel')} />
    </Radio.Group>
  );
}
