import { useQuery } from 'react-query';

import { IPollsRecentResponse } from '../types/slicesAndInfo';
import axios from '../../axios';

const useGetPollsRecent = (limit = 6) =>
  useQuery<IPollsRecentResponse, Error>(
    [`/stats/polls/recent`, limit],
    () =>
      axios.get('/gateway/tilly/stats/polls/recent', {
        params: {
          limit,
        },
      }),
    {
      staleTime: Infinity,
      retry: 2,
    },
  );

export default useGetPollsRecent;
