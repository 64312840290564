import { useMutation } from 'react-query';

import { IApiResponse, IUserSettingsDto } from '../types';
import axios from '../axios';

import queryClient from './queryClient';

const useUpdateUserSettings = () =>
  useMutation<IUserSettingsDto, Error, IUserSettingsDto, unknown>(
    async (settings: IUserSettingsDto) => {
      const response = await axios.post<unknown, IApiResponse<IUserSettingsDto>>(
        '/gateway/tilly/user-settings',
        settings,
      );

      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData('user-settings', data);
      },
    },
  );

export default useUpdateUserSettings;
