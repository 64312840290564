import React from 'react';
import { Button, Text } from '@mantine/core';

import useStyles from './ButtonInStab.style';

export default function ButtonInStab({
  text,
  onClick,
}: {
  text: string;
  onClick?: (e?: React.SyntheticEvent) => void;
}) {
  const { classes } = useStyles();

  return (
    <Button size="md" variant="outline" classNames={classes} onClick={onClick}>
      <Text className={classes.btnText}>{text}</Text>
    </Button>
  );
}
