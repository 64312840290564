import React, { useCallback, useMemo, useRef } from 'react';
import { Select, SelectItem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

import useStyles from './ChannelsSelector.style';

type Props = {
  items: SelectItem[];
  description?: string;
  placeholder: string;
  label?: string | JSX.Element;
  limit: number;
  value: string | null;
  onChange?: (value: string) => void;
  error?: string | null;
  id?: string;
};

export default function ChannelsSelector({
  items,
  description,
  placeholder,
  label,
  limit,
  value,
  onChange,
  error,
  id,
}: Props) {
  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const onItemSelected = useCallback(
    (newValue: string) => {
      if (onChange) onChange(newValue);
    },
    [onChange],
  );

  const onSelectorKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const selectChannelsClasses = useMemo(
    () => ({
      item: classes.selectItem,
      input: classes.selectInput,
      rightSection: classes.SelectRightSection,
    }),
    [classes],
  );

  return (
    <Select
      hoverOnSearchChange
      description={description}
      ref={inputRef}
      id={id}
      label={label}
      dropdownPosition="bottom"
      value={value}
      placeholder={placeholder}
      maxDropdownHeight={250}
      classNames={selectChannelsClasses}
      data={items}
      onChange={onItemSelected}
      onKeyDown={onSelectorKeyDown}
      error={error}
      limit={limit}
      rightSection={<IconChevronDown size={14} />}
      searchable
      withinPortal
      withAsterisk
      // eslint-disable-next-line
      labelProps={{ mb: 'xs' }}
    />
  );
}
