import { useQuery } from 'react-query';

import axios from '../axios';
import { AccessRole } from '../types';

const useGetAccessRole = () =>
  useQuery<AccessRole, Error>(
    'access role',
    () =>
      axios
        .get('/gateway/tilly/access-roles/whoami')
        /* eslint-disable @typescript-eslint/no-explicit-any */
        .then((r: any) => r.role),
    {
      staleTime: Infinity,
      retry: 2,
    },
  );

export default useGetAccessRole;
