import React from 'react';
import { Button, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout/Layout';
import Header from '../../components/Header/Header';
import SettingsBody from '../../components/SettingsBody/SettingsBody';
import useGetAccessRole from '../../queries/useGetAccessRole';
import LoadingPage from '../loading';
import LogoutPopover from '../../components/Logout/LogoutPopover';
import Footer from '../../components/Footer/Footer';
import AccessDeniedErrorPage from '../error/concrete/AccessDeniedErrorPage';
import { ROUTES } from '../../constants';

import useStyles from './styles';

export default function SettingsPage(): JSX.Element {
  const { isLoading, data: role, isSuccess, error } = useGetAccessRole();
  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isSuccess) {
    throw error ?? new Error('Unknown error');
  }

  if (role !== 'Admin') {
    return <AccessDeniedErrorPage />;
  }

  return (
    <Layout
      header={
        <Header
          firstRow={
            <div>
              <Button variant="white" px={12} className={classes.settings} component={Link} to={ROUTES.HOME}>
                <Text color={theme.black} weight="normal" size={16} underline>
                  {t('Statistics')}
                </Text>
              </Button>
              <LogoutPopover logoutText="LogoutButton" />
            </div>
          }
        />
      }
      footer={<Footer />}
      body={<SettingsBody />}
    />
  );
}
