import { useQuery } from 'react-query';

import { IPollInfo } from '../types/slicesAndInfo';
import { IFilter } from '../types/filter';
import axios from '../../axios';
import { IOverallOverview } from '../types/overview';
import { IApiResponse } from '../../types';

const useGetOverallOverview = (poll: IPollInfo, filter: IFilter, enabled = true) =>
  useQuery<IOverallOverview, Error>(
    [`/stats/polls/${poll.id}/overview`, filter],
    async () => {
      const response = await axios.get<unknown, IApiResponse<IOverallOverview>>(
        `/gateway/tilly/stats/polls/${poll.id}/overview`,
        { params: { filter } },
      );

      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      retry: true,
      enabled,
    },
  );

export default useGetOverallOverview;
