import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  groupNameContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0px',
    fontSize: '16px',
  },
  hierarchyCellContainer: {
    paddingLeft: '7px',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainSlice: {
    fontWeight: 'bold',
  },
  firstOfHierarchy: {
    '&::before': {
      top: '50%',
    },
    '&::after': {
      display: 'none',
    },
  },
  hierarchy: {
    '&::before': {
      position: 'absolute',
      content: '""',
      width: '1px',
      backgroundColor: theme.colors['light-gray'][5],
      left: '57%',
      top: '0px',
      bottom: '0px',
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      height: '1px',
      top: '50%',
      left: '57%',
      right: '0px',
      backgroundColor: theme.colors['light-gray'][5],
    },
  },
  lastOfHierarchy: {
    '&::before': {
      bottom: '50%',
    },
  },
}));

export default useStyles;
