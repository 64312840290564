import { t } from 'i18next';
import Shepherd from 'shepherd.js';

import { ELEMENT_ID } from '../../../constants';

export default function addShareLinkStep(tour: Shepherd.Tour) {
  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.ShareLink.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.ShareLink.Text'),
    attachTo: {
      element: `#${ELEMENT_ID.SHARE_LINK}`,
      on: 'bottom',
    },
    modalOverlayOpeningPadding: 0,
  });
}
