import React from 'react';

import State from './State';

const Context = React.createContext<State<boolean>>([false, () => {}]);

export const usePaymentInfoModal = () => React.useContext(Context);

export default function PaymentInfoModalProvider({ children }: React.PropsWithChildren) {
  const paymentInfoModalState = React.useState(false);

  return <Context.Provider value={paymentInfoModalState}>{children}</Context.Provider>;
}
