import React, { useMemo } from 'react';
import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Checkbox, Text } from '@mantine/core';

import { GroupBy } from '../../../statistics/types/enums';
import CheckboxIcon from '../CheckboxIcon';
import HierarchicalCell from '../../HierarchicalCell/HierarchicalCell';
import LongTextTruncate from '../../LongTextTruncate/LongTextTruncate';

import { ITableDataRow } from './FilterTable.types';
import { ROW_ID } from './FilterTable.constants';
import useStyles from './FilterTable.style';

export function NameCell({ getValue, row, table }: CellContext<ITableDataRow, unknown>) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const value = getValue<string>();

  const className = useMemo(() => {
    if (row.getCanExpand()) return classes.bold;

    return '';
  }, [row, classes]);

  const localizedValue =
    row.id !== ROW_ID.MAIN && row.original.type === GroupBy.Tenures ? t(`OverviewTable.${value}`) : value;

  const checkboxClasses = React.useMemo(
    () => ({
      body: classes.checkboxBody,
      input: classes.checkboxInput,
      inner: classes.checkboxInner,
    }),
    [classes],
  );

  return (
    <>
      <Checkbox
        icon={CheckboxIcon}
        onChange={row.id === ROW_ID.MAIN ? table.getToggleAllRowsSelectedHandler() : row.getToggleSelectedHandler()}
        checked={row.id === ROW_ID.MAIN ? table.getIsAllRowsSelected() : row.getIsSelected()}
        classNames={checkboxClasses}
      />
      <LongTextTruncate text={localizedValue} maxWidth={120} className={className} />
    </>
  );
}

export function HierarchyCell({ table, row }: CellContext<ITableDataRow, unknown>) {
  return <HierarchicalCell row={row} table={table} />;
}

export function PplCell({ getValue, row }: CellContext<ITableDataRow, unknown>) {
  const { classes, cx } = useStyles();
  const className = row.getCanExpand() ? cx(classes.peopleCount, classes.bold) : classes.peopleCount;
  return <Text className={className}>{getValue<number>()}</Text>;
}
