import React, { ReactNode } from 'react';

import useStyles from './CustomSkeleton.style';

interface SkeletonProps {
  className?: string;
  children?: ReactNode;
}

function CustomSkeleton({ className, children }: SkeletonProps) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.skeleton, className)} data-testid="custom-skeleton">
      {children}
    </div>
  );
}

CustomSkeleton.defaultProps = {
  className: '',
  children: null,
};

export default CustomSkeleton;
