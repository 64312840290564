import React from 'react';
import { Box, Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import useGetUserSettings from '../../queries/useGetUserSettings';
import useUpdateUserSettings from '../../queries/useUpdateUserSettings';

export default function EmailPermissionSettings() {
  const { t } = useTranslation();

  const userSettings = useGetUserSettings();
  const updateUserSettings = useUpdateUserSettings();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (event) => {
      const { checked } = event.currentTarget;
      updateUserSettings.mutate({
        emailPermitted: checked,
      });
    },
    [updateUserSettings],
  );

  const loading = userSettings.isLoading || updateUserSettings.isLoading;

  return (
    <Box>
      <Checkbox
        disabled={loading}
        checked={userSettings.data?.emailPermitted}
        color="violet"
        label={t('EmailPermissionSettings.Label')}
        description={t('EmailPermissionSettings.Description')}
        onChange={onChange}
      />
    </Box>
  );
}
