import { useMutation } from 'react-query';

import { IApiResponse, ISettingsRole } from '../types';
import axios from '../axios';

import queryClient from './queryClient';

const useCreateSettingsRoleMutation = () =>
  useMutation<ISettingsRole, Error, Omit<ISettingsRole, 'id'>, unknown>(
    async (request) => {
      const response = await axios.post<unknown, IApiResponse<ISettingsRole>>(`/gateway/tilly/settings/roles`, request);
      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      onSuccess: (response) => {
        const roles = queryClient.getQueryData<ISettingsRole[]>('settings/roles');
        if (roles) {
          queryClient.setQueryData<ISettingsRole[]>('settings/roles', [...roles, response]);
        }
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: ['settings/roles'] });
      },
    },
  );

export default useCreateSettingsRoleMutation;
