import { RefObject } from 'react';
import { UseQueryResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';

import { IFilter } from '../../statistics/types/filter';
import { GroupBy, MainPredictorType, Tenure } from '../../statistics/types/enums';
import { IPollInfo, IPollSlicesResponse } from '../../statistics/types/slicesAndInfo';
import { IMainMetricsHistoryResponse } from '../../statistics/types/history';
import goodMinus from '../../helpers/goodMinus';

import { settings, ObjectData, ObjectDataValues } from './Indicator.constants';

export const createAxis = (elementRef: RefObject<HTMLDivElement>, data: ObjectData, type: string) => {
  const { height, padding, xAxisWidth } = settings;
  const svg = d3.select(elementRef.current).selectAll('svg');

  const x = d3
    .scaleBand()
    .domain(data.values.map((d) => d.dateTitle))
    .range([0, xAxisWidth]);

  const tickProps = {
    size: -5,
    padding: 8,
    sizeOuter: 0,
  };

  const xAxis = d3
    .axisBottom(x)
    .tickSize(tickProps.size)
    .tickPadding(tickProps.padding)
    .tickSizeOuter(tickProps.sizeOuter);

  const axis = svg
    .append('g')
    .attr('transform', `translate(${0},${height - padding * 2})`)
    .style('color', '#ccc')
    .attr('data-axis', true)
    .call(xAxis);

  axis.selectAll('text').style('color', '#7f7f7f');

  axis
    .selectAll('.tick')
    .nodes()
    .forEach((el, i) => {
      // const value = d3.select(el).data()[0] as string;
      const value = data.values[i].date;
      d3.select(el)
        .style('cursor', 'pointer')
        .style('font-size', '9px')
        .on('mouseover', () => {
          d3.select(`[data-date="${type}/${value}"]`).attr('opacity', 1);
          d3.selectAll(`[data-dateTitle="${type}/${value}"]`).attr('opacity', 1);
          d3.selectAll(`[data-mainDateTitle="${type}/${value}"]`).attr('opacity', 0);
        })
        .on('mouseout', () => {
          d3.selectAll(`[data-date="${type}/${value}"]`).attr('opacity', 0);
          d3.selectAll(`[data-dateTitle="${type}/${value}"]`).attr('opacity', 0);
          d3.selectAll(`[data-mainDateTitle="${type}/${value}"]`).attr('opacity', 1);
        });
    });

  // add arrow marker
  svg
    .append('defs')
    .append('marker')
    .attr('id', 'arrowhead')
    .attr('refX', 5)
    .attr('refY', 5)
    .attr('markerWidth', 16)
    .attr('markerHeight', 13)
    .append('path')
    .attr('d', 'M 0 0 L 5 5 L 0 10')
    .attr('stroke', '#ccc')
    .attr('stroke-width', 1)
    .attr('fill', 'none');

  axis.select('path').attr('marker-end', 'url(#arrowhead)');
};

export const initSvg = (elementRef: RefObject<HTMLDivElement>, type: string, data?: ObjectData) => {
  if (!data) {
    return;
  }
  const { width, height } = settings;
  d3.select(elementRef.current)
    .append('svg')
    .attr(`data-type${type}`, true)
    .attr('width', width)
    .attr('height', height);

  createAxis(elementRef, data, type);
};

export const clearSvg = (elementRef: RefObject<HTMLDivElement>) => {
  d3.select(elementRef.current).select('svg').remove();
};

export const getDay = (d: Date) => {
  const day = d.getDate().toString();
  if (day.length === 1) {
    return `0${day}`;
  }
  return day;
};

export const getOriginDay = (d: Date) => {
  const day = d.getDate().toString();
  return day;
};

export const getOriginMonth = (d: Date) => {
  const date = new Date(d.toString());
  const month = date.toLocaleString('en-US', { month: 'short' });
  return month;
};

export const getMonth = (d: Date) => {
  const date = new Date(d.toString());
  const month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    return `0${month}`;
  }
  return month;
};

export const getYear = (d: Date) => {
  const date = new Date(d.toString());
  return date.getFullYear().toString().substring(2);
};

export const clearLines = (type: string) => {
  d3.select(`[data-activeLine${type}]`).remove();
  d3.select(`[data-activeCircle${type}]`).remove();
  d3.selectAll(`[data-activeTitle${type}]`).remove();
  d3.selectAll(`[data-otherTitle${type}]`).remove();
  d3.selectAll(`[data-line${type}]`).remove();
  d3.selectAll(`[data-circle${type}]`).remove();
  d3.selectAll(`[data-title${type}]`).remove();
};

type LinesProps = {
  elementRef: RefObject<HTMLDivElement>;
  dataset: ObjectData[];
  activeDataset: ObjectData | undefined;
  activeType: MainPredictorType;
  type: string;
  colorActiveLine: string;
  colorOtherLine: string;
  showOverall: boolean;
  showFiltered: boolean;
};

type Points = {
  value: number;
  isActive: boolean;
  x: number;
  y: number;
  dy?: number;
  nullValue?: boolean;
}[];

export const createLines = (props: LinesProps) => {
  const {
    activeType,
    dataset,
    elementRef,
    type,
    activeDataset,
    colorActiveLine,
    colorOtherLine,
    showOverall,
    showFiltered,
  } = props;

  let activeData = dataset.find((d) => d.type === activeType);
  if (activeDataset) {
    activeData = activeDataset;
  }
  if (!activeData) {
    return;
  }

  const { padding, xAxisWidth, yAxisHeight } = settings;
  const maxValues = dataset.map((data) => Math.max(...data.values.map((d) => d.value)));
  const minValues = dataset.map((data) => Math.min(...data.values.map((d) => d.value)));
  if (activeDataset) {
    maxValues.push(Math.max(...activeDataset.values.map((d) => d.value)));
    minValues.push(Math.min(...activeDataset.values.map((d) => d.value)));
  }

  const maxValueY = Math.max(...maxValues);
  const minValueY = Math.min(...minValues);

  const svg = d3.select(elementRef.current).selectAll('svg');

  const xScale = d3.scaleLinear().domain([0, activeData.values.length]).range([0, xAxisWidth]);
  // const yScale = d3.scaleLinear().domain([minValueY - 2, maxValueY + 3]).range([yAxisHeight - padding, 0]);
  const yScale = d3
    .scaleLinear()
    .domain([minValueY, maxValueY])
    .range([yAxisHeight - padding, 0]);
  const startD = xAxisWidth / activeData.values.length / 2;
  const scaleYValue = (v: number) => {
    if (v === minValueY) {
      return yScale(v) - 3;
    }
    if (v === maxValueY) {
      return yScale(v) + 3.5;
    }
    return yScale(v);
  };

  const titlesNamesPointsY: {
    y: number;
    x: number;
    value: number;
    isActive: boolean;
    type: string;
    color: string;
    text: string;
    isOpacity?: boolean;
  }[] = [];

  // other
  const createOtherLines = (
    points: number[][],
    departsment: MainPredictorType,
    otherDataset: ObjectDataValues[],
    title: string,
  ) => {
    const linePath = d3.line()(points as [number, number][]);
    svg
      .append('g')
      .attr(`data-line${type}`, true)
      .append('path')
      .attr('d', linePath)
      .attr('stroke', colorOtherLine)
      .style('opacity', 0.2)
      .attr('stroke-width', 1)
      .attr('fill', 'none');

    const circles = svg.append('g').attr(`data-circle${departsment}`, true).selectAll('circles').data(points);
    circles
      .enter()
      .append('circle')
      .style('cursor', 'pointer')
      .attr('data-hover', (d, i) => `${type}/${activeData!.values[i].date}`)
      .attr('cx', (data) => data[0])
      .attr('cy', (data) => data[1])
      .attr('r', '3.5')
      .attr('fill', colorOtherLine)
      .style('opacity', 0.2)
      .on('mouseover', (e: MouseEvent) => {
        const target = e.target as SVGAElement;
        const hoverData = target.dataset.hover;
        if (hoverData) {
          d3.select(`[data-date="${hoverData}"]`).attr('opacity', 1);
          d3.selectAll(`[data-dateTitle="${hoverData}"]`).attr('opacity', 1);
          d3.selectAll(`[data-mainDateTitle="${hoverData}"]`).attr('opacity', 0);
        }
      })
      .on('mouseout', (e: MouseEvent) => {
        const target = e.target as SVGAElement;
        const hoverData = target.dataset.hover;
        if (hoverData) {
          d3.selectAll(`[data-date="${hoverData}"]`).attr('opacity', 0);
          d3.selectAll(`[data-dateTitle="${hoverData}"]`).attr('opacity', 0);
          d3.selectAll(`[data-mainDateTitle="${hoverData}"]`).attr('opacity', 1);
        }
      });

    circles.exit().remove();

    titlesNamesPointsY.push({
      color: colorOtherLine,
      isOpacity: true,
      isActive: false,
      text: title,
      type,
      x: points[points.length - 1][0],
      y: points[points.length - 1][1],
      value: otherDataset[otherDataset.length - 1].value,
    });
  };

  let otherData = dataset.filter((d) => d.dataType !== activeData?.dataType);
  if (!showOverall) {
    otherData = otherData.filter((d) => d.dataType !== 'Overall');
  }
  if (!showFiltered) {
    otherData = otherData.filter((d) => d.dataType !== 'Filtered');
  }
  otherData.forEach((data) => {
    const points = data.values.map((d, i) => [xScale(i + 1) - startD, scaleYValue(d.value)]);
    createOtherLines(points, data.type as MainPredictorType, data.values, data.dataType);
  });

  // active
  const points = activeData.values.map((d, i) => [xScale(i + 1) - startD, scaleYValue(d.value)]);
  const activeLinePath = d3.line()(points as [number, number][]);

  svg
    .append('g')
    .attr(`data-activeLine${type}`, true)
    .append('path')
    .attr('d', activeLinePath)
    .attr('stroke', colorActiveLine)
    .attr('stroke-width', 1)
    .attr('fill', 'none');

  const activeCircles = svg.append('g').attr(`data-activeCircle${type}`, true).selectAll('circles').data(points);
  activeCircles
    .enter()
    .append('path')
    .attr('d', (data) =>
      d3.line()([
        [data[0], data[1]],
        [data[0], yAxisHeight - padding],
      ]),
    )
    .attr('data-date', (d, i) => `${type}/${activeData!.values[i].date}`)
    .attr('stroke', '#ccc')
    .style('stroke-dasharray', '3, 3')
    .attr('stroke-width', 1)
    .attr('fill', 'none')
    .attr('opacity', 0);

  activeCircles
    .enter()
    .append('circle')
    .style('cursor', 'pointer')
    .attr('data-hover', (d, i) => `${type}/${activeData!.values[i].date}`)
    .attr('cx', (data) => data[0])
    .attr('cy', (data) => data[1])
    .attr('r', '3.5')
    .attr('fill', colorActiveLine)
    .on('mouseover', (e: MouseEvent) => {
      const target = e.target as SVGAElement;
      const hoverData = target.dataset.hover;
      if (hoverData) {
        d3.select(`[data-date="${hoverData}"]`).attr('opacity', 1);
        d3.selectAll(`[data-dateTitle="${hoverData}"]`).attr('opacity', 1);
        d3.selectAll(`[data-mainDateTitle="${hoverData}"]`).attr('opacity', 0);
      }
    })
    .on('mouseout', (e: MouseEvent) => {
      const target = e.target as SVGAElement;
      const hoverData = target.dataset.hover;
      if (hoverData) {
        d3.selectAll(`[data-date="${hoverData}"]`).attr('opacity', 0);
        d3.selectAll(`[data-dateTitle="${hoverData}"]`).attr('opacity', 0);
        d3.selectAll(`[data-mainDateTitle="${hoverData}"]`).attr('opacity', 1);
      }
    });

  const titlesPointsY: Record<
    string,
    {
      value: number;
      y: number;
      x: number;
      isActive: boolean;
      nullValue?: boolean;
    }[]
  > = {};
  activeData.values.forEach((d, i) => {
    titlesPointsY[d.date] = [
      { value: d.value, x: points[i][0], y: points[i][1], isActive: true, nullValue: d.nullValue },
    ];
  });

  otherData.forEach((data) => {
    const otherPoints = data.values.map((d, i) => [xScale(i + 1) - startD, scaleYValue(d.value)]);
    otherPoints.forEach((p, i) => {
      if (!titlesPointsY[data.values[i].date].find((titlePoint) => titlePoint.value === data.values[i].value)) {
        titlesPointsY[data.values[i].date].push({
          value: data.values[i].value,
          x: p[0],
          y: p[1],
          isActive: false,
          nullValue: data.values[i].nullValue,
        });
        titlesPointsY[data.values[i].date].sort((a, b) => a.value - b.value);
      }
    });
  });

  // dyTop 18 title size by font & padding
  const dyTop = -6;
  // dyBottom 18 title size by font & padding
  const dyBottom = 16;
  const heightBetweenPoints = 20;

  const h = 20;
  const hmin = 20;
  const dTop = -6;
  const dBottom = 16;
  const dNextBottom = 14;
  const dNextTop = -14;

  const calcTitles = (titlesPoints: Points) => {
    for (let i = 0; i < titlesPoints.length; i += 1) {
      const p = titlesPoints[i];
      const pNext = titlesPoints[i - 1];
      const pPrev = titlesPoints[i + 1];
      if (otherData.length >= 3) {
        if (p.y > hmin) {
          p.dy = p.y + dTop;
        } else if (yAxisHeight - padding - p.y > h) {
          p.dy = p.y + dBottom;
        }
      } else if (!pNext && !pPrev) {
        if (p.y > hmin) {
          p.dy = p.y + dTop;
        } else if (yAxisHeight - padding - p.y > h) {
          p.dy = p.y + dBottom;
        }
      } else if (!pNext && pPrev) {
        // console.log(p.value, {p, pNext, pPrev});
        if (p.y > hmin) {
          p.dy = p.y + dTop;
        } else if (pPrev.y - p.y >= h) {
          p.dy = p.y + dBottom;
        } else if (titlesPoints[i + 2]) {
          const pPrev2 = titlesPoints[i + 2];
          if (pPrev2.y - pPrev.y > h) {
            p.dy = pPrev.y + dBottom;
            pPrev.dy = pPrev.y + dBottom;
          } else if (yAxisHeight - padding - pPrev2.y > h) {
            p.dy = pPrev2.y + dBottom;
            pPrev2.dy = p.dy;
          }
        } else {
          // console.log(p.value, {p, pNext, pPrev});
          p.dy = pPrev.y + dBottom;
          pPrev.dy = p.dy;
        }
      } else if (pNext && pPrev) {
        // console.log(p.v, {p, pNext, pPrev});
        if (p.dy && pPrev.y - p.dy > h) {
          p.dy += dNextBottom;
        } else if (p.dy && pPrev.y - p.y > h * 2) {
          p.dy += dNextBottom;
        } else if (p.y - pNext.y > h && pNext.dy && p.y - pNext.dy > h) {
          p.dy = p.y + dTop;
        } else if (!p.dy && pPrev.y - p.y > h) {
          p.dy = p.y + dBottom;
        } else if (pPrev.dy) {
          p.dy = pPrev.dy + dNextBottom;
          pPrev.dy = p.dy + dNextBottom;
        } else if (yAxisHeight - padding - pPrev.y >= h * 2) {
          p.dy = pPrev.y + dBottom;
          pPrev.dy = p.dy + dNextBottom;
        } else if (pNext.dy) {
          p.dy = pNext.dy;
          pNext.dy = p.dy + dNextTop;
        }
      } else if (pNext && !pPrev) {
        // console.log(p.value, {p, pNext, pPrev});
        if (!p.dy) {
          if (yAxisHeight - padding - p.y <= h && p.y - pNext.y > h && pNext.dy && p.y - pNext.dy > h) {
            p.dy = p.y + dTop;
          } else if (p.y - pNext.y >= h && pNext.dy && p.y - pNext.dy > h) {
            p.dy = p.y + dTop;
          } else if (yAxisHeight - padding - p.y > h) {
            p.dy = p.y + dBottom;
          } else if (pNext.dy && p.y - pNext.dy > dBottom * 2 && p.y - pNext.y >= h) {
            p.dy = p.y + dTop;
          } else if (p.y + dBottom < yAxisHeight - padding) {
            p.dy = p.y + dBottom;
          } else if (titlesPoints[i - 2]) {
            const pNext2 = titlesPoints[i - 2];
            if (pNext2.dy && p.y - pNext.y >= h) {
              const { dy } = pNext2;
              pNext2.dy += dNextTop;
              p.dy = p.y + dTop;
              pNext.dy = dy;
            } else if (pNext.dy && pNext.y - pNext2.y > h * 2) {
              p.dy = pNext.dy;
              pNext.dy = p.dy + dNextTop;
            } else if (pNext2.dy) {
              const { dy } = pNext2;
              pNext2.dy += dNextTop;
              p.dy = pNext.dy;
              pNext.dy = dy;
            }
          }
        } else if (p.dy && titlesPoints.length === 2) {
          p.dy += dNextBottom;
        }
      }
    }
  };

  const otherTitles = svg.append('g').attr(`data-otherTitle${type}`, true);
  activeData.values.forEach((d) => {
    const titlesPoints: Points = titlesPointsY[d.date].map((p) => ({
      value: p.value,
      isActive: p.isActive,
      x: p.x,
      y: p.y,
      dy: undefined,
      nullValue: p.nullValue,
    }));
    titlesPoints.sort((a, b) => b.value - a.value);

    calcTitles(titlesPoints);

    for (let j = 0; j < titlesPoints.length; j += 1) {
      const p = titlesPoints[j];
      const py = p.dy ? p.dy : p.y;
      const textBg = otherTitles
        .append('text')
        .attr('x', p.x)
        .attr('y', py)
        .text(p.nullValue ? '?' : goodMinus(p.value))
        .style('pointer-events', 'none')
        .style('font-size', '14px')
        .attr('text-anchor', 'middle')
        .attr('data-dateTitle', `${type}/${d.date}`)
        .style('stroke', '#FFFFFF')
        .style('stroke-width', '2')
        .attr('opacity', 0);
      if (p.value === d.value) {
        textBg.attr('fill', colorActiveLine);
      } else {
        textBg.attr('fill', colorOtherLine);
        textBg.style('opacity', 0.2);
      }

      const text = otherTitles
        .append('text')
        .attr('x', p.x)
        .attr('y', py)
        .text(p.nullValue ? '?' : goodMinus(p.value))
        .style('pointer-events', 'none')
        .style('font-size', '14px')
        .attr('text-anchor', 'middle')
        .attr('data-dateTitle', `${type}/${d.date}`)
        .attr('opacity', 0);
      if (p.value === d.value) {
        text.attr('fill', colorActiveLine);
      } else {
        text.attr('fill', colorOtherLine);
        textBg.style('opacity', 0.2);
      }
    }
  });

  const getYPosition = (y: number) => {
    if (y < heightBetweenPoints) {
      return y + dyBottom;
    }
    return y + dyTop;
  };
  activeCircles
    .enter()
    .append('text')
    .attr('data-mainDateTitle', (d, i) => `${type}/${activeData!.values[i].date}`)
    .attr('x', (data) => data[0])
    .attr('y', (data) => getYPosition(data[1]))
    .style('pointer-events', 'none')
    .style('font-size', '14px')
    .text((d, i) => (activeData!.values[i].nullValue ? '?' : goodMinus(activeData!.values[i].value)))
    .attr('text-anchor', 'middle')
    .attr('fill', colorActiveLine)
    .style('stroke', '#FFFFFF')
    .style('stroke-width', '2');
  activeCircles
    .enter()
    .append('text')
    .attr('data-mainDateTitle', (d, i) => `${type}/${activeData!.values[i].date}`)
    .attr('x', (data) => data[0])
    .attr('y', (data) => getYPosition(data[1]))
    .style('pointer-events', 'none')
    .style('font-size', '14px')
    .text((d, i) => (activeData!.values[i].nullValue ? '?' : goodMinus(activeData!.values[i].value)))
    .attr('text-anchor', 'middle')
    .attr('fill', colorActiveLine);

  activeCircles.exit().remove();

  titlesNamesPointsY.push({
    color: colorActiveLine,
    isActive: true,
    text: activeData.dataType,
    type,
    x: points[points.length - 1][0],
    y: points[points.length - 1][1],
    value: activeData!.values[activeData!.values.length - 1].value,
  });

  titlesNamesPointsY.sort((a, b) => b.value - a.value);
  const titlesNames: Points = titlesNamesPointsY.map((p) => ({
    value: p.value,
    isActive: p.isActive,
    x: p.x,
    y: p.y,
    dy: undefined,
  }));
  titlesNames.sort((a, b) => b.value - a.value);
  calcTitles(titlesNames);

  for (let i = 0; i < titlesNamesPointsY.length; i += 1) {
    const p = titlesNamesPointsY[i];
    const dy = titlesNames[i].dy ? titlesNames[i].dy : p.y;
    const text = p.text.length > 7 ? `${p.text.substring(0, 7)}...` : p.text;
    const dxPositive = 12;
    const dxnegative = 16;
    const dx = p.value < 0 ? dxnegative : dxPositive;
    const title = d3
      .select(elementRef.current)
      .append('span')
      .attr(`data-activeTitle${type}`, true)
      .style('position', 'absolute')
      .style('font-size', '14px')
      .style('color', p.color)
      .style('top', `${dy}px`)
      .style('left', `${points[points.length - 1][0] + dx}px`)
      .style('transform', `translate(0, -80%)`)
      .style('white-space', 'nowrap')
      .style('cursor', 'pointer');
    title.text(text);
    if (p.isOpacity) {
      title.style('opacity', 0.2);
    }
    if (p.text.length > 7) {
      const titleElement = title
        .append('span')
        .style('position', 'absolute')
        .style('font-size', '14px')
        .style('color', p.color)
        .style('top', `0px`)
        .style('left', `0px`)
        .style('opacity', 0)
        .style('white-space', 'nowrap')
        .style('pointer-events', 'none')
        .text(p.text);

      title.on('mouseover', () => {
        titleElement.style('opacity', 1);
        title.style('color', 'transparent');
      });
      title.on('mouseout', () => {
        titleElement.style('opacity', 0);
        title.style('color', p.color);
      });
    }
  }
};

export const getData = (
  polls: IPollInfo[],
  data: IMainMetricsHistoryResponse | undefined,
  type: MainPredictorType,
  dataType?: string | null,
) => {
  if (data) {
    let idDayTitle = false;
    const monthNames = polls.map((p) => {
      const date = new Date(p.startedAt);
      return getOriginMonth(date);
    });
    monthNames.forEach((m, i) => {
      if (monthNames.findIndex((month) => month === m) !== i) {
        idDayTitle = true;
      }
    });

    const values = data.data
      .map((d) => {
        const poll = polls.find((p) => p.id === d.pollId);
        if (poll) {
          const date = new Date(poll.startedAt);
          let dateTitle = `${getOriginMonth(date)} ${date.getFullYear()}`;
          if (idDayTitle) {
            dateTitle = `${getOriginMonth(date)} ${getOriginDay(date)} ${date.getFullYear()}`;
          }
          const dateValue = `${getDay(date)}/${getMonth(date)}/${getYear(date)}`;
          const dataValue = d[type];
          let value = 0;
          if (dataValue) {
            value = Math.round(dataValue * 100);
          }
          return { date: dateValue, dateTitle, value, nullValue: dataValue === null };
        }
        return undefined;
      })
      .reverse()
      .filter(Boolean) as ObjectDataValues[];
    let dataTypeTitle = 'Overall';
    if (dataType) {
      dataTypeTitle = dataType;
    }

    return [{ type, values, dataType: dataTypeTitle } as ObjectData];
  }
  return [];
};

export const setFilter = (filter: IFilter, groupBy: GroupBy, selected: string | null | undefined): IFilter => {
  if (!selected) {
    return filter;
  }
  let key: keyof IFilter;

  switch (groupBy) {
    case GroupBy.Locations:
      key = 'locations';
      break;

    case GroupBy.Roles:
      key = 'roles';
      break;

    case GroupBy.Tenures:
      key = 'tenures';
      break;

    case GroupBy.Departments:
    default:
      key = 'departments';
      break;
  }

  if (key === 'tenures') {
    return { ...filter, [key]: [selected as Tenure] };
  }

  return { ...filter, [key]: [Number(selected)] };
};

export const useGetGroupName = (
  slices: UseQueryResult<IPollSlicesResponse, Error>,
  groupBy: GroupBy,
  selected: string | null | undefined,
) => {
  const { t } = useTranslation();

  if (!slices.data) {
    return null;
  }

  let key: 'departments' | 'roles' | 'locations' | 'tenures';

  switch (groupBy) {
    case GroupBy.Locations:
      key = 'locations';
      break;

    case GroupBy.Roles:
      key = 'roles';
      break;

    case GroupBy.Tenures:
      key = 'tenures';
      break;

    case GroupBy.Departments:
    default:
      key = 'departments';
      break;
  }

  // eslint-disable-next-line
  // @ts-ignore
  const name = slices.data[key].find((x) => ('id' in x ? x.id.toString() === selected : x.name === selected))?.name;

  if (!name || groupBy !== GroupBy.Tenures) {
    return name;
  }

  return t(`Tenures.${name}`);
};

export const getGroupName = (
  slices: UseQueryResult<IPollSlicesResponse, Error>,
  groupBy: GroupBy,
  selected: string | null | undefined,
) => {
  if (!slices.data) {
    return null;
  }

  let key: 'departments' | 'roles' | 'locations' | 'tenures';

  switch (groupBy) {
    case GroupBy.Locations:
      key = 'locations';
      break;

    case GroupBy.Roles:
      key = 'roles';
      break;

    case GroupBy.Tenures:
      key = 'tenures';
      break;

    case GroupBy.Departments:
    default:
      key = 'departments';
      break;
  }

  // eslint-disable-next-line
  // @ts-ignore
  return slices.data[key].find((x) => ('id' in x ? x.id.toString() === selected : x.name === selected))?.name;
};
