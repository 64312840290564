import { useQuery } from 'react-query';

import { IPollSlicesResponse } from '../types/slicesAndInfo';
import axios from '../../axios';
import { IApiResponse } from '../../types';

const useGetPollSlices = (pollId: number, enabled = true) =>
  useQuery<IPollSlicesResponse, Error>(
    [`/stats/polls/${pollId}/slices`],
    async () => {
      const response = await axios.get<unknown, IApiResponse<IPollSlicesResponse>>(
        `/gateway/tilly/stats/polls/${pollId}/slices`,
      );

      if (!response || response.code !== 'success' || !response.value) {
        throw new Error(`Response was not successful. Code: ${response.code}. Errors: ${response.errors?.toString()}`);
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      retry: true,
      enabled,
    },
  );

export default useGetPollSlices;
