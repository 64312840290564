import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    marginTop: theme.spacing.lg,
    justifyContent: 'space-between',
    gap: theme.spacing.sm,
  },
  carouselRoot: {
    margin: `${theme.spacing.xs}px 0 ${theme.spacing.lg}px`,
    maxWidth: '370px',
  },
}));

export default useStyles;
