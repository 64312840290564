import React from 'react';
import { useQueryParams, StringParam } from 'use-query-params';

import { IFilter } from '../statistics/types/filter';
import { Tenure } from '../statistics/types/enums';

import State from './State';

const Context = React.createContext<State<IFilter>>([
  { departments: [], roles: [], locations: [], tenures: [] },
  () => {},
]);

export const useFilter = () => React.useContext(Context);

export default function FilterProvider({ children }: React.PropsWithChildren) {
  const [{ departments, roles, locations, tenures }, setQuery] = useQueryParams({
    departments: StringParam,
    roles: StringParam,
    locations: StringParam,
    tenures: StringParam,
  });

  const filterState = React.useMemo<State<IFilter>>(() => {
    const filter = {
      departments: departments
        ? departments
            .split(',')
            .filter((s) => s !== '')
            .map((i) => Number(i))
        : [],
      roles: roles
        ? roles
            .split(',')
            .filter((s) => s !== '')
            .map((i) => Number(i))
        : [],
      locations: locations
        ? locations
            .split(',')
            .filter((s) => s !== '')
            .map((i) => Number(i))
        : [],
      tenures: tenures
        ? tenures
            .split(',')
            .filter((s) => s !== '')
            .map((i) => i as Tenure)
        : [],
    };

    const setFilter = (newFilter: IFilter) => {
      setQuery({
        departments: newFilter.departments.length ? newFilter.departments.join(',') : null,
        roles: newFilter.roles.length ? newFilter.roles.join(',') : null,
        locations: newFilter.locations.length ? newFilter.locations.join(',') : null,
        tenures: newFilter.tenures.length ? newFilter.tenures.join(',') : null,
      });
    };

    return [filter, setFilter];
  }, [departments, roles, locations, tenures, setQuery]);

  return <Context.Provider value={filterState}>{children}</Context.Provider>;
}
