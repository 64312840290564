import { createStyles, MantineTheme } from '@mantine/core';

const useStyles = createStyles((theme: MantineTheme) => ({
  root: {
    width: '135px',
    '& > [aria-expanded="true"]': {
      '.mantine-Select-input': {
        backgroundColor: theme.colors.white[0],
      },
      '.mantine-Select-wrapper': {
        '&:after': {
          position: 'absolute',
          top: 'calc(100% - 7px)',
          left: 0,
          width: '100%',
          height: '15px',
          backgroundColor: theme.colors.white[0],
          borderLeft: `1px ${theme.black} solid`,
          borderRight: `1px ${theme.black} solid`,
          content: "''",
        },
      },
    },
  },
  input: {
    border: `1px ${theme.black} solid`,
    minHeight: '30px',
    height: '30px',
    width: '135px',
    backgroundColor: theme.white,
    '::placeholder': {
      color: theme.black,
    },
    '&:disabled': {
      border: `1px ${theme.colors.white[4]} solid`,
      color: theme.colors.white[4],
      backgroundColor: theme.white,
    },
    lineHeight: 1,
  },
  rightSection: {
    '& > button > svg': {
      color: `${theme.colors.red[4]} !important`,
    },
  },
  dropdown: {
    border: `1px ${theme.black} solid`,
    borderTop: 'none',
    borderRadius: '0 0 8px 8px',
    boxShadow: 'none',
    backgroundColor: theme.colors.white[0],
  },
  itemsWrapper: {
    borderTop: `1px ${theme.colors.white[1]} solid`,
  },
  item: {
    paddingLeft: '8px',
    '&[data-selected]': {
      '&, &:hover': {
        color: theme.colors.white[4],
      },
    },
  },
}));

export default useStyles;
