import React, { useCallback } from 'react';
import { Box, Flex, Popover, Switch, Text, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';

import { ISettingsSlice, ISlicesSettingsDto } from '../../types';
import useUpdateSlicesSettingsMutation from '../../queries/useUpdateSlicesSettingsMutation';

import useStyles from './SliceSettingsSwitch.styles';

interface Props {
  settingsKey: keyof ISlicesSettingsDto;
  slicesEnabledSettings: ISlicesSettingsDto;
  slicesList: ISettingsSlice[];
}

export default function SliceSettingsSwitch({ settingsKey, slicesEnabledSettings, slicesList }: Props) {
  const { t } = useTranslation();
  const { classes, theme } = useStyles();
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const { mutate, isLoading: isUpdating, error: updatingError } = useUpdateSlicesSettingsMutation();

  const onChange = useCallback(() => {
    const checked = !slicesEnabledSettings[settingsKey];

    if (!checked && slicesList?.length) {
      return;
    }

    mutate({ ...slicesEnabledSettings, [settingsKey]: checked });
  }, [slicesEnabledSettings, settingsKey, mutate, slicesList]);

  const canBeDisabled = !slicesEnabledSettings[settingsKey] || !slicesList.length;

  const labelElement = <Text size="md">{t(`SlicesSettings.AdditionalDataSlices_SwitchLabel`)}</Text>;

  const switchElement = (
    <Switch
      classNames={classes}
      size="sm"
      color="violet"
      checked={slicesEnabledSettings[settingsKey]}
      onChange={onChange}
      error={updatingError?.message}
      disabled={isUpdating || !canBeDisabled}
    />
  );

  if (canBeDisabled) {
    return (
      <Flex align="center" justify="space-between" mt="sm">
        {labelElement}
        {switchElement}
      </Flex>
    );
  }

  const tooltipElement = (
    <Text size="sm" color={theme.white}>
      {t('SlicesSettings.AdditionalDataSlices_Tooltip')}
    </Text>
  );

  if (middleScreen) {
    return (
      <Flex align="center" justify="space-between" mt="sm">
        {labelElement}
        <Popover width={200} withArrow>
          <Popover.Target>
            <Box>{switchElement}</Box>
          </Popover.Target>
          <Popover.Dropdown bg={theme.black}>{tooltipElement}</Popover.Dropdown>
        </Popover>
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="space-between" mt="sm">
      {labelElement}
      <Tooltip.Floating label={tooltipElement} multiline width={200}>
        <Box>{switchElement}</Box>
      </Tooltip.Floating>
    </Flex>
  );
}
