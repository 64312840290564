import React, { useCallback } from 'react';
import { ActionIcon, Badge } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { Tenure } from '../../../statistics/types/enums';
import { useComparison } from '../../../globalStates/Comparison';

import useStyles from './ComparisonBadge.style';

const match = (
  comparison: number[] | Tenure[],
  left: (comparison: number[]) => void,
  right: (comparison: Tenure[]) => void,
) => {
  if (comparison.length === 0) {
    return;
  }

  if (typeof comparison[0] === 'number') {
    left(comparison as number[]);
    return;
  }

  right(comparison as Tenure[]);
};

const getName = (
  id: number | Tenure,
  name: string | null | undefined,
  t: TFunction<'translation', undefined, 'translation'>,
) => {
  if (typeof id === 'number') {
    return name;
  }

  return t<string>(`Tenures.${name}`);
};

export default function ComparisonBadge({
  comparisonId,
  name,
}: {
  comparisonId: number | Tenure;
  name: string | null | undefined;
}) {
  const { t } = useTranslation();
  const [comparison, setComparison] = useComparison();
  const { classes, theme } = useStyles();

  const onClick = useCallback(
    () =>
      match(
        comparison.comparison,
        (nums) =>
          setComparison({
            ...comparison,
            comparison: nums.filter((c) => c !== comparisonId),
          }),
        (tenures) =>
          setComparison({
            ...comparison,
            comparison: tenures.filter((c) => c !== comparisonId),
          }),
      ),
    [comparison, setComparison, comparisonId],
  );

  return (
    <Badge
      className={classes.badge}
      rightSection={
        <ActionIcon size="xs" variant="transparent" onClick={onClick}>
          <IconX size={14} color={theme.colors.red[4]} />
        </ActionIcon>
      }
    >
      {t<string>('Statistics.ComparePredictors', {
        name: getName(comparisonId, name, t),
        interpolation: { escapeValue: false },
      })}
    </Badge>
  );
}
