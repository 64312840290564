import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme, underlineText: boolean) => ({
  logout: {
    textDecoration: underlineText ? 'underline' : 'none',
  },
  logoutErrorPopover: {
    backgroundColor: 'transparent',
    border: '0',
  },
  logoutError: {
    backgroundColor: 'white',
    '&:before': {
      backgroundColor: 'red',
    },
  },
}));

export default useStyles;
