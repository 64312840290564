import React from 'react';
import { Center, Container, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ErrorNumber } from '../../../../images/404.svg';
import useStyles from '../../styles';
import { ROUTES } from '../../../../constants';
import ErrorPage from '../..';
import ButtonInStab from '../../../../components/ButtonInStab/ButtonInStab';

function NotFoundErrorBody(): JSX.Element {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const onClickHandler = React.useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  return (
    <Center dir="column" className={classes.errorContainer}>
      <Container>
        <Center>
          <ErrorNumber className={classes.svgImage} />
        </Center>
        <Text className={classes.oops}>{t('NotFoundErrorPage.Title')}</Text>
        <Text className={classes.text}>{t('NotFoundErrorPage.Text')}</Text>
        <Center>
          <ButtonInStab text={t('ErrorPage.ReturnButton')} onClick={onClickHandler} />
        </Center>
      </Container>
    </Center>
  );
}

export default function NotFoundErrorPage() {
  return (
    <ErrorPage>
      <NotFoundErrorBody />
    </ErrorPage>
  );
}
