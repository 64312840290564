import { t } from 'i18next';
import Shepherd from 'shepherd.js';

import { ELEMENT_ID } from '../../../constants';

export default function addMainPredictorsStep(tour: Shepherd.Tour) {
  tour.addStep({
    title: () => t<string>('StatisticsOnboarding.Steps.MainPredictors.Title'),
    text: () => t<string>('StatisticsOnboarding.Steps.MainPredictors.Text'),
    attachTo: {
      element: `#${ELEMENT_ID.MAIN_PREDICTORS}`,
      on: 'right',
    },
  });
}
