import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Center, Container, Text } from '@mantine/core';

import Header from '../../../components/Header/Header';
import useStyles from '../styles';
import { ROUTES } from '../../../constants';
import Layout from '../../../components/Layout/Layout';
import LogoutPopover from '../../../components/Logout/LogoutPopover';
import ButtonInStab from '../../../components/ButtonInStab/ButtonInStab';

export default function UpgradePageUpgraded(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const onClickHandler = React.useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  const body = (
    <Center dir="column" className={classes.upgradeContainer}>
      <Container>
        <Text className={classes.title}>{t('UpgradePageProPlan.Title')}</Text>
        <Text className={classes.text}>{t('UpgradePageProPlan.Text')}</Text>
        <Center>
          <ButtonInStab text={t('UpgradePage.ReturnButton')} onClick={onClickHandler} />
        </Center>
      </Container>
    </Center>
  );

  return <Layout header={<Header firstRow={<LogoutPopover logoutText="LogoutButton" />} />} body={body} />;
}
