import React from 'react';
import { Group, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { IStats } from '../../statistics/types/overview';
import { IPollInfo } from '../../statistics/types/slicesAndInfo';
import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import Indicator from '../Indicator/Indicator';
import evaluate from '../../helpers/evaluate';
import goodMinus from '../../helpers/goodMinus';
import toPercent from '../../helpers/toPercent';
import { MainPredictorType } from '../../statistics/types/enums';

import { ENPS_DESCRIPTIONS, ENPS_ZONES } from './MainPredictorCard.constants';
import isValueUnknown from './MainPredictorCard.helpers';
import useStyles from './MainPredictorCard.style';

interface IMainPredictorCardProps {
  data: IStats | undefined;
  isLoading: boolean;
  type: MainPredictorType;
  polls: IPollInfo[];
}

export default function MainPredictorCard(props: IMainPredictorCardProps) {
  const { data, isLoading, type, polls } = props;
  const title = type === 'enps' ? 'MainPredictor.eNPS' : 'MainPredictor.Turnover';
  const titleClass = type === 'enps' ? 'enpsTitle' : 'turnoverTitle';
  const unknownSymbol = '?';
  const withCharts = polls.length > 1;
  const { cx, classes } = useStyles(withCharts);
  const { t } = useTranslation();

  let value = unknownSymbol;
  let colorClass = classes.unknown;
  let percentSymbol = '';
  let description = '';
  let differenceSymbol = '';
  let difference = unknownSymbol;

  if (isLoading) {
    return <CustomSkeleton className={classes.container} />;
  }

  if (!isValueUnknown(data)) {
    value = goodMinus(toPercent(data?.value, 1));
    colorClass = classes[type];

    [percentSymbol, description] =
      type === 'enps'
        ? ['', ENPS_DESCRIPTIONS[evaluate(data?.value, ENPS_ZONES)]]
        : ['%', 'MainPredictorCard.TurnoverDescription'];

    difference = '';

    if (data?.difference) {
      differenceSymbol = data?.difference < 0 ? '−' : '+';
      difference = `${toPercent(Math.abs(data?.difference), 1)}`;
    }
  }

  return (
    <Stack className={cx(classes.container)}>
      <Group align="start" className={classes.values}>
        <Title order={2} className={cx(classes.title, classes[titleClass])}>
          {t(title)}
        </Title>
        <Text className={cx(classes.value, colorClass)}>{`${value}${percentSymbol}`}</Text>
        {difference && (
          <Text className={cx(classes.differenceCaption, colorClass)}>
            <Text className={classes.differenceValue}>{`${differenceSymbol}${difference}${percentSymbol}`}</Text>
            {t('MainPredictorCard.SinceLastSurvey')}
          </Text>
        )}
      </Group>
      <Text className={classes.description}>{t(description)}</Text>
      {withCharts && <Indicator type={type} polls={polls} />}
    </Stack>
  );
}
